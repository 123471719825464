import times from 'lodash/times';

export enum UserRole {
  ADMIN = 'admin',
  CHIEF_EDITOR = 'chiefEditor',
  SUB_CHIEF_EDITOR = 'subChiefEditor',
  OPERATOR = 'operator',
  TERMINAL_CARRIER = 'terminalCarrier',
  LOADING_POINT_CARRIER = 'loadingPointCarrier',
  SERVICE_STATION_CARRIER = 'serviceStationCarrier',
  FORWARDER = 'forwarder',
  WAGON_LOAD = 'wagonLoad',
}

/**
 * Hack to identify booking provider without proper user role and company type
 * TODO: Remove after creating proper booking provider in API
 * @deprecated
 */
export enum UserHackRole {
  BOOKING_PROVIDER = 'bookingProvider',
}

export enum CompanyType {
  OPERATOR = 'operator',
  TERMINAL_CARRIER = 'terminalCarrier',
  LOADING_POINT_CARRIER = 'loadingPointCarrier',
  SERVICE_STATION_CARRIER = 'serviceStationCarrier',
  FORWARDER = 'forwarder',
  WAGON_LOAD = 'wagonLoad',
}

/**
 * Hack to identify booking provider without proper user role and company type
 * TODO: Remove after creating proper booking provider in API
 * @deprecated
 */
export enum CompanyHackType {
  BOOKING_PROVIDER = 'bookingProvider',
}

export enum AreaType {
  CONVEYANCE = 'conveyance',
  WAGON_LOAD = 'wagonLoad',
}

export const RailwaySidingCompanyType = 'siding';

// Center of Europe (Czech Republic)
export const defaultGeoLocation = {
  lat: 50.331,
  lng: 14.678,
};

export class Entity {
  uid: string | null = null;
}

export class Address {
  address1 = '';
  address2 = '';
  street = '';
  zip = '';
  city = '';
  country = '';
}

export class AddressWithCountry {
  address1 = '';
  address2 = '';
  street = '';
  zip = '';
  city = '';
  country = new Entity();
}

export class Contact {
  name = '';
  phone = '';
  phone1 = '';
  phone2 = '';
  mobile = '';
  fax = '';
  email = '';
  url = '';
  url2 = '';
}

export class GeoLocation {
  lat = '';
  lng = '';
}

export interface ParsedGeoLocation {
  lat: number;
  lng: number;
}

export class Country extends Entity {
  code = '';
  name = {
    de: '',
    en: '',
  };
  center = new GeoLocation();
}

export class Info {
  infoHeadline = '';
  infoContent = '';
}

export class Place extends Entity {
  name = '';
  placeType!: [string];
  company = new Entity();
  locality = new Entity();
  address = new Address();
  contact = new Contact();
  loc = new GeoLocation();
}

export class FileDocument extends File {
  uid = '';
  label = '';
  fileSize = ''; // Deprecated File Api, but a lot of Documents have these fields in the DB
  fileName = ''; // Deprecated File Api, but a lot of Documents have these fields in the DB
}

export class Location extends Entity {
  country = new Entity();
  loc = new GeoLocation();
  name = {
    de: '',
    en: '',
    locale: '',
  };
}

export class Company extends Entity {
  name = '';
  companyType: CompanyType[] = [];
  loadingPointCompanyType?: string = undefined;
  address = new AddressWithCountry();
  urls = {
    url1: '',
    url1_label: '',
    url2: '',
    url2_label: '',
    url3: '',
    url3_label: '',
  };
  contact = new Contact();
  isBookingProvider?: boolean;
  bookableConnections?: string[];
}

export class LoadingPoint extends Place {
  infos: Info[] = times(6, () => new Info());
  documents: FileDocument[] = [];
  freeText = '';
  infoType = ''; // unused since Sidings rewrite.
}

export class LoadingPointCompanyTypeObject {
  id: string = '';
  label: string = '';
  color: string = '';
  system?: boolean;
}

export type Option = 'true' | 'false' | 'null';

export class RailwaySidingConnection {
  status: Option = 'true';
  loading: Option = 'null';
  electric: Option = 'null';
  bypass: Option = 'null';
}

export class RailwaySidingInterchanges {
  tracks: string[] = [];
  sideRamp = false;
  headRamp = false;
  groundLevel = false;
  size = '';
  storageArea = '';
}

export class RailwaySidingMachines {
  forklift = false;
  reachStacker = false;
  crane = false;
  digger = false;
  others: string[] = [];
}

export class RailwaySidingEquipment {
  rankDevice = false;
  trainScale = false;
  truckScale = false;
  serviceStation = false;
  parking = false;
}

export class RailwaySidingFeatures {
  connection = new RailwaySidingConnection();
  interchanges = new RailwaySidingInterchanges();
  machines = new RailwaySidingMachines();
  equipment = new RailwaySidingEquipment();
}

export class RailwaySiding extends LoadingPoint {
  RIL100?: string; // only on imported sindings. Internal id from imported dataset
  RL100?: string; // only on imported sindings. Internal id from imported dataset
  features = new RailwaySidingFeatures();
}

export function isRailwaySiding(company: Company) {
  return (
    company && company.loadingPointCompanyType === RailwaySidingCompanyType
  );
}

export class TerminalBusinessHours {
  open = false;
  begin = '';
  end = '';
  allDay = false;
}

export class TerminalTransport {
  rail = true;
  road = true;
  ship = false;
  transship = false;
}

export class TerminalInfrastructure {
  catenary = false;
  cranes = false;
  cranesQuantity = '';
  breaksTestInstallation = false;
  limitedLiftingHeight = false;
  maxLiftingHeight = '';
  connectionsTemperatureManagedLoadingUnits = false;
  limitedTonnage = false;
  maxTonnage = '';
  limitedTransShipment = false;
  maxTransShipment = '';
  limitedGroundSpace = false;
  maxGroundSpace = '';
}

export class TerminalAccomplishments {
  customsAvailable = false;
  customsDistance = '';
  containerRepairAvailable = false;
  containerRepairDistance = '';
  containerCleaningAvailable = false;
  containerCleaningDistance = '';
  containerDepositAvailable = false;
  containerDepositDistance = '';
  furtherAccomplishments = false;
  furtherAccomplishmentsList: string[] = [];
}

export class TerminalHazardousGoods {
  interchangeAvailable = false;
  leakageAvailable = false;
}

export class TerminalHydrogen {
  isProvider = false;
  isHandling = false;
}

export class TerminalUsableUnits {
  container20 = false;
  container30 = false;
  container40 = false;
  container45 = false;
  exchangeableContainer = false;
  tankContainer = false;
  semiTrailer = false;
  nikrasa = false;
  bulk = false;
  roLa = false;
  furtherUnitsAvailable = false;
  furtherUnits: string[] = [];
}

export class Terminal extends Place {
  active = false;
  documents: FileDocument[] = [];
  businessHours = {
    monday: new TerminalBusinessHours(),
    tuesday: new TerminalBusinessHours(),
    wednesday: new TerminalBusinessHours(),
    thursday: new TerminalBusinessHours(),
    friday: new TerminalBusinessHours(),
    saturday: new TerminalBusinessHours(),
    sunday: new TerminalBusinessHours(),
    onRequest: false,
  };
  features = {
    interchangesAvailable: false,
    interchanges: [] as string[],
    interchangesTotalLengthAvailable: false,
    interchangesTotalLength: '',
    gripperAvailable: false,
    gripperLength: '',
    mobileInterchangeMachinesAvailable: false,
    mobileInterchangeMachines: [] as string[],
    furtherInformation: '',
    transport: new TerminalTransport(),
    transportCarrier: undefined as string | undefined, // legacy transport feature
    infrastructure: new TerminalInfrastructure(),
    accomplishments: new TerminalAccomplishments(),
    hazardousGoods: new TerminalHazardousGoods(),
    hydrogen: new TerminalHydrogen(),
    usableUnits: new TerminalUsableUnits(),
  };
}

export class ServiceStation extends Place {
  BST = '';
  infos = {
    track: '',
    serviceNews: '',
    furtherInfos: '',
    furtherContacts: '',
  };
}

export class Connection extends Entity {
  name = '';
  operator = new Entity();
  contact = new Contact();
  locality = [new Entity(), new Entity()];
}

export class TrainTiming {
  uid = '';
  delivery = {
    day: '',
    time: '',
  };
  collection = {
    day: '',
    time: '',
  };
}

export class TransportUsableUnits {
  bulk = false;
  container20 = false;
  container30 = false;
  container40 = false;
  container45 = false;
  exchangeableContainer = false;
  nikrasa = false;
  roLa = false;
  semiTrailer = false;
  tankContainer = false;
  hazardousGoods = false;
}

export class Train extends Entity {
  name = '';
  capacitiesLeft = false;
  departure = {
    day: '',
    time: '',
  };
  arrival = {
    day: '',
    time: '',
  };
  endOfBooking = {
    day: '',
    time: '',
  };
  prices = {
    usableUnit: '',
    all: {
      price: '',
    },
    sectionPrices: [] as string[],
  };
  connection = new Entity();
  operator = new Entity();
  terminal: Entity[] = [];
  terminalTimings: TrainTiming[] = [];
  usableUnits = new TransportUsableUnits();
  text = '';
}

export class CircularArea {
  radius = 25;
  plz = '';
  loc = defaultGeoLocation;
}

export class CountryAreaDefinition {
  country: string = '';
  all: boolean = false;
  areas: CircularArea[] = [];
}

export class Area extends Entity {
  areaType!: AreaType[];
  company = new Entity();
  localities: Entity[] = [];
  country: Entity[] = [];
  areas: CountryAreaDefinition[] = [];
}

export class ConveyanceArea extends Area {
  name = '';
  usableUnits = new TransportUsableUnits();
}

export class WagonLoad extends Area {
  name = '';
  color = '';
  contact1 = new Contact();
  contact2 = new Contact();
  contact3 = new Contact();
  contact1Title = '';
  contact2Title = '';
  contact3Title = '';
  infos: Info[] = times(5, () => new Info());
}

export class User extends Entity {
  active = false;
  email = '';
  firstName = '';
  lastName = '';
  mobile = '';
  phone = '';
  fax = '';
  uid = '';
  language = '';
  company: Entity[] = [];
  userRole: UserRole[] = [];
  country: Entity[] = [];
}

export interface LocalizedMessage {
  [localeId: string]: string;
}

export class News extends Entity {
  title = '';
  localeTitle: LocalizedMessage = {};
  message = '';
  localeMessage: LocalizedMessage = {};
  publishDate = '';
  active = false;
  url = '';
}

export class Imprint {
  content: LocalizedMessage = {
    de: '',
    en: '',
  };
}

export class CompanyTypeConfig extends Entity {
  welcomeScreenText = '';
}

// API Responses

export interface ConnectionResultZug {
  localityTo: Entity[];
  operator: Entity;
  terminalFrom: Entity[];
  terminalTo: Entity[];
  train: Entity;
}

export interface ConnectionResultData {
  active?: any;
  distance: number;
  name: string;
  uidFrom: string;
  uidTo: string;
  zug: ConnectionResultZug[];
}

// Result of /web/connection/<startUid>/<endUid>
export interface ConnectionSearchResult {
  connection: ConnectionResultData[];
  operatorChanges: number;
  realDistance: number;
  terminalChanges: number;
  transferNumber: number;
}

// Result of /location/plz/:countryCode?plz=:query
export interface PostalCodeQueryResult {
  uid: string;
  country: 'DE';
  plz: '67258';
  loc: ParsedGeoLocation;
  entries: PostalCodeLocationEntry[];
  city: PostalCodeLocationName;
}

export interface PostalCodeLocationEntry {
  loc: ParsedGeoLocation;
  city: PostalCodeLocationName;
  destrict: PostalCodeLocationName;
  region: PostalCodeLocationName;
}

export type PostalCodeLocationName = { [languageCode: string]: string } & {
  def: string;
};
