var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b-terminal-detail-base"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-offset-1 col-sm-11"},[(!_vm.editName)?_c('h1',[_vm._v("\n        "+_vm._s(_vm.itemData.name)+"\n        "),_c('button',{staticClass:"button icon edit",attrs:{"type":"button"},on:{"click":function($event){_vm.editName = true}}})]):_c('h1',[_c('div',{staticClass:"ng-inline-edit form-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemData.name),expression:"itemData.name"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
              'ng-inline-edit__input': true,
              error: _vm.$errors.has('name'),
            },attrs:{"type":"text","name":"name","id":"name"},domProps:{"value":(_vm.itemData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.itemData, "name", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"ng-inline-edit__inner-container"},[_c('button',{staticClass:"button icon check",attrs:{"type":"button","disabled":_vm.$errors.has('name')},on:{"click":_vm.saveName}}),_vm._v(" "),_c('button',{staticClass:"button icon abort",attrs:{"type":"button"},on:{"click":_vm.cancelEditName}})])])])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-offset-1 col-sm-11"},[_c('div',{staticClass:"form-row"},[_c('div',[_c('div',{staticClass:"switch icon",class:{
              'switch-on': _vm.itemData.active,
              'switch-off': !_vm.itemData.active,
            },on:{"click":function($event){return _vm.changeActiveState()}}}),_vm._v(" "),_c('div',{staticClass:"switch switch-label",on:{"click":function($event){return _vm.changeActiveState()}}},[_vm._v("\n            "+_vm._s(_vm.$t('Terminal aktiv'))+"\n          ")])])])])]),_vm._v(" "),_c('div',{staticClass:"row form-buttons"},[_c('div',{staticClass:"col-xs-12 col-sm-offset-1 col-sm-11"},[_c('div',[_c('router-link',{staticClass:"icon edit",attrs:{"to":{
            name: 'terminal.edit.contact',
            params: { uid: _vm.itemData.uid },
          }}},[_vm._v(_vm._s(_vm.$t('Kontakt')))])],1),_vm._v(" "),_c('div',[_c('router-link',{staticClass:"icon edit",attrs:{"to":{
            name: 'terminal.edit.features',
            params: { uid: _vm.itemData.uid },
          }}},[_vm._v(_vm._s(_vm.$t('Features')))])],1),_vm._v(" "),_c('div',[_c('router-link',{staticClass:"icon edit",attrs:{"to":{
            name: 'terminal.edit.documents',
            params: { uid: _vm.itemData.uid },
          }}},[_vm._v(_vm._s(_vm.$t('Upload')))])],1)])]),_vm._v(" "),_c('div',{staticClass:"row back"},[_c('div',{staticClass:"col-xs-1 col-sm-1"},[_c('router-link',{staticClass:"icon back",attrs:{"to":{ name: 'terminal' }}},[_vm._v(_vm._s(_vm.$t('Zurück')))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }