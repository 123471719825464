var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"booking-connection-selector"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nameFilter),expression:"nameFilter"}],staticClass:"filter",attrs:{"type":"text","placeholder":_vm.$t('Filter...')},domProps:{"value":(_vm.nameFilter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nameFilter=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"fixed-table-container"},[_c('div',{staticClass:"fixed-table-container-inner"},[_c('table',{staticClass:"extended-view"},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"5"}},[_c('div',{staticClass:"th-inner sortable",class:{
                  sorted: true,
                  reverse: _vm.reverseSort,
                },on:{"click":function($event){return _vm.toggleConnectionSort()}}},[_vm._v("\n                "+_vm._s(_vm.$t('Verbindung'))+"\n              ")])]),_vm._v(" "),_c('th',[_c('div',{staticClass:"th-inner"},[_vm._v("\n                "+_vm._s(_vm.$t('buchbar?'))+"\n              ")])])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.filteredOptions),function(item){return _c('tr',{key:item.key,on:{"click":function($event){if (item.selected) {
                _vm.removeIdFromSelection(item.id);
              } else {
                _vm.addIdToSelection(item.id);
              }}}},[_c('td',{attrs:{"colspan":"5"}},[_vm._v(_vm._s(item.from)+" - "+_vm._s(item.to))]),_vm._v(" "),_c('td',[_c('span',{staticClass:"custom-button type-checkbox small"},[_c('input',{attrs:{"id":item.key,"type":"checkbox"},domProps:{"value":item.selected,"checked":item.selected},on:{"input":function($event){$event.preventDefault();$event.stopPropagation();if (item.selected) {
                      _vm.removeIdFromSelection(item.id);
                    } else {
                      _vm.addIdToSelection(item.id);
                    }}}}),_vm._v(" "),_c('label',{attrs:{"for":item.key}})])])])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }