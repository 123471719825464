import { WAGON_LOAD, COUNTRY } from 'store/actions';
import {
  COMPANY as COMPANY_GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
  COUNTRY as COUNTRY_GETTERS,
} from 'store/getters';
import { normalizeUrl, sortListBy } from 'service/utils';
import { editComponent } from 'mixins/edit-component';
import { editPlaceComponent } from 'mixins/edit-place-component';
import Vue from 'vue';
import { WagonLoad } from 'kv_shared/lib/data-types';
import { mapGetters } from 'vuex';
import map from 'lodash/map';
// import isEqual from 'lodash/isEqual';

export default (Vue as any).extend({
  mixins: [
    editComponent(WagonLoad, state => state.wagonLoad.current),
    editPlaceComponent(COMPANY_GETTERS.WAGON_LOAD),
  ],

  created() {
    this.$store.dispatch(WAGON_LOAD.LOAD_CURRENT_ITEM, this.uid);
    this.$store.dispatch(COUNTRY.LOAD_LIST);
  },

  computed: {
    ...mapGetters({
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
      countryList: COUNTRY_GETTERS.BY_ID,
      countryNameList: COUNTRY_GETTERS.NAMES_BY_ID,
    }),

    isSuperUser() {
      return this.currentUser.isSuperUser;
    },

    countryOptions() {
      const options = map(this.countryNameList, (countryName, uid) => ({
        label: countryName,
        value: uid,
      }));
      return sortListBy(options, ['label']);
    },

    countrySelection: {
      get() {
        const preselection = this.itemData.country.map(
          country =>
            this.countryOptions.find(opt => opt.value === country.uid) || {
              value: country.uid,
              label: ' ',
            }, // default value if companyOptions are not yet loaded
        );
        return sortListBy(preselection, 'label');
      },
      set(selectionArray) {
        const uids = selectionArray.map(item => ({ uid: item && item.value }));

        // // Prevent endless circular updates
        // if (!isEqual(uids, this.itemData.company)) {
        this.itemData.country = uids;
        // }
      },
    },
  },

  methods: {
    normalizeUrl() {
      this.itemData.contact1.url = normalizeUrl(this.itemData.contact1.url);
      this.itemData.contact2.url = normalizeUrl(this.itemData.contact2.url);
      this.itemData.contact3.url = normalizeUrl(this.itemData.contact3.url);
    },

    submitCallback() {
      return this.$store
        .dispatch(WAGON_LOAD.SAVE_ITEM, this.itemData)
        .catch(e => {
          if (this.itemData.uid) {
            alert(
              this.$t(
                'Beim Ändern des Einzelwagenverkehr ist ein Fehler aufgetreten.',
              ),
            );
          } else {
            alert(
              this.$t(
                'Beim Anlegen des Einzelwagenverkehr ist ein Fehler aufgetreten.',
              ),
            );
          }
          console.error('item save error', e);
        });
    },
  },
});
