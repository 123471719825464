import { mapGetters } from 'vuex';
import { COMPANY, COUNTRY, CONVEYANCE } from 'store/actions';
// tslint:disable-next-line:max-line-length
import {
  CONVEYANCE as GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
  COUNTRY as COUNTRY_GETTERS,
} from 'store/getters';
import { editComponent } from 'mixins/edit-component';
import Vue from 'vue';
import map from 'lodash/map';
import { sortListBy, normalizeUrl } from 'service/utils';
import { Company } from 'kv_shared/lib/data-types';

export default (Vue as any).extend({
  mixins: [editComponent(Company, state => state.companies.current)],

  // Data fetching
  created() {
    this.$store.dispatch(COMPANY.LOAD_CURRENT_ITEM, this.uid);
    this.$store.dispatch(COUNTRY.LOAD_LIST);
    this.$store.dispatch(CONVEYANCE.LOAD_LIST);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {};
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapGetters({
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
      countryNameList: COUNTRY_GETTERS.NAMES_BY_ID,
      conveyanceAreas: GETTERS.AREAS_OF_CURRENT_FORWARDER,
    }),

    returnToMainPage() {
      return (
        this.currentUser &&
        !this.currentUser.isSuperUser &&
        this.currentUser.company &&
        this.currentUser.company.length <= 1
      );
    },

    // Select-Options

    countryOptions() {
      const options = map(this.countryNameList, (name, uid) => ({
        value: uid,
        label: name,
      }));
      return sortListBy(options, 'label');
    },

    countrySelection: {
      get() {
        return this.countryOptions.find(
          opt => opt.value === this.itemData.address.country.uid,
        );
      },
      set(value) {
        this.itemData.address.country.uid = value && value.value;
      },
    },
  },

  methods: {
    normalizeUrl() {
      this.itemData.contact.url = normalizeUrl(this.itemData.contact.url);
    },

    submitCallback() {
      return this.$store.dispatch(COMPANY.SAVE_ITEM, this.itemData).catch(e => {
        if (this.itemData.uid) {
          alert(
            this.$t('Beim Ändern der Spedition ist ein Fehler aufgetreten.'),
          );
        } else {
          alert(
            this.$t('Beim Anlegen der Spedition ist ein Fehler aufgetreten.'),
          );
        }
        console.error('update error', e);
      });
    },
  },
});
