var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b-terminal"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-offset-1 col-sm-8 col-md-5 col-lg-4"},[_c('h2',{staticClass:"not-mobile"},[_vm._v(_vm._s(_vm.$t('Terminals')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchFilter),expression:"searchFilter"}],staticClass:"filter",attrs:{"type":"text","placeholder":_vm.$t('Filter...')},domProps:{"value":(_vm.searchFilter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchFilter=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"fixed-table-container"},[_c('div',{staticClass:"table-header"}),_vm._v(" "),_c('div',{staticClass:"fixed-table-container-inner"},[_c('table',{staticClass:"terminals"},[_c('thead',[_c('tr',[_c('th',[_c('div',{staticClass:"th-inner sortable",class:{ sorted: _vm.orderBy == 'active', reverse: _vm.reverse },on:{"click":function($event){return _vm.order('active')}}},[_vm._v("\n                    "+_vm._s(_vm.$t('aktiv'))+"\n                  ")])]),_vm._v(" "),_c('th',[_c('div',{staticClass:"th-inner sortable",class:{ sorted: _vm.orderBy == 'name', reverse: _vm.reverse },on:{"click":function($event){return _vm.order('name')}}},[_vm._v("\n                    "+_vm._s(_vm.$t('Terminal'))+"\n                  ")])]),_vm._v(" "),_c('th',[_c('div',{staticClass:"th-inner sortable",class:{
                      sorted: _vm.orderBy == 'companyName',
                      reverse: _vm.reverse,
                    },on:{"click":function($event){return _vm.order('companyName')}}},[_vm._v("\n                    "+_vm._s(_vm.$t('Terminalbetreiber'))+"\n                  ")])]),_vm._v(" "),(_vm.currentUser.isSuperUser)?_c('th'):_vm._e(),_vm._v(" "),_c('th')])]),_vm._v(" "),_c('tbody',[_vm._l((_vm.filteredItems),function(item){return _c('tr',{key:item.uid},[_c('td',[_c('span',{staticClass:"icon small",class:{
                      'radio-on': item.active,
                      'radio-off': !item.active,
                    }})]),_vm._v(" "),_c('td',[_c('div',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('div',[_vm._v("\n                    "+_vm._s((item.address && item.address.country) ||
                        _vm.$t('(kein Land angegeben)'))+"\n                  ")])]),_vm._v(" "),_c('td',[_c('div',[_vm._v("\n                    "+_vm._s(item.companyName ||
                        _vm.$t('(kein Terminalbetreiber angegeben)'))+"\n                  ")])]),_vm._v(" "),(_vm.currentUser.isSuperUser)?_c('td',[_c('router-link',{staticClass:"icon login",attrs:{"to":{
                      name: 'terminal.edit.company',
                      params: { uid: item.uid },
                    },"title":_vm.$t('Terminal übertragen')}})],1):_vm._e(),_vm._v(" "),_c('td',[_c('router-link',{staticClass:"icon edit",attrs:{"to":{ name: 'terminal.edit', params: { uid: item.uid } },"title":_vm.$t('Terminal bearbeiten')}})],1)])}),_vm._v(" "),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.terminals),expression:"!terminals"}],staticClass:"empty-list"},[_c('td',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.$t('kein Terminal vorhanden')))])])],2)])])])])]),_vm._v(" "),_c('div',{staticClass:"row buttons"},[_c('div',{staticClass:"col-xs-12 col-sm-offset-1 col-sm-8 col-md-5 col-lg-4"},[_c('router-link',{staticClass:"icon add",attrs:{"to":{ name: 'terminal.add' },"title":_vm.$t('neues Terminal hinzufügen')}},[_vm._v(_vm._s(_vm.$t('Terminal hinzufügen')))])],1)]),_vm._v(" "),_c('div',{staticClass:"row back"},[_c('div',{staticClass:"col-xs-1 col-sm-1"},[_c('router-link',{staticClass:"icon back",attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t('Zurück')))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }