import { mapState } from 'vuex';
import { UI } from 'store/actions';

export default {
  computed: {
    ...mapState<any>({
      modal: state => state.ui.modal,
    }),
  },

  methods: {
    close() {
      this.$store.dispatch(UI.CLOSE_MODAL);
    },

    confirm() {
      this.$store.dispatch(UI.CLOSE_MODAL, { confirmed: true });
    },
  },
};
