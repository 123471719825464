import { mapState } from 'vuex';
import { LOADING_POINT, UI } from 'store/actions';
import { COMPANY as COMPANY_GETTERS } from 'store/getters';
import { isRailwaySiding } from 'service/data-types';
import { normalizeUrl } from 'service/utils';
import { editPlaceComponent } from 'mixins/edit-place-component';
import Vue from 'vue';
import { editComponent } from 'mixins/edit-component';
import isEqual from 'lodash/isEqual';
import { LoadingPoint, RailwaySidingFeatures } from 'kv_shared/lib/data-types';

export default (Vue as any).extend({
  mixins: [
    editComponent(LoadingPoint, state => state.loadingPoints.current),
    editPlaceComponent(COMPANY_GETTERS.LOADING_POINT_CARRIERS),
  ],

  // Data fetching
  created() {
    this.$store.dispatch(LOADING_POINT.LOAD_CURRENT_ITEM, this.uid);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      isUploading: false,
      filesToDelete: [],
      filesToUpload: [],
      emptyFeatures: new RailwaySidingFeatures(),
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapState<any>({
      apiUrl: state => state.api.apiUrl,
    }),

    isRailwaySiding() {
      return isRailwaySiding(this.currentCompany);
    },

    isEmptySidingFeatures() {
      if (this.itemData.features) {
        return isEqualFeatures(this.itemData.features, this.emptyFeatures);
      }
      return true;
    },

    placeholderDefaults() {
      return {
        name: this.$t('Name'),
        phone: this.$t('Telefon'),
        fax: this.$t('Fax'),
        email: this.$t('E-Mail'),
        web: this.$t('Web'),
      };
    },

    currentPlaceholder() {
      const company = this.currentCompany;
      const defaultPlaceholder = this.placeholderDefaults;
      return company && company.contact
        ? {
            name: company.contact.name || defaultPlaceholder.name,
            phone: company.contact.phone || defaultPlaceholder.phone,
            fax: company.contact.fax || defaultPlaceholder.fax,
            email: company.contact.email || defaultPlaceholder.email,
            web: company.contact.url || defaultPlaceholder.web,
          }
        : defaultPlaceholder;
    },
  },

  methods: {
    normalizeUrl() {
      this.itemData.contact.url = normalizeUrl(this.itemData.contact.url);
    },

    prepareUpload(files) {
      const doc = files && files[0];

      if (doc && !this.filesToUpload.find(d => isEqual(d, doc))) {
        doc.label = doc.label || '';
        this.filesToUpload.push(doc);
        this.itemData.documents.push(doc);
      }
    },

    prepareDelete(doc) {
      this.$store
        .dispatch(UI.SHOW_CONFIRM_MODAL, {
          message: this.$t('Wollen Sie das Dokument löschen?'),
          confirm: this.$t('Ja'),
          close: this.$t('Nein'),
        })
        .then(response => {
          if (response.confirmed) {
            if (doc.uid && !this.filesToDelete.find(d => isEqual(d, doc))) {
              this.filesToDelete.push(doc);
            }
            this.itemData.documents = this.itemData.documents.filter(
              d => !isEqual(d, doc),
            );
            this.filesToUpload = this.filesToUpload.filter(
              d => !isEqual(d, doc),
            );
          }
        });
    },

    cancelEdit() {
      this.filesToDelete = [];
      this.filesToUpload = [];
      this.resetData();
    },

    submitCallback() {
      this.isUploading = true;

      // Sequentially delete files
      const deletePromise = this.filesToDelete
        .reduce((promise, doc) => {
          return promise.then(() => {
            return this.$store
              .dispatch(LOADING_POINT.REMOVE_DOCUMENT, {
                loadingPoint: this.itemData,
                document: doc,
              })
              .catch(e => {
                console.log('error deleting file', doc, e);
              });
          });
        }, Promise.resolve())
        .then(() => (this.filesToDelete = []));

      // Sequentially upload files
      const uploadPromise = this.filesToUpload
        .reduce((promise, doc) => {
          return promise.then(() => {
            return this.$store
              .dispatch(LOADING_POINT.ADD_DOCUMENT, {
                loadingPoint: this.itemData,
                file: doc,
              })
              .catch(e => {
                console.log('error uploading file', doc, e);
                this.itemData.documents = this.itemData.documents.filter(
                  d => !isEqual(d, doc),
                );
              });
          });
        }, deletePromise)
        .then(() => (this.filesToUpload = []));

      // Save loading point if changed
      uploadPromise
        .then(() => {
          return this.$store
            .dispatch(LOADING_POINT.SAVE_ITEM, this.itemData)
            .catch(e => {
              if (this.itemData.uid) {
                alert(
                  this.$t(
                    'Beim Ändern der Ladestelle ist ein Fehler aufgetreten.',
                  ),
                );
              } else {
                alert(
                  this.$t(
                    'Beim Anlegen der Ladestelle ist ein Fehler aufgetreten.',
                  ),
                );
              }
              console.error('update error', e);
            });
        })
        .then(() => {
          this.isUploading = false;
        })
        .catch(() => {
          this.isUploading = false;
        });
    },
  },
});

function isEqualFeatures(f1, f2) {
  for (const key1 in f1) {
    for (const key2 in f1[key1]) {
      if (!isEqual(f1[key1][key2], f2[key1][key2])) {
        return false;
      }
    }
  }
  return true;
}
