import { mapState } from 'vuex';
import { NEWS } from 'store/actions';
import Vue from 'vue';
import { editComponent } from 'mixins/edit-component';
import moment from 'moment';
import { normalizeUrl } from 'service/utils';
import { News } from 'kv_shared/lib/data-types';
import { ORIGINAL_LANGUAGE } from 'kv_shared/lib/utils/localization';
import { de, en, fr, it } from 'vuejs-datepicker/dist/locale';

export default (Vue as any).extend({
  mixins: [editComponent(News, state => state.news.current)],

  // Data fetching
  created() {
    this.$store.dispatch(NEWS.LOAD_CURRENT_ITEM, this.uid);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      currentLang: ORIGINAL_LANGUAGE,
      dateLanguages: { de, en, fr, it },
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapState<any>({
      availableLanguages: state => state.availableLanguages,
    }),

    languageOptions() {
      return this.availableLanguages.map(l => ({
        value: l,
        label: l.toUpperCase(),
      }));
    },

    languageSelection: {
      get() {
        return {
          value: this.currentLang,
          label: this.currentLang.toUpperCase(),
        };
      },
      set(value) {
        this.currentLang = value.value;
      },
    },

    currentTitle: {
      get() {
        let message = this.itemData.localeTitle[this.currentLang];
        if (this.currentLang === ORIGINAL_LANGUAGE) {
          message =
            message ||
            (this.itemData.title === messageDeprecationString
              ? ''
              : this.itemData.title);
        }
        return message || '';
      },

      set(value) {
        Vue.set(
          this.itemData.localeTitle,
          this.currentLang || ORIGINAL_LANGUAGE,
          value,
        );
      },
    },

    currentMessage: {
      get() {
        let message = this.itemData.localeMessage[this.currentLang];
        if (this.currentLang === ORIGINAL_LANGUAGE) {
          message =
            message ||
            (this.itemData.message === messageDeprecationString
              ? ''
              : this.itemData.message);
        }
        return message || '';
      },

      set(value) {
        Vue.set(
          this.itemData.localeMessage,
          this.currentLang || ORIGINAL_LANGUAGE,
          value,
        );
      },
    },

    // https://github.com/charliekassel/vuejs-datepicker/issues/118
    currentDateSelection: {
      get() {
        return (
          this.itemData.publishDate &&
          moment(this.itemData.publishDate).toDate()
        );
      },
      set(value) {
        this.itemData.publishDate = value && value.getTime();
      },
    },

    datepickerLanguage() {
      return this.dateLanguages[this.currentLang] || this.dateLanguages.en;
    },
  },

  methods: {
    normalizeUrl() {
      this.itemData.url = normalizeUrl(this.itemData.url);
    },

    openDatepicker() {
      this.$refs.calendar.showCalendar();
    },

    submitCallback() {
      // update title and message from single attribute to localized fields
      if (
        this.itemData.title &&
        this.itemData.title !== messageDeprecationString
      ) {
        if (!this.itemData.localeTitle[ORIGINAL_LANGUAGE]) {
          Vue.set(
            this.itemData.localeTitle,
            ORIGINAL_LANGUAGE,
            this.itemData.title,
          );
        }
        this.itemData.title = messageDeprecationString;
      }
      if (
        this.itemData.message &&
        this.itemData.message !== messageDeprecationString
      ) {
        if (!this.itemData.localeMessage[ORIGINAL_LANGUAGE]) {
          Vue.set(
            this.itemData.localeMessage,
            ORIGINAL_LANGUAGE,
            this.itemData.message,
          );
        }
        this.itemData.message = messageDeprecationString;
      }

      return this.$store.dispatch(NEWS.SAVE_ITEM, this.itemData).catch(e => {
        if (this.itemData.uid) {
          alert(
            this.$t('Beim Ändern der Ladestelle ist ein Fehler aufgetreten.'),
          );
        } else {
          alert(
            this.$t('Beim Anlegen der Ladestelle ist ein Fehler aufgetreten.'),
          );
        }
        console.error('update error', e);
      });
    },
  },
});

const messageDeprecationString = 'deprecated, using localization instead';
