import { mapGetters } from 'vuex';
import { COMPANY, COUNTRY, LOADING_POINT, USER } from 'store/actions';
// tslint:disable-next-line:max-line-length
import {
  AUTHENTICATION as AUTHENTICATION_GETTERS,
  COMPANY as COMPANY_GETTERS,
  COUNTRY as COUNTRY_GETTERS,
  LOADING_POINT as LOADING_POINT_GETTERS,
} from 'store/getters';
import { editComponent } from 'mixins/edit-component';
import Vue from 'vue';
import map from 'lodash/map';
import { sortListBy, normalizeUrl } from 'service/utils';
import {
  Company,
  CompanyHackType,
  CompanyType,
} from 'kv_shared/lib/data-types';
import { isCompanyBookingProvider } from 'kv_shared/lib/bookingProviderHack';
import { handleHackCompanyType } from 'service/data-types';
import BookableConnectionSelector from '../booking/ConnectionSelector.vue';

export default (Vue as any).extend({
  mixins: [editComponent(Company, state => state.companies.current)],

  components: { BookableConnectionSelector },

  // Data fetching
  created() {
    this.$store.dispatch(COMPANY.LOAD_CURRENT_ITEM, this.uid);
    this.$store.dispatch(COUNTRY.LOAD_LIST);
    this.$store.dispatch(LOADING_POINT.LOAD_COMPANY_TYPES);

    if (this.$route.query.companyType) {
      this.companyTypeSelection = { value: this.$route.query.companyType };
    }
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {};
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapGetters({
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
      countryNameList: COUNTRY_GETTERS.NAMES_BY_ID,
      loadingPointCompanyTypes: LOADING_POINT_GETTERS.COMPANY_TYPES_BY_ID,
      companyTypeNames: COMPANY_GETTERS.TYPE_NAMES,
    }),

    returnToMainPage() {
      return (
        this.currentUser &&
        !this.currentUser.isSuperUser &&
        !this.currentUser.isSubChiefEditor &&
        this.currentUser.company &&
        this.currentUser.company.length <= 1
      );
    },

    isOperator() {
      return this.itemData.companyType[0] === CompanyType.OPERATOR;
    },

    isLoadingPointCarrier() {
      return this.itemData.companyType[0] === CompanyType.LOADING_POINT_CARRIER;
    },

    isBookingProvider() {
      return isCompanyBookingProvider(this.itemData);
    },

    selectedCompanyTypeName() {
      return this.isBookingProvider
        ? this.companyTypeNames[CompanyHackType.BOOKING_PROVIDER]
        : this.companyTypeNames[this.itemData.companyType[0]];
    },

    // Select-Options

    companyTypeOptions() {
      let options = map(this.companyTypeNames, (name, type) => ({
        value: type,
        label: name,
      }));

      if (!this.currentUser.isSuperUser) {
        options = options.filter(o =>
          this.currentUser.userRole.includes(o.value),
        );
      }

      return options;
    },

    companyTypeSelection: {
      get() {
        return this.companyTypeOptions.find(
          opt => opt.value === this.itemData.companyType[0],
        );
      },
      set(value) {
        this.itemData.companyType = value ? [value.value] : [];
      },
    },

    countryOptions() {
      const options = map(this.countryNameList, (name, uid) => ({
        value: uid,
        label: name,
      }));
      return sortListBy(options, 'label');
    },

    countrySelection: {
      get() {
        return this.countryOptions.find(
          opt => opt.value === this.itemData.address.country.uid,
        );
      },
      set(value) {
        this.itemData.address.country.uid = value && value.value;
      },
    },

    lpTypeOptions() {
      return map(this.loadingPointCompanyTypes, (type: any) => ({
        value: type.id,
        label: type.label,
      }));
    },

    lpTypeSelection: {
      get() {
        return this.lpTypeOptions.find(
          opt => opt.value === this.itemData.loadingPointCompanyType,
        );
      },
      set(value) {
        this.itemData.loadingPointCompanyType = value && value.value;
      },
    },
  },

  methods: {
    normalizeUrl() {
      this.itemData.contact.url = normalizeUrl(this.itemData.contact.url);
    },
    normalizeUrl1() {
      this.itemData.urls.url1 = normalizeUrl(this.itemData.urls.url1);
    },
    normalizeUrl2() {
      this.itemData.urls.url2 = normalizeUrl(this.itemData.urls.url2);
    },
    normalizeUrl3() {
      this.itemData.urls.url3 = normalizeUrl(this.itemData.urls.url3);
    },

    submitCallback() {
      const isNewCompany = !this.itemData.uid;

      this.itemData = handleHackCompanyType(this.itemData);

      return this.$store
        .dispatch(COMPANY.SAVE_ITEM, this.itemData)
        .then(() => this.saveSubChiefEditorWithNewCompany(isNewCompany))
        .catch(e => {
          if (this.itemData.uid) {
            alert(
              this.$t(
                'Beim Ändern des Unternehmens ist ein Fehler aufgetreten.',
              ),
            );
          } else {
            alert(
              this.$t(
                'Beim Anlegen des Unternehmens ist ein Fehler aufgetreten.',
              ),
            );
          }
          console.error('update error', e);
        });
    },

    saveSubChiefEditorWithNewCompany(isNew) {
      if (this.currentUser.isSubChiefEditor && isNew && this.itemData.uid) {
        console.log('saving new company of subChiefEditor');
        const user = this.$store.state.authentication.user;
        user.company.push({ uid: this.itemData.uid });
        return this.$store.dispatch(USER.SAVE_ITEM, user);
      }
    },
  },
});
