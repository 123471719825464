import {
  AUTHENTICATION as ACTIONS,
  API,
  UI,
  GLOBAL,
  USER,
} from 'store/actions';
import { AUTHENTICATION as MUTATIONS } from 'store/mutations';
import {
  AUTHENTICATION as GETTERS,
  COMPANY as COMPANY_GETTERS,
} from 'store/getters';
import { LOCAL_SESSION_KEY, USER_SPECIAL_ROLE } from 'service/constants';
import router from 'config/router';
import { UserRole } from 'kv_shared/lib/data-types';
import { isUserBookingProvider } from 'kv_shared/lib/bookingProviderHack';

let user = null;
let authData = null;

let localUser: any = localStorage.getItem(LOCAL_SESSION_KEY);
if (localUser) {
  try {
    localUser = JSON.parse(localUser);
    if (localUser.user) {
      user = localUser.user;
    }
    if (localUser.authData) {
      authData = localUser.authData;
    }
  } catch (e) {
    console.error('cannot retrieve local session', e, localUser);
  }
}

const state = {
  user,
  authData,
  reauthRequest: false,
};

const getters = {
  [GETTERS.CURRENT_USER](state, getters) {
    return {
      isLoggedIn: !!state.authData,
      isAdmin: hasRole(state.user, UserRole.ADMIN),
      isChiefEditor: hasRole(state.user, UserRole.CHIEF_EDITOR),
      isOperator: hasRole(state.user, UserRole.OPERATOR),
      isTerminalCarrier: hasRole(state.user, UserRole.TERMINAL_CARRIER),
      isLoadingPointCarrier: hasRole(
        state.user,
        UserRole.LOADING_POINT_CARRIER,
      ),
      isServiceStationCarrier: hasRole(
        state.user,
        UserRole.SERVICE_STATION_CARRIER,
      ),
      isForwarder: hasRole(state.user, UserRole.FORWARDER),
      isWagonLoad: hasRole(state.user, UserRole.WAGON_LOAD),
      isContainerZug:
        state.user && state.user.specialRole === USER_SPECIAL_ROLE.CONTAINERZUG,
      isSuperUser:
        hasRole(state.user, UserRole.ADMIN) ||
        hasRole(state.user, UserRole.CHIEF_EDITOR),
      isSubChiefEditor: hasRole(state.user, UserRole.SUB_CHIEF_EDITOR),
      ...state.user,
      isBookingProvider: isUserBookingProvider(
        state.user,
        getters[COMPANY_GETTERS.BY_ID],
      ),
    };
  },
};

const actions = {
  [ACTIONS.LOGIN]({ commit, dispatch }, newUser) {
    commit(MUTATIONS.RESET);
    return dispatch(API.POST, {
      path: 'user/login',
      body: {
        ...newUser,
        email: newUser.email.toLowerCase(),
      },
    })
      .then(authData => {
        commit(MUTATIONS.SET_AUTH, authData);
        return authData;
      })
      .then(authData => dispatch(USER.API_GET_BY_ID, authData.uid))
      .then(userData => {
        commit(MUTATIONS.SET_USER, userData);
        if (userData.language) {
          dispatch(GLOBAL.CHANGE_LANGUAGE, userData.language);
        }
      });
  },

  [ACTIONS.LOGOUT]({ commit, dispatch }) {
    commit(MUTATIONS.RESET);
    dispatch(UI.CLOSE_MENU);
    router.push({ name: 'login' });
  },

  [ACTIONS.REAUTHENTICATE]({ state, commit, dispatch }) {
    if (state.reauthRequest) {
      return;
    }

    console.log('Requesting new auth token');

    commit(MUTATIONS.SET_REAUTH_REQUEST, true);

    return dispatch(API.POST, { path: 'user/roat' })
      .then(response => {
        console.log('getting roat response', response);
        commit(MUTATIONS.SET_REAUTH_REQUEST, false);
        if (response.oat && response.roat) {
          commit(MUTATIONS.SET_AUTH, { ...state.auth, ...response });
        } else {
          dispatch(ACTIONS.LOGOUT);
          throw new Error('no oat/roat available');
        }
      })
      .catch(e => {
        commit(MUTATIONS.SET_REAUTH_REQUEST, false);
        dispatch(ACTIONS.LOGOUT);
        throw e;
      });
  },

  [ACTIONS.RESET_PASSWORD]({ dispatch }, passwordData) {
    return dispatch(API.POST, {
      path: 'user/rsed',
      body: passwordData,
    });
  },

  [ACTIONS.NEW_PASSWORD]({ dispatch }, passwordData) {
    return dispatch(API.PUT, {
      path: 'user/setSed',
      body: passwordData,
    });
  },

  [ACTIONS.SAVE_LOCAL_SESSION]({ state }) {
    try {
      const json = JSON.stringify({
        authData: state.authData,
        user: state.user,
      });
      localStorage.setItem(LOCAL_SESSION_KEY, json);
    } catch (e) {
      console.error('error while saving local session', e);
    }
  },
};

const mutations = {
  [MUTATIONS.SET_AUTH](state, auth) {
    state.authData = auth;
  },

  [MUTATIONS.SET_USER](state, user) {
    state.user = user;
  },

  [MUTATIONS.RESET](state) {
    state.authData = null;
    state.user = null;
  },

  [MUTATIONS.SET_REAUTH_REQUEST](state, request) {
    state.reauthRequest = request;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

// private functions

function hasRole(user, role) {
  return user && user.userRole.includes(role);
}
