import { render, staticRenderFns } from "./SetPassword.vue?vue&type=template&id=6527795a&"
import script from "./setpassword.ts?vue&type=script&lang=js&"
export * from "./setpassword.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports