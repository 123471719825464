import { mapGetters, mapState } from 'vuex';
import { USER, COMPANY, COUNTRY } from 'store/actions';
// tslint:disable-next-line:max-line-length
import {
  COMPANY as COMPANY_GETTERS,
  COUNTRY as COUNTRY_GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
} from 'store/getters';
import { editComponent } from 'mixins/edit-component';
import Vue from 'vue';
import { sortListBy } from 'service/utils';
import isEqual from 'lodash/isEqual';
import { CompanyHackType, User, UserRole } from 'kv_shared/lib/data-types';
import { map } from 'lodash';
import { handleHackUserRole } from 'service/data-types';
import { isCompanyBookingProvider } from 'kv_shared/lib/bookingProviderHack';

export default (Vue as any).extend({
  mixins: [editComponent(User, state => state.users.current)],

  // add props here that are passed from parent templates or from router,
  // like item uid etc for data fetching.
  // do not mutate these, but use them as initial values for 'data'
  props: ['editLoggedInUser'],

  // Data fetching here if necessary
  created() {
    this.loadUserData();
    this.$store.dispatch(COMPANY.LOAD_LIST);
    this.$store.dispatch(COUNTRY.LOAD_LIST);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      apiErrors: {
        unknownError: false,
        emailExists: false,
      },
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapGetters({
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
      companyTypeNames: COMPANY_GETTERS.TYPE_NAMES,
      companies: COMPANY_GETTERS.OF_CURRENT_USER,
      countryNameList: COUNTRY_GETTERS.NAMES_BY_ID,
    }),

    ...mapState<any>({
      languages: state => state.availableLanguages,
    }),

    isForwarder() {
      return this.itemData.userRole.includes(UserRole.FORWARDER);
    },

    companyAndCountryDisabled() {
      return (
        !this.currentUser.isSuperUser &&
        this.currentUser.isSubChiefEditor &&
        (this.itemData.company.some(
          c => !this.currentUser.company.find(cc => cc.uid === c.uid),
        ) ||
          this.itemData.country.some(
            c => !this.currentUser.country.find(cc => cc.uid === c.uid),
          ))
      );
    },

    itemDataEmail: {
      get() {
        return this.itemData.email.toLowerCase();
      },

      set(value) {
        this.itemData.email = value && value.toLowerCase();
      },
    },

    languageOptions() {
      const lang = this.languages;
      return lang.map(language => ({
        label: language.toUpperCase(),
        value: language,
      }));
    },

    languageSelection: {
      get() {
        return this.languageOptions.find(
          opt => opt.value === this.itemData.language,
        );
      },

      set(value) {
        this.itemData.language = value && value.value;
      },
    },

    companyOptions() {
      const options = this.companies.map(company => {
        const typeName = isCompanyBookingProvider(company)
          ? this.companyTypeNames[CompanyHackType.BOOKING_PROVIDER]
          : this.companyTypeNames[company.companyType[0]] ||
            this.$t('(unbekannt)');

        return {
          label: company.name + ' (' + typeName + ')',
          value: company.uid,
        };
      });

      return sortListBy(options, 'label');
    },

    companySelection: {
      get() {
        const preselection = this.itemData.company.map(
          item =>
            this.companyOptions.find(opt => opt.value === item.uid) || {
              value: item.uid,
              label: ' ',
            }, // default value if companyOptions are not yet loaded
        );
        return sortListBy(preselection, 'label');
      },

      set(selectionArray) {
        const uids = selectionArray.map(item => ({ uid: item && item.value }));

        // Prevent endless circular updates
        if (!isEqual(uids, this.itemData.company)) {
          this.itemData.company = uids;
          this.setUserCompanyRoles();
        }
      },
    },

    countryOptions() {
      let options = map(this.countryNameList, (name, uid) => ({
        value: uid,
        label: name,
      }));
      if (!this.currentUser.isSuperUser) {
        const userCountries =
          (this.currentUser && this.currentUser.country) || [];
        options = options.filter(o =>
          userCountries.find(c => c.uid === o.value),
        );
      }
      return sortListBy(options, 'label');
    },

    countrySelection: {
      get() {
        return this.countryOptions.filter(opt =>
          this.itemData.country.find(c => c.uid === opt.value),
        );
      },
      set(values) {
        this.itemData.country = values.map(item => ({
          uid: item && item.value,
        }));
        console.log(this.itemData.country);
      },
    },

    subChiefEditorChecked: {
      get() {
        return this.itemData.userRole.includes(UserRole.SUB_CHIEF_EDITOR);
      },

      set(val) {
        if (this.currentUser.isSuperUser) {
          const isSubChiefEditor = this.itemData.userRole.includes(
            UserRole.SUB_CHIEF_EDITOR,
          );
          if (isSubChiefEditor !== val) {
            if (val) {
              this.itemData.userRole.push(UserRole.SUB_CHIEF_EDITOR);
            } else {
              this.itemData.userRole = this.itemData.userRole.filter(
                r => r !== UserRole.SUB_CHIEF_EDITOR,
              );
            }
          }
        }
      },
    },
  },

  watch: {
    editLoggedInUser: 'loadUserData',
  },

  // methods that are available in the component or in templates
  methods: {
    loadUserData() {
      this.$store.dispatch(
        USER.LOAD_CURRENT_ITEM,
        this.editLoggedInUser ? this.currentUser.uid : this.uid,
      );
    },

    setUserCompanyRoles() {
      // get unique companyRoles for selected companies
      const uniqueUserRoles = this.itemData.company.reduce((roles, item) => {
        const c = this.companies.find(company => company.uid === item.uid);
        if (c && c.companyType && c.companyType.length > 0) {
          for (const type of c.companyType) {
            roles[type] = true;
          }
        }
        return roles;
      }, {});

      // flatten array(s)
      const userRoles = Object.keys(uniqueUserRoles);

      // keep non company user roles
      if (this.itemData.userRole.includes(UserRole.ADMIN)) {
        userRoles.push(UserRole.ADMIN);
      }
      if (this.itemData.userRole.includes(UserRole.CHIEF_EDITOR)) {
        userRoles.push(UserRole.CHIEF_EDITOR);
      }
      if (this.itemData.userRole.includes(UserRole.SUB_CHIEF_EDITOR)) {
        userRoles.push(UserRole.SUB_CHIEF_EDITOR);
      }

      this.itemData = handleHackUserRole(this.itemData);

      this.itemData.userRole = userRoles;
    },

    submitCallback() {
      this.apiErrors.emailExists = false;
      this.apiErrors.unknownError = false;

      this.$store
        .dispatch(USER.SAVE_ITEM, this.itemData)
        .then(() =>
          this.$store.dispatch(
            USER.UPDATE_CONTAINER_ZUG_COMPANIES,
            this.itemData,
          ),
        )
        .catch(e => {
          if (e.dataPromise) {
            e.dataPromise.then(data => {
              switch (data.S_notification.code) {
                // email already exists
                case 2101:
                  this.apiErrors.emailExists = true;
                  break;
                default:
                  this.apiErrors.unknownError = true;
                  console.log('unhandled error code', data);
              }
            });
          } else if (this.itemData.uid) {
            alert(
              this.$t('Beim Ändern des Benutzers ist ein Fehler aufgetreten.'),
            );
          } else {
            alert(
              this.$t('Beim Anlegen des Benutzers ist ein Fehler aufgetreten.'),
            );
          }

          console.error('update error', e);
        });
    },
  },
});
