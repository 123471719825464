import { COMPANY as ACTIONS, API } from 'store/actions';
import { COMPANY as MUTATIONS } from 'store/mutations';
import {
  COMPANY as GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
} from 'store/getters';
import { COMPANY_TYPE_OBJECT } from 'service/constants';
import {
  listToIdMap,
  defaultValueOnHttpError,
  sortListBy,
} from 'service/utils';
import {
  defaultItemState,
  defaultItemActions,
  defaultItemMutations,
} from 'store/commons/item-list';
import Vue from 'vue';
import i18n from 'kv_shared/lib/vue/i18n';
import {
  defaultSearchableState,
  defaultSearchableActions,
  defaultSearchableMutations,
} from 'store/commons/searchable';
import { freezeToStopVueReactivity } from 'service/vue-helpers';
import { CompanyHackType, CompanyType } from 'kv_shared/lib/data-types';
import { isCompanyBookingProvider } from 'kv_shared/lib/bookingProviderHack';

const state = {
  ...defaultItemState(),
  ...defaultSearchableState(),
  configurations: {},
};

const getters = {
  [GETTERS.BY_ID]: state => listToIdMap(state.list),

  [GETTERS.OF_CURRENT_USER]: (state, getters) => {
    const user = getters[AUTHENTICATION_GETTERS.CURRENT_USER];
    if (user.isSuperUser) {
      return state.list;
    } else {
      return state.list.filter(
        company =>
          user.company &&
          user.company.find(userCompany => userCompany.uid === company.uid),
      );
    }
  },

  [GETTERS.TYPE_NAMES](_, __, rootState) {
    const names = {};
    for (const key in COMPANY_TYPE_OBJECT) {
      const typeObj = COMPANY_TYPE_OBJECT[key];
      names[typeObj.type] = i18n.t(typeObj.name);
    }
    return names;
  },

  [GETTERS.FILTERED_LIST]: (state, getters, rootState) => {
    const filtered: any[] = [];

    for (const company of getters[GETTERS.OF_CURRENT_USER]) {
      const companyName = company.name ? company.name.toLowerCase() : '';

      const companyTypeName = isCompanyBookingProvider(company)
        ? getters[GETTERS.TYPE_NAMES][CompanyHackType.BOOKING_PROVIDER]
        : getters[GETTERS.TYPE_NAMES][company.companyType[0]] ||
          i18n.t('(unbekannt)');

      if (
        companyName.indexOf(state.searchString) > -1 ||
        companyTypeName.toLowerCase().indexOf(state.searchString) > -1
      ) {
        filtered.push(
          freezeToStopVueReactivity({
            ...company,
            companyTypeName,
          }),
        );
      }
    }

    return filtered;
  },

  [GETTERS.BY_TYPE]: (_, getters) => {
    const companies = {
      [CompanyType.OPERATOR]: [] as any[],
      [CompanyType.TERMINAL_CARRIER]: [] as any[],
      [CompanyType.LOADING_POINT_CARRIER]: [] as any[],
      [CompanyType.SERVICE_STATION_CARRIER]: [] as any[],
      [CompanyType.FORWARDER]: [] as any[],
      [CompanyType.WAGON_LOAD]: [] as any[],
    };

    for (const company of getters[GETTERS.OF_CURRENT_USER]) {
      if (company.companyType.indexOf(CompanyType.OPERATOR) >= 0) {
        companies[CompanyType.OPERATOR].push(company);
      }
      if (company.companyType.indexOf(CompanyType.TERMINAL_CARRIER) >= 0) {
        companies[CompanyType.TERMINAL_CARRIER].push(company);
      }
      if (company.companyType.indexOf(CompanyType.LOADING_POINT_CARRIER) >= 0) {
        companies[CompanyType.LOADING_POINT_CARRIER].push(company);
      }
      if (
        company.companyType.indexOf(CompanyType.SERVICE_STATION_CARRIER) >= 0
      ) {
        companies[CompanyType.SERVICE_STATION_CARRIER].push(company);
      }
      if (company.companyType.indexOf(CompanyType.FORWARDER) >= 0) {
        companies[CompanyType.FORWARDER].push(company);
      }
      if (company.companyType.indexOf(CompanyType.WAGON_LOAD) >= 0) {
        companies[CompanyType.WAGON_LOAD].push(company);
      }
    }

    return companies;
  },

  [GETTERS.OPERATORS]: (_, getters) =>
    sortListBy(getters[GETTERS.BY_TYPE][CompanyType.OPERATOR], 'name'),

  [GETTERS.TERMINAL_CARRIERS]: (_, getters) =>
    sortListBy(getters[GETTERS.BY_TYPE][CompanyType.TERMINAL_CARRIER], 'name'),

  [GETTERS.LOADING_POINT_CARRIERS]: (_, getters) =>
    sortListBy(
      getters[GETTERS.BY_TYPE][CompanyType.LOADING_POINT_CARRIER],
      'name',
    ),

  [GETTERS.SERVICE_STATION_CARRIERS]: (_, getters) =>
    sortListBy(
      getters[GETTERS.BY_TYPE][CompanyType.SERVICE_STATION_CARRIER],
      'name',
    ),

  [GETTERS.FORWARDERS]: (_, getters) =>
    sortListBy(getters[GETTERS.BY_TYPE][CompanyType.FORWARDER], 'name'),

  [GETTERS.WAGON_LOAD]: (_, getters) =>
    sortListBy(getters[GETTERS.BY_TYPE][CompanyType.WAGON_LOAD], 'name'),
};

const actions = {
  ...defaultItemActions(ACTIONS, MUTATIONS),
  ...defaultSearchableActions(ACTIONS, MUTATIONS),

  [ACTIONS.GET_CONFIG_BY_TYPE]({ commit, dispatch }, type) {
    if (!Object.values(CompanyType).includes(type)) {
      return;
    }

    return dispatch(ACTIONS.API_GET_CONFIG_BY_TYPE, type).then(config =>
      commit(MUTATIONS.SET_CONFIG_BY_TYPE, { type, config }),
    );
  },

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, 'company')
      .then(response => {
        return response.companyList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, 'company/' + uid).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_DELETE_BY_ID]({ dispatch }, uid) {
    return dispatch(API.DELETE, 'company/' + uid);
  },

  [ACTIONS.API_GET_CONFIG_BY_TYPE]({ commit, dispatch }, type) {
    return dispatch(API.GET, 'company/' + type + '/configuration');
  },

  [ACTIONS.API_SAVE_CONFIG_BY_TYPE]({ dispatch }, data) {
    return dispatch(API.PUT, {
      path: 'company/' + data.uid + '/configuration',
      body: data,
    });
  },

  [ACTIONS.API_UPDATE]({ dispatch }, item) {
    return dispatch(API.PUT, {
      path: 'company/' + item.uid,
      body: item,
    });
  },

  [ACTIONS.API_CREATE]({ dispatch }, newItem) {
    return dispatch(API.POST, { path: 'company/create' }).then(response => {
      return dispatch(API.PUT, {
        path: 'company/' + response.uid + '/init',
        body: newItem,
      }).then(() => response);
    });
  },
};

const mutations = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSearchableMutations(MUTATIONS),

  [MUTATIONS.SET_CONFIG_BY_TYPE](state, { type, config }) {
    // Use Vue.set on dynamic property names for reactive updates
    Vue.set(state.configurations, type, config);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
