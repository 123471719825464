export const AUTHENTICATION = {
  CURRENT_USER: 'currentUser',
};

export const USER = {
  BY_ID: 'usersById',
  ROLE_NAMES: 'userRoleNames',
  FILTERED_LIST: 'usersFilteredList',
  CHIEF_EDITOR: 'getUserChiefEditor',
  ADMIN: 'getUserAdmin',
};

export const COUNTRY = {
  BY_ID: 'countriesById',
  BY_CODE: 'countryByCode',
  NAMES_BY_ID: 'countryNamesById',
};

export const LOCATION = {
  BY_ID: 'locationsById',
  LOCALE_NAMES: 'locationsLocaleNames',
  FILTERED_LIST: 'locationsFilteredList',
  SORTED_BY_NAME: 'locationsSortedByName',
};

export const COMPANY = {
  BY_ID: 'companiesById',
  OF_CURRENT_USER: 'companiesOfUser',
  FILTERED_LIST: 'companiesFilteredList',
  TYPE_NAMES: 'companyTypeNames',
  BY_TYPE: 'companiesByType',
  OPERATORS: 'operators',
  TERMINAL_CARRIERS: 'terminalCarriers',
  LOADING_POINT_CARRIERS: 'loadingPointCarriers',
  SERVICE_STATION_CARRIERS: 'serviceStationCarriers',
  WAGON_LOAD: 'wagonLoad',
  FORWARDERS: 'forwarders',
};

export const TERMINAL = {
  BY_ID: 'terminalsById',
  OF_CURRENT_USER: 'terminalsOfUser',
  FILTERED_LIST: 'terminalsFilteredList',
  BY_COUNTRY: 'terminalsActiveByCountry',
};

export const LOADING_POINT = {
  OF_CURRENT_USER: 'loadingPointsOfUser',
  COMPANY_TYPES_BY_ID: 'loadingPointCompanyTypesById',
  COMPANY_TYPE_LABELS: 'loadingPointCompanyTypeLabels',
  FILTERED_LIST: 'loadingPointFilteredList',
};

export const WAGON_LOAD = {
  OF_CURRENT_USER: 'wagonLoadOfUser',
  FILTERED_LIST: 'wagonLoadFilteredList',
};

export const CONNECTION = {
  BY_ID: 'connectionsById',
  OF_CURRENT_USER: 'connectionsOfUser',
  FILTERED_LIST: 'connectionsFilteredList',
};

export const TRAIN = {
  BY_ID: 'trainsById',
  OF_CURRENT_CONNECTION: 'trainsOfCurrentConnection',
  FILTERED_LIST: 'trainsFilteredList',
};

export const CONVEYANCE = {
  FILTERED_FORWARDER_LIST: 'conveyanceFilteredForwarderList',
  AREAS_OF_CURRENT_FORWARDER: 'conveyanceAreasOfCurrentForwarder',
  LOCATIONS_BY_COUNTRY: 'conveyanceLocationsByCountry',
};

export const NEWS = {
  FILTERED_LIST: 'newsFilteredList',
};
