import i18n from 'kv_shared/lib/vue/i18n';

export const weekDays = [
  {
    id: 1,
    label: i18n.t('Montag'),
  },
  {
    id: 2,
    label: i18n.t('Dienstag'),
  },
  {
    id: 3,
    label: i18n.t('Mittwoch'),
  },
  {
    id: 4,
    label: i18n.t('Donnerstag'),
  },
  {
    id: 5,
    label: i18n.t('Freitag'),
  },
  {
    id: 6,
    label: i18n.t('Samstag'),
  },
  {
    id: 7,
    label: i18n.t('Sonntag'),
  },
];

export const weekDaysMini = [
  {
    id: 1,
    label: {
      de: 'Mo',
      en: 'Mo',
    },
  },
  {
    id: 2,
    label: {
      de: 'Di',
      en: 'Tu',
    },
  },
  {
    id: 3,
    label: {
      de: 'Mi',
      en: 'We',
    },
  },
  {
    id: 4,
    label: {
      de: 'Do',
      en: 'Th',
    },
  },
  {
    id: 5,
    label: {
      de: 'Fr',
      en: 'Fr',
    },
  },
  {
    id: 6,
    label: {
      de: 'Sa',
      en: 'Sa',
    },
  },
  {
    id: 7,
    label: {
      de: 'So',
      en: 'Su',
    },
  },
];
