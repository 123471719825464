import { GLOBAL as MUTATIONS, API } from './mutations';
import { GLOBAL as ACTIONS } from './actions';
import { LocalConfig } from 'service/data-types';

const state = {
  locale: 'de',
  availableLanguages: ['de'],
  apiConfig: null,
};

const actions = {
  [ACTIONS.ADD_CONFIG]({ commit, dispatch }, config: LocalConfig) {
    commit(MUTATIONS.SET_CONFIG, config);
    commit(API.SET_URL, config.apiUrl);
  },

  [ACTIONS.CHANGE_LANGUAGE]({ commit }, language) {
    if (language) {
      commit(MUTATIONS.SET_LANGUAGE, language);
    }
  },
};

const mutations = {
  [MUTATIONS.SET_CONFIG](state, config) {
    state.apiConfig = config;
  },

  [MUTATIONS.SET_LANGUAGE](state, lang) {
    state.locale = lang;
  },

  [MUTATIONS.SET_AVAILABLE_LANGUAGES](state, langs) {
    if (langs && langs.length) {
      state.availableLanguages = langs.map(lang => lang.toLowerCase());
    }
  },
};

export default { state, mutations, actions };
