import { mapGetters, mapState } from 'vuex';
import { CONNECTION, COMPANY, TRAIN, LOCATION, COUNTRY } from 'store/actions';
// tslint:disable-next-line:max-line-length
import {
  LOCATION as LOCATION_GETTERS,
  COMPANY as COMPANY_GETTERS,
  COUNTRY as COUNTRY_GETTERS,
} from 'store/getters';
import { editComponent } from 'mixins/edit-component';
import Vue from 'vue';
import { sortListBy } from 'service/utils';
import map from 'lodash/map';
import router from '../../config/router';
import { Connection } from 'kv_shared/lib/data-types';

class DefaultStation {
  locality = '';
  country = '';
}

const defaultStations = [new DefaultStation(), new DefaultStation()];

export default (Vue as any).extend({
  mixins: [editComponent(Connection, state => state.connections.current)],

  // do not mutate these, but use them as initial values for 'data'
  props: ['templateId'],

  // Data fetching
  created() {
    if (this.uid) {
      this.$store.dispatch(CONNECTION.LOAD_CURRENT_ITEM, this.uid);
    } else if (this.templateId) {
      this.$store.dispatch(CONNECTION.LOAD_CURRENT_ITEM, this.templateId);
    } else {
      // Reset when creating new connection. Otherwise last open connection
      // serves as default item data.
      this.$store.dispatch(CONNECTION.RESET_CURRENT);
    }
    this.$store.dispatch(COMPANY.LOAD_LIST);
    this.$store.dispatch(TRAIN.LOAD_LIST);
    this.$store.dispatch(LOCATION.LOAD_LIST);
    this.$store.dispatch(COUNTRY.LOAD_LIST);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      companySelectionNeeded: true,
      draggableOptions: {
        handle: '.sort-handle',
      },
      stations: defaultStations,
    };
  },

  computed: {
    ...mapGetters({
      companies: COMPANY_GETTERS.OPERATORS,
      countryNames: COUNTRY_GETTERS.NAMES_BY_ID,
      locationNames: LOCATION_GETTERS.LOCALE_NAMES,
      locationById: LOCATION_GETTERS.BY_ID,
    }),

    ...mapState<any>({
      locationList: state => state.locations.list,
      trainList: state => state.trains.list,
    }),

    companyOptions() {
      const options = this.companies.map(company => ({
        label: company.name,
        value: company.uid,
      })) || [{ label: '', value: '' }];
      return sortListBy(options, 'label');
    },

    companySelection: {
      get() {
        return this.companyOptions.find(
          opt => opt.value === this.itemData.operator.uid,
        );
      },
      set(value) {
        this.itemData.operator.uid = value && value.value;
      },
    },

    hasTrains() {
      return !!(
        this.itemData.uid &&
        this.trainList.find(train => train.connection.uid === this.itemData.uid)
      );
    },

    countryOptions() {
      const options = map(this.countryNames, (name, uid) => ({
        uid,
        label: name,
      }));
      return sortListBy(options, 'label');
    },
  },

  watch: {
    locationById: 'resetData',
    $route: 'resetData', // react to route change after copy
    stations: {
      deep: true,
      handler: 'updateItemLocalities',
    },
  },

  // methods that are available in the component or in templates
  methods: {
    resetDataCallback() {
      const defaultData = new Connection();
      if (this.templateId) {
        this.itemData.uid = defaultData.uid;
        this.itemData.name = defaultData.name;
      }

      this.stations = this.itemData.locality.map(locality => {
        const location = this.locationById[locality.uid];

        if (location) {
          return {
            locality: location.uid,
            country: location.country.uid,
          };
        } else {
          return new DefaultStation();
        }
      });
    },

    updateItemLocalities() {
      this.itemData.locality = this.stations.map(station => ({
        uid: station.locality,
      }));
    },

    addLocationRow() {
      if (this.itemData.locality && this.itemData.locality.length < 10) {
        this.stations.push(new DefaultStation());
      }
    },

    deleteLocationRow(index) {
      this.stations.splice(index, 1);
    },

    locationOptions(countryUid) {
      const filteredLocations = this.locationList.filter(
        location => location.country.uid === countryUid,
      );
      const options = filteredLocations.map(location => ({
        value: location.uid,
        label: this.locationNames[location.uid],
      })) || [{ value: '', label: '' }];
      return sortListBy(options, 'label');
    },

    submitCallback() {
      return this.$store
        .dispatch(CONNECTION.SAVE_ITEM, this.itemData)
        .then(() => {
          if (this.$route.name === 'connection.copy') {
            router.replace({
              name: 'connection.edit',
              params: { uid: this.currentStoreData.uid },
            });
          }
        })
        .catch(e => {
          if (this.itemData.uid) {
            alert(
              this.$t('Beim Ändern der Verbindung ist ein Fehler aufgetreten.'),
            );
          } else {
            alert(
              this.$t(
                'Beim Anlegen der Verbindung ist ein Fehler aufgetreten.',
              ),
            );
          }
          console.error('update error', e);
        });
    },
  },
});
