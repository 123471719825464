import { MISC as ACTIONS, API } from 'store/actions';
import { MISC as MUTATIONS } from 'store/mutations';

const state = {
  imprintData: {},
};

const actions = {
  [ACTIONS.GET_IMPRINT]({ commit, dispatch }) {
    return dispatch(API.GET, 'misc/singleton/imprint').then(imprint =>
      commit(MUTATIONS.SET_IMPRINT, imprint),
    );
  },

  [ACTIONS.UPDATE_IMRPINT]({ dispatch, commit }, newImprintData) {
    return dispatch(API.PUT, {
      path: 'misc/singleton/imprint',
      body: newImprintData,
    }).then(() => {
      commit(MUTATIONS.SET_IMPRINT, newImprintData);
    });
  },
};

const mutations = {
  [MUTATIONS.SET_IMPRINT](state, imprint) {
    state.imprintData = imprint;
  },
};

export default {
  state,
  actions,
  mutations,
};
