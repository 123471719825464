import { WAGON_LOAD as ACTIONS, API } from 'store/actions';
import { WAGON_LOAD as MUTATIONS } from 'store/mutations';
import {
  WAGON_LOAD as GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
  COMPANY as COMPANY_GETTERS,
} from 'store/getters';
import { defaultValueOnHttpError } from 'service/utils';
import {
  defaultItemState,
  defaultItemActions,
  defaultItemMutations,
} from 'store/commons/item-list';
import {
  defaultSearchableState,
  defaultSearchableActions,
  defaultSearchableMutations,
} from 'store/commons/searchable';

const state = {
  ...defaultItemState(),
  ...defaultSearchableState(),
};

const getters = {
  [GETTERS.OF_CURRENT_USER]: (state, getters) => {
    const user = getters[AUTHENTICATION_GETTERS.CURRENT_USER];
    let list = state.list;
    if (!user.isSuperUser) {
      const companiesOfUser = getters[COMPANY_GETTERS.OF_CURRENT_USER];
      list = state.list.filter(wagonLoad =>
        companiesOfUser.find(company => company.uid === wagonLoad.company.uid),
      );
    }
    const companies = getters[COMPANY_GETTERS.BY_ID];
    return list.map(wl => ({
      ...wl,
      companyName:
        (companies[wl.company.uid] && companies[wl.company.uid].name) || '',
    }));
  },

  [GETTERS.FILTERED_LIST](state, getters, rootState) {
    const filtered: any[] = [];

    for (const wl of getters[GETTERS.OF_CURRENT_USER]) {
      const name = wl.name ? wl.name.toLowerCase() : '';
      const companyName = wl.companyName ? wl.companyName.toLowerCase() : '';
      if (
        name.indexOf(state.searchString) > -1 ||
        companyName.indexOf(state.searchString) > -1
      ) {
        filtered.push(wl);
      }
    }

    return filtered;
  },
};

const actions = {
  ...defaultItemActions(ACTIONS, MUTATIONS),
  ...defaultSearchableActions(ACTIONS, MUTATIONS),

  // API ACTIONS

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, 'area/wagonLoad')
      .then(response => {
        return response.areaList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, 'area/wagonLoad/' + uid).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_UPDATE]({ dispatch }, wagonLoad) {
    return dispatch(API.PUT, {
      path: 'area/wagonLoad/' + wagonLoad.uid,
      body: wagonLoad,
    });
  },

  [ACTIONS.API_CREATE]({ dispatch }, newItem) {
    return dispatch(API.POST, { path: 'area/wagonLoad/create' }).then(
      response => {
        return dispatch(API.PUT, {
          path: 'area/wagonLoad/' + response.uid + '/init',
          body: newItem,
        }).then(() => response);
      },
    );
  },

  [ACTIONS.API_DELETE_BY_ID]({ dispatch }, uid) {
    return dispatch(API.DELETE, 'area/wagonLoad/' + uid);
  },
};

const mutations = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSearchableMutations(MUTATIONS),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
