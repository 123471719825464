import {
  makeCompanyIntoHackBookingProvider,
  makeUserIntoHackBookingProvider,
} from 'kv_shared/lib/bookingProviderHack';
import {
  Company,
  CompanyHackType,
  User,
  UserHackRole,
} from 'kv_shared/lib/data-types';
import { LOADING_POINT_COMPANY_TYPE } from 'service/constants';

export interface LocalConfig {
  apiUrl: string;
}

export function isRailwaySiding(company) {
  return (
    company &&
    company.loadingPointCompanyType ===
      LOADING_POINT_COMPANY_TYPE.RAILWAY_SIDING
  );
}

/**
 * TODO: Remove after creating proper company type in API
 * @deprecated
 */
export function handleHackUserRole(itemData: User): User {
  if (itemData.userRole.includes(UserHackRole.BOOKING_PROVIDER as any)) {
    itemData = makeUserIntoHackBookingProvider(itemData);
  }
  return itemData;
}

/**
 * TODO: Remove after creating proper company type in API
 * @deprecated
 */
export function handleHackCompanyType(itemData: Company): Company {
  if (itemData.companyType.includes(CompanyHackType.BOOKING_PROVIDER as any)) {
    itemData = makeCompanyIntoHackBookingProvider(itemData);
  }
  return itemData;
}
