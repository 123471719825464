import { AUTHENTICATION, USER } from 'store/actions';

export default {
  props: ['email', 'uid'],

  created() {
    this.fetchUser();
  },

  data() {
    return {
      currentEmail: this.email,
      sed: '',
      newSed: '',
      passwordConfirm: '',

      apiErrors: null,
      passwordChanged: false,
      user: null,
    };
  },

  computed: {
    firstLogin() {
      return this.user == null || this.user.active === false;
    },
  },

  watch: {
    uid: 'fetchUser',
    user() {
      this.currentEmail =
        this.user && this.user.email && this.user.email.toLowerCase();
    },
  },

  methods: {
    fetchUser() {
      this.user = null;
      if (this.uid) {
        this.$store
          .dispatch(USER.API_GET_BY_ID, this.uid)
          .then(user => (this.user = user));
      }
    },

    goBack() {
      this.$router.back();
    },

    setPassword() {
      this.$validator
        .validateAll()
        .then(valid => {
          if (!valid) {
            console.log('form errors');
            return;
          }

          this.apiErrors = null;

          const passwordData = {
            email: this.currentEmail.toLowerCase(),
            sed: this.sed,
            newSed: this.newSed,
          };

          this.$store
            .dispatch(AUTHENTICATION.NEW_PASSWORD, passwordData)
            .then(() => {
              this.passwordChanged = true;
            })

            .catch(error => {
              // handle system specific errors
              if (error.dataPromise) {
                error.dataPromise.then(data => {
                  switch (data.S_notification) {
                    case 2104:
                      this.apiErrors = { wrongPassword: true };
                      break;
                    default:
                      this.apiErrors = { unknownError: true };
                      console.log('unhandled error code', data);
                  }
                });
              } else {
                console.error('unknown (not system specific) error', error);
              }
            });
        })
        .catch(e => {
          console.log('form errors', e);
        });
    },
  },
};
