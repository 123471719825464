import { mapState, mapGetters } from 'vuex';
import { USER, UI, COMPANY } from 'store/actions';
// tslint:disable-next-line:max-line-length
import {
  COMPANY as COMPANY_GETTERS,
  USER as USER_GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
} from 'store/getters';
import { sortListBy } from 'service/utils';
import { User, UserRole } from 'kv_shared/lib/data-types';
import { COMPANY_TYPE_OBJECT } from 'service/constants';

export default {
  // Data fetching here if necessary
  created() {
    this.$store.dispatch(USER.LOAD_LIST);
    this.$store.dispatch(COMPANY.LOAD_LIST);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      reverse: false,
      orderBy: 'firstName',
      user: {
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
      },
      companyTypeObject: (() => {
        const cto = { ...COMPANY_TYPE_OBJECT } as any;
        // remove booking provider welcome screen
        // TODO: return completet company type object after booking provider hack type is removed!
        delete cto.BOOKING_PROVIDER;
        return cto;
      })(),
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapState<any>({}),

    ...mapGetters({
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
      userList: USER_GETTERS.FILTERED_LIST,
      storeChiefEditor: USER_GETTERS.CHIEF_EDITOR,
      storeAdmin: USER_GETTERS.ADMIN,
      companies: COMPANY_GETTERS.BY_ID,
    }),

    userChiefEditor() {
      return this.storeChiefEditor || new User();
    },

    userAdmin() {
      return this.storeAdmin || new User();
    },

    filteredItems() {
      let filtered = [...this.userList];

      if (this.currentUser.isSubChiefEditor) {
        console.log('fufu', this.currentUser);
        filtered = filtered
          .filter(
            u =>
              u.company &&
              u.company.every(c =>
                this.currentUser.company.find(cc => cc.uid === c.uid),
              ),
          )
          .filter(u => {
            return !(
              u.userRole.includes(UserRole.CHIEF_EDITOR) ||
              u.userRole.includes(UserRole.ADMIN)
            );
          });
        console.log(filtered);
      }

      filtered = filtered.map(user => ({
        ...user,
        companies:
          user.company &&
          user.company
            .map(c => this.companies[c.uid])
            .filter(c => c)
            .sort((c1, c2) => c1.name.localeCompare(c2.name)),
      }));

      if (this.orderBy === 'userRoleName') {
        sortListBy(filtered, 'userRoleName', 'firstName');
      } else if (this.orderBy === 'company') {
        filtered.sort((u1, u2) => {
          if (!u1.companies || u1.companies.length === 0) {
            return -1;
          }
          if (!u2.companies || u2.companies.length === 0) {
            return 1;
          }
          return u1.companies[0].name.localeCompare(u2.companies[0].name);
        });
      } else {
        sortListBy(filtered, this.orderBy);
      }

      if (this.reverse) {
        filtered.reverse();
      }

      return filtered;
    },

    searchFilter: {
      get() {
        return this.$store.state.users.searchString;
      },
      set(value) {
        this.$store.dispatch(USER.FILTER_LIST, value);
      },
    },
  },

  // methods that are available in the component or in templates
  methods: {
    order(orderBy) {
      if (this.orderBy === orderBy) {
        this.reverse = !this.reverse;
      } else {
        this.orderBy = orderBy;
        this.reverse = false;
      }
    },

    deleteUser(item) {
      this.$store
        .dispatch(UI.SHOW_CONFIRM_MODAL, {
          message: this.$t(
            'Wollen Sie den Benutzer "{name}" wirklich löschen?',
            { name: item.firstName + ' ' + item.lastName },
          ),
          confirm: this.$t('Ja'),
          close: this.$t('Nein'),
        })
        .then(response => {
          if (response.confirmed) {
            this.$store.dispatch(USER.REMOVE_ITEM, item);
          }
        });
    },
  },
};
