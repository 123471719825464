import { mapGetters, mapState } from 'vuex';
import {
  CONNECTION,
  LOCATION,
  COMPANY,
  TRAIN,
  TERMINAL,
  UI,
} from 'store/actions';
// tslint:disable-next-line:max-line-length
import {
  AUTHENTICATION as AUTHENTICATION_GETTERS,
  TRAIN as TRAIN_GETTERS,
  CONNECTION as CONNECTION_GETTERS,
} from 'store/getters';
import { sortListBy } from 'service/utils';

export default {
  // add props here that are passed from parent templates or from router,
  // like item uid etc for data fetching.
  // do not mutate these, but use them as initial values for 'data'
  props: [],

  // Data fetching here if necessary
  created() {
    this.$store.dispatch(CONNECTION.LOAD_LIST);
    this.$store.dispatch(LOCATION.LOAD_LIST);
    this.$store.dispatch(COMPANY.LOAD_LIST);
    this.$store.dispatch(TRAIN.LOAD_LIST);
    this.$store.dispatch(TERMINAL.LOAD_LIST);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      orderConnectionsBy: 'name',
      reverseConnections: false,
      orderTrainsBy: 'name',
      reverseTrains: false,
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapState<any>({}),

    ...mapGetters({
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
      connectionList: CONNECTION_GETTERS.FILTERED_LIST,
      trainList: TRAIN_GETTERS.OF_CURRENT_CONNECTION,
      filteredTrains: TRAIN_GETTERS.FILTERED_LIST,
    }),

    isSuperUser() {
      return this.currentUser.isSuperUser || this.currentUser.isContainerZug;
    },

    copyUsableUnitsAvailable() {
      return this.activeTrain && this.trainList && this.trainList.length > 1;
    },

    filteredConnectionItems() {
      const filtered = [...this.connectionList];
      if (this.orderConnectionsBy === 'location') {
        sortListBy(filtered, 'location', 'name', 'operator');
      } else if (this.orderConnectionsBy === 'operator') {
        sortListBy(filtered, 'operator', 'name', 'location');
      } else {
        sortListBy(filtered, this.orderConnectionsBy);
      }
      if (this.reverseConnections) {
        filtered.reverse();
      }
      return filtered;
    },

    filteredTrainItems() {
      const filtered = [...this.filteredTrains] || [];
      if (this.orderTrainsBy === 'stations') {
        sortListBy(filtered, 'stations', 'name');
      } else {
        sortListBy(filtered, this.orderTrainsBy);
      }
      if (this.reverseTrains) {
        filtered.reverse();
      }
      return filtered;
    },

    searchFilterConnections: {
      get() {
        return this.$store.state.connections.searchString;
      },
      set(value) {
        this.$store.dispatch(CONNECTION.FILTER_LIST, value);
      },
    },

    searchFilterTrains: {
      get() {
        return this.$store.state.trains.searchString;
      },

      set(value) {
        this.$store.dispatch(TRAIN.FILTER_LIST, value);
      },
    },

    activeConnection: {
      get() {
        return this.$store.state.connections.current;
      },
      set(item) {
        this.$store.dispatch(CONNECTION.SET_ITEM, { item });
      },
    },

    activeTrain: {
      get() {
        return this.$store.state.trains.current;
      },
      set(item) {
        this.$store.dispatch(TRAIN.SET_ITEM, { item });
      },
    },
  },

  // watch some data. Use with care, prefer computed properties if possible
  // watch : {
  //   someDataKey: 'someMethod',
  //   otherDataKey () {...},
  // },

  // methods that are available in the component or in templates
  methods: {
    orderConnection(orderConnectionsBy) {
      if (this.orderConnectionsBy === orderConnectionsBy) {
        this.reverseConnections = !this.reverseConnections;
      } else {
        this.orderConnectionsBy = orderConnectionsBy;
        this.reverseConnections = false;
      }
    },

    orderTrains(orderTrainsBy) {
      if (this.orderTrainsBy === orderTrainsBy) {
        this.reverseTrains = !this.reverseTrains;
      } else {
        this.orderTrainsBy = orderTrainsBy;
        this.reverseTrains = false;
      }
    },

    toggleConnectionActiveState(connection) {
      if (
        !this.activeConnection ||
        this.activeConnection.uid !== connection.uid
      ) {
        this.activeConnection = connection;
      } else {
        this.activeConnection = null;
        this.trainList = [];
      }
      this.activeTrain = null;
    },

    toggleTrainActiveState(train) {
      if (!this.activeTrain || this.activeTrain.uid !== train.uid) {
        this.activeTrain = train;
      } else {
        this.activeTrain = null;
      }
    },

    deleteConnection() {
      if (this.trainList.length > 0) {
        this.$store.dispatch(UI.SHOW_INFORM_MODAL, {
          message: this.$t(
            'Verbindungen können bei bestehenden Zügen nicht gelöscht werden.',
          ),
          close: this.$t('Ok'),
        });
      } else {
        this.$store
          .dispatch(UI.SHOW_CONFIRM_MODAL, {
            message: this.$t(
              'Wollen Sie die Verbinding "{name}" wirklich löschen?',
              { name: this.activeConnection.name },
            ),
            confirm: this.$t('Ja'),
            close: this.$t('Nein'),
          })
          .then(response => {
            if (response.confirmed) {
              this.$store.dispatch(
                CONNECTION.REMOVE_ITEM,
                this.activeConnection,
              );
            }
          });
      }
    },

    copyUsableUnits() {
      this.$store
        .dispatch(UI.SHOW_CONFIRM_MODAL, {
          message: this.$t(
            // tslint:disable-next-line:max-line-length
            'Sollen die Ladeformen des ausgewählten Zuges "{trainName}" auf alle Züge der Verbindung "{connectionName}" übertragen werden? Vorhandene Ladeformen der anderen Züge werden überschrieben.',
            {
              connectionName: this.activeConnection.name,
              trainName: this.activeTrain.name,
            },
          ),
          confirm: this.$t('Ja'),
          close: this.$t('Nein'),
        })
        .then(response => {
          if (response.confirmed) {
            this.$store.dispatch(TRAIN.COPY_USABLE_UNITS);
          }
        });
    },

    deleteTrain() {
      this.$store
        .dispatch(UI.SHOW_CONFIRM_MODAL, {
          message: this.$t('Wollen Sie den Zug "{name}" wirklich löschen?', {
            name: this.activeTrain.name,
          }),
          confirm: this.$t('Ja'),
          close: this.$t('Nein'),
        })
        .then(response => {
          if (response.confirmed) {
            this.$store.dispatch(TRAIN.REMOVE_ITEM, this.activeTrain);
          }
        });
    },
  },
};
