import { TERMINAL as ACTIONS, API, CONVEYANCE } from 'store/actions';
import { TERMINAL as MUTATIONS } from 'store/mutations';
// tslint:disable-next-line:max-line-length
import {
  TERMINAL as GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
  COMPANY as COMPANY_GETTERS,
  LOCATION as LOCATION_GETTERS,
  COUNTRY as COUNTRY_GETTERS,
} from 'store/getters';
import { listToIdMap, defaultValueOnHttpError } from 'service/utils';
import {
  defaultItemActions,
  defaultItemState,
  defaultItemMutations,
} from 'store/commons/item-list';
import {
  defaultSearchableState,
  defaultSearchableActions,
  defaultSearchableMutations,
} from 'store/commons/searchable';
import isEqual from 'lodash/isEqual';
import { freezeToStopVueReactivity } from 'service/vue-helpers';
import {
  Terminal,
  Location,
  Country,
  defaultGeoLocation,
} from 'kv_shared/lib/data-types';

const state = {
  ...defaultItemState(),
  ...defaultSearchableState(),
};

const getters = {
  [GETTERS.BY_ID]: state => listToIdMap(state.list),

  [GETTERS.OF_CURRENT_USER]: (state, getters) => {
    const user = getters[AUTHENTICATION_GETTERS.CURRENT_USER];
    if (user.isSuperUser) {
      return state.list;
    } else {
      const companies = getters[COMPANY_GETTERS.OF_CURRENT_USER];
      return state.list.filter(terminal =>
        companies.find(company => company.uid === terminal.company.uid),
      );
    }
  },

  [GETTERS.BY_COUNTRY](state, getters, rootState) {
    const locations = getters[LOCATION_GETTERS.BY_ID];
    const countries = getters[COUNTRY_GETTERS.BY_ID];

    const terminals: { [id: string]: any[] } = {};
    for (const t of state.list as Terminal[]) {
      const location: Location =
        locations[t.locality && (t.locality.uid as string)];
      const country: Country =
        countries[
          location && location.country && (location.country.uid as string)
        ];

      if (country) {
        const loc = { ...defaultGeoLocation };

        if (t.loc && (typeof t.loc.lat === 'number' || t.loc.lat)) {
          loc.lat = parseFloat(t.loc.lat);
        } else if (
          location.loc &&
          (typeof location.loc.lat === 'number' || location.loc.lat)
        ) {
          loc.lat = parseFloat(location.loc.lat);
        }
        if (t.loc && (typeof t.loc.lng === 'number' || t.loc.lng)) {
          loc.lng = parseFloat(t.loc.lng);
        } else if (
          location.loc &&
          (typeof location.loc.lng === 'number' || location.loc.lng)
        ) {
          loc.lng = parseFloat(location.loc.lng);
        }

        if (!terminals[country.uid as string]) {
          terminals[country.uid as string] = [{ ...t, loc }];
        } else {
          terminals[country.uid as string].push({ ...t, loc });
        }
      }
    }
    return freezeToStopVueReactivity(terminals);
  },

  [GETTERS.FILTERED_LIST]: (state, getters) => {
    const filtered: any[] = [];

    for (const terminal of getters[GETTERS.OF_CURRENT_USER]) {
      const terminalName = terminal.name ? terminal.name.toLowerCase() : '';
      const company =
        getters[COMPANY_GETTERS.BY_ID][
          terminal.company && terminal.company.uid
        ];
      const companyName = company ? company.name : '';
      const country =
        terminal.address && terminal.address.country
          ? terminal.address.country.toLowerCase()
          : '';

      if (
        terminalName.indexOf(state.searchString) > -1 ||
        country.indexOf(state.searchString) > -1 ||
        companyName.toLowerCase().indexOf(state.searchString) > -1
      ) {
        filtered.push(
          freezeToStopVueReactivity({
            ...terminal,
            companyName,
          }),
        );
      }
    }

    return filtered;
  },
};

const actions = {
  ...defaultItemActions(ACTIONS, MUTATIONS),
  ...defaultSearchableActions(ACTIONS, MUTATIONS),

  // Override and recalculate conveyance areas after new terminal
  [ACTIONS.SAVE_ITEM]({ commit, dispatch, state }, item) {
    if (!item.uid) {
      return dispatch(ACTIONS.API_CREATE, item).then(result => {
        if (result.uid) {
          item.uid = result.uid;
        }
        dispatch(ACTIONS.SET_ITEM, { item, updateList: true });
        dispatch(CONVEYANCE.RECALCULATE_ALL);
      });
    } else {
      return dispatch(ACTIONS.API_UPDATE, item).then(() =>
        dispatch(ACTIONS.SET_ITEM, { item, updateList: true }),
      );
    }
  },

  // Override and recalculate conveyance areas after deleted terminal
  [ACTIONS.REMOVE_ITEM]({ commit, dispatch, state }, item) {
    dispatch(ACTIONS.API_DELETE_BY_ID, item.uid)
      .then(result => {
        console.log('item deleted!', item, result);
      })
      .then(() => {
        commit(MUTATIONS.REMOVE_FROM_LIST, [item]);
        dispatch(CONVEYANCE.RECALCULATE_ALL);
      });
  },

  [ACTIONS.ADD_DOCUMENT]({ commit, dispatch }, { terminal, file }) {
    return dispatch(ACTIONS.API_UPLOAD_DOCUMENT, {
      uid: terminal.uid,
      file,
    }).then(newDocument => {
      if (newDocument) {
        const index = terminal.documents.findIndex(d => isEqual(d, file));
        terminal.documents.splice(
          index,
          1,
          Object.assign({ label: '' }, file, newDocument),
        );
      }
    });
  },

  [ACTIONS.REMOVE_DOCUMENT]({ commit, dispatch }, { terminal, document }) {
    return dispatch(ACTIONS.API_DELETE_DOCUMENT, {
      uid: terminal.uid,
      fileUid: document.uid,
    });
  },

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, 'place/terminal')
      .then(response => {
        return response.placeList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, 'place/terminal/' + uid).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_UPDATE]({ dispatch }, terminal) {
    return dispatch(API.PUT, {
      path: 'place/terminal/' + terminal.uid,
      body: terminal,
    });
  },

  [ACTIONS.API_CREATE]({ dispatch }, newItem) {
    return dispatch(API.POST, { path: 'place/terminal/create' }).then(
      response => {
        return dispatch(API.PUT, {
          path: 'place/terminal/' + response.uid + '/init',
          body: newItem,
        }).then(() => response);
      },
    );
  },

  [ACTIONS.API_DELETE_BY_ID]({ dispatch }, uid) {
    return dispatch(API.DELETE, 'place/terminal/' + uid);
  },

  [ACTIONS.API_UPLOAD_DOCUMENT]({ dispatch }, { uid, file }) {
    return dispatch(API.UPLOAD, {
      path: 'place/terminal/' + uid + '/file/upload',
      file,
    }).then(response => {
      if (response.uploadedFiles && response.uploadedFiles.length) {
        return response.uploadedFiles[0];
      }
    });
  },

  [ACTIONS.API_DELETE_DOCUMENT]({ dispatch }, { uid, fileUid }) {
    return dispatch(API.DELETE, 'place/terminal/' + uid + '/file/' + fileUid);
  },
};

const mutations = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSearchableMutations(MUTATIONS),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
