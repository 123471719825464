import { mapGetters } from 'vuex';
import { COMPANY, LOCATION, LOADING_POINT, UI } from 'store/actions';
import { sortListBy } from 'service/utils';
import {
  AUTHENTICATION as AUTHENTICATION_GETTERS,
  LOADING_POINT as LOADING_POINT_GETTERS,
} from 'store/getters';

// TODO: Optimizations
// * show max 100 item, with options to load more, or infinite scroll (harder to implement)
// * maybe pagination (persisted in store)
// * Refactor item table view into an own component

export default {
  created() {
    this.$store.dispatch(COMPANY.LOAD_LIST);
    this.$store.dispatch(LOCATION.LOAD_LIST);
    this.$store.dispatch(LOADING_POINT.LOAD_COMPANY_TYPES);
    this.$store.dispatch(LOADING_POINT.LOAD_LIST);
  },

  data() {
    return {
      orderBy: 'name',
      reverse: false,
      maxItems: 100,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
      loadingPoints: LOADING_POINT_GETTERS.FILTERED_LIST,
    }),

    emptyStore() {
      return this.$store.state.loadingPoints.list.length === 0;
    },

    filteredItems() {
      const filtered = [...this.loadingPoints];
      if (this.orderBy === 'typeName') {
        sortListBy(filtered, 'typeName', 'name');
      } else {
        sortListBy(filtered, this.orderBy);
      }
      if (this.reverse) {
        filtered.reverse();
      }
      if (filtered.length > this.maxItems) {
        return filtered.slice(0, this.maxItems);
      }
      return filtered;
    },

    editLoadingPointLabel() {
      return this.$t('Ladestelle bearbeiten');
    },

    deleteLoadingPointLabel() {
      return this.$t('Ladestelle löschen');
    },

    searchFilter: {
      get() {
        return this.$store.state.loadingPoints.searchString;
      },
      set(value) {
        this.$store.dispatch(LOADING_POINT.FILTER_LIST, value);
      },
    },
  },

  methods: {
    order(orderBy) {
      if (this.orderBy === orderBy) {
        this.reverse = !this.reverse;
      } else {
        this.orderBy = orderBy;
        this.reverse = false;
      }
    },

    loadAll() {
      this.maxItems = Math.max(this.loadingPoints.length, this.maxItems);
    },

    deleteLoadingPoint(loadingPoint) {
      this.$store
        .dispatch(UI.SHOW_CONFIRM_MODAL, {
          message: this.$t(
            'Wollen Sie die Ladestelle {loadingPointName} wirklich löschen?',
            { loadingPointName: loadingPoint.name },
          ),
          confirm: this.$t('Ja'),
          close: this.$t('Nein'),
        })
        .then(response => {
          if (response.confirmed) {
            this.$store.dispatch(LOADING_POINT.REMOVE_ITEM, loadingPoint);
          }
        });
    },
  },
};
