import i18n from 'kv_shared/lib/vue/i18n';
import {
  UserRole,
  CompanyType,
  UserHackRole,
  CompanyHackType,
} from 'kv_shared/lib/data-types';

export const LOCAL_SESSION_KEY = 'kv_editor_session';

export const USER_ROLE_OBJECT = {
  ADMIN: {
    name: i18n.t('Admin'),
    type: UserRole.ADMIN,
  },
  CHIEF_EDITOR: {
    name: i18n.t('Chefredakteur'),
    type: UserRole.CHIEF_EDITOR,
  },
  SUB_CHIEF_EDITOR: {
    name: i18n.t('Verbandsredakteur'),
    type: UserRole.SUB_CHIEF_EDITOR,
  },
  OPERATOR: {
    name: i18n.t('Operator'),
    type: UserRole.OPERATOR,
  },
  TERMINAL_CARRIER: {
    name: i18n.t('Terminalbetreiber'),
    type: UserRole.TERMINAL_CARRIER,
  },
  LOADING_POINT_CARRIER: {
    name: i18n.t('Ladestellenbetreiber'),
    type: UserRole.LOADING_POINT_CARRIER,
  },
  SERVICE_STATION_CARRIER: {
    name: i18n.t('Tankstellenbetreiber'),
    type: UserRole.SERVICE_STATION_CARRIER,
  },
  FORWARDER: {
    name: i18n.t('Spediteur'),
    type: UserRole.FORWARDER,
  },
  WAGON_LOAD: {
    name: i18n.t('Einzelwagenverkehr'),
    type: UserRole.WAGON_LOAD,
  },
  BOOKING_PROVIDER: {
    name: i18n.t('Buchungsanbieter'),
    type: UserHackRole.BOOKING_PROVIDER,
  },
};

export const USER_SPECIAL_ROLE = {
  CONTAINERZUG: 'containerzug',
};

export const USER_ADMIN_EMAIL = 'rh@hornnissen.de';
export const USER_CHIEF_EDITOR_EMAIL = 'maik.staehr@deutschebahn.com';

export const COMPANY_TYPE_OBJECT = {
  OPERATOR: {
    name: i18n.t('Operator'),
    type: CompanyType.OPERATOR,
  },
  TERMINAL_CARRIER: {
    name: i18n.t('Terminalbetreiber'),
    type: CompanyType.TERMINAL_CARRIER,
  },
  LOADING_POINT_CARRIER: {
    name: i18n.t('Ladestellenbetreiber'),
    type: CompanyType.LOADING_POINT_CARRIER,
  },
  SERVICE_STATION_CARRIER: {
    name: i18n.t('Tankstellenbetreiber'),
    type: CompanyType.SERVICE_STATION_CARRIER,
  },
  FORWARDER: {
    name: i18n.t('Spedition'),
    type: CompanyType.FORWARDER,
  },
  WAGON_LOAD: {
    name: i18n.t('Einzelwagenverkehr'),
    type: CompanyType.WAGON_LOAD,
  },
  BOOKING_PROVIDER: {
    name: i18n.t('Buchungsanbieter'),
    type: CompanyHackType.BOOKING_PROVIDER,
  },
};

export const LOADING_POINT_COMPANY_TYPE = {
  DB: 'db',
  NON_DB: 'nondb',
  BLS: 'bls',
  OEBB: 'oebb',
  SBB: 'sbb',
  PKP: 'pkp',
  NON_PKP: 'nonpkp',
  DSB: 'dsb',
  RAILWAY_SIDING: 'siding',
};

export const TIME_REGEX = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
export const PRICE_REGEX = /^\d+[\.|\,]?\d{0,2}$/;
