import Vue from 'vue';
import {
  ORIGINAL_LANGUAGE,
  getTranslation,
  cachedTranslations,
} from '../utils/localization';

function translate(
  language: string,
  key: string,
  substitutions?: { [id: string]: string },
) {
  const str = getTranslation(cachedTranslations[key], language, key);

  if (!substitutions) {
    return str;
  }

  return str.replace(/\{\s*\w+\s*\}/g, placeholder => {
    const key = placeholder
      .replace('{', '')
      .replace('}', '')
      .trim();

    if (substitutions[key] !== undefined) {
      return substitutions[key];
    }

    // return the original placeholder
    return placeholder;
  });
}

const i18n = {
  locale: ORIGINAL_LANGUAGE,
  t(key: string, substitutions?: { [id: string]: string }) {
    return translate(i18n.locale, key, substitutions);
  },
};

export default i18n;

Vue.use({
  install(Vue: any) {
    Vue.prototype.$t = i18n.t;

    Vue.mixin({
      data() {
        return {
          $i18n: i18n,
        };
      },
    });
  },
});
