import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import App from 'components/App.vue';
import router from 'config/router';
import store from 'store';
import Multiselect from 'vue-multiselect';
import vDatepicker from 'vuejs-datepicker';
import vDraggable from 'vuedraggable'; // https://github.com/SortableJS/Vue.Draggable
import MaskedInput from 'vue-masked-input';
import 'kv_shared/lib/vue/i18n';
import 'config/forms';

sync(store, router);

Vue.component('multiselect', Multiselect);
Vue.component('v-datepicker', vDatepicker);
Vue.component('v-draggable', vDraggable);
Vue.component('masked-input', MaskedInput);

const app = new Vue({
  router,
  store,
  ...App,
} as any);

export { app, router, store };
