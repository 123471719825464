import { mapGetters, mapState } from 'vuex';
import { GLOBAL, UI } from 'store/actions';
import appMenu from './Menu.vue';
import { AUTHENTICATION } from 'store/getters';

export default {
  components: {
    appMenu,
  },

  computed: {
    ...mapState<any>({
      currentLanguage: state => state.locale,
      availableLanguages: state => state.availableLanguages,
      isMenuOpen: state => state.ui.openMenu,
    }),

    ...mapGetters({
      currentUser: AUTHENTICATION.CURRENT_USER,
    }),
  },

  methods: {
    toggleOpenState() {
      this.$store.dispatch(UI.TOGGLE_MENU);
    },

    setLanguage(lang) {
      this.$store.dispatch(GLOBAL.CHANGE_LANGUAGE, lang);
    },
  },
};
