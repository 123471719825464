import { mapGetters } from 'vuex';
import { COMPANY, LOCATION, WAGON_LOAD, UI } from 'store/actions';
import {
  WAGON_LOAD as WAGON_LOAD_GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
} from 'store/getters';
import { sortListBy } from 'service/utils';

export default {
  // Data fetching
  created() {
    this.$store.dispatch(COMPANY.LOAD_LIST);
    this.$store.dispatch(LOCATION.LOAD_LIST);
    this.$store.dispatch(WAGON_LOAD.LOAD_LIST);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      orderBy: 'name',
      reverse: false,
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapGetters({
      wagonLoads: WAGON_LOAD_GETTERS.FILTERED_LIST,
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
    }),

    filteredItems() {
      const ordered = [...this.wagonLoads];
      sortListBy(ordered, this.orderBy);
      if (this.reverse) {
        ordered.reverse();
      }
      return ordered;
    },

    searchFilter: {
      get() {
        return this.$store.state.wagonLoad.searchString;
      },
      set(value) {
        this.$store.dispatch(WAGON_LOAD.FILTER_LIST, value);
      },
    },

    isSuperUser() {
      return this.currentUser.isSuperUser;
    },
  },

  // methods that are available in the component or in templates
  methods: {
    order(orderBy) {
      if (this.orderBy === orderBy) {
        this.reverse = !this.reverse;
      } else {
        this.orderBy = orderBy;
        this.reverse = false;
      }
    },

    deleteWagonLoad(wagonLoad) {
      this.$store
        .dispatch(UI.SHOW_CONFIRM_MODAL, {
          message: this.$t(
            'Wollen Sie den Einzelwagenverkehr {name} wirklich löschen?',
            { name: wagonLoad.name },
          ),
          confirm: this.$t('Ja'),
          close: this.$t('Nein'),
        })
        .then(response => {
          if (response.confirmed) {
            this.$store.dispatch(WAGON_LOAD.REMOVE_ITEM, wagonLoad);
          }
        });
    },
  },
};
