import { mapGetters, mapState } from 'vuex';
import { COMPANY } from 'store/actions';
import { AUTHENTICATION as AUTHENTICATION_GETTERS } from 'store/getters';
import { CompanyType } from 'kv_shared/lib/data-types';

export default {
  // Data fetching
  created() {
    this.fetchCompanyConfigs();
  },

  computed: {
    ...mapGetters({
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
    }),

    ...mapState<any>({
      companyConfigurations: state => state.companies.configurations,
    }),

    userTexts() {
      const texts = {
        role: '',
        welcome: '',
      };

      let config;

      if (this.currentUser.isAdmin) {
        texts.role = this.$t('Admin');
        config = this.companyConfigurations[CompanyType.OPERATOR];
      } else if (this.currentUser.isChiefEditor) {
        texts.role = this.$t('Chefredakteur');
        config = this.companyConfigurations[CompanyType.OPERATOR];
      } else if (this.currentUser.isOperator) {
        texts.role = this.$t('Operator');
        config = this.companyConfigurations[CompanyType.OPERATOR];
      } else if (this.currentUser.isTerminalCarrier) {
        texts.role = this.$t('Terminalbetreiber');
        config = this.companyConfigurations[CompanyType.TERMINAL_CARRIER];
      } else if (this.currentUser.isLoadingPointCarrier) {
        texts.role = this.$t('Ladestellenbetreiber');
        config = this.companyConfigurations[CompanyType.LOADING_POINT_CARRIER];
      } else if (this.currentUser.isServiceStationCarrier) {
        texts.role = this.$t('Tankstellenbetreiber');
        config = this.companyConfigurations[
          CompanyType.SERVICE_STATION_CARRIER
        ];
      } else if (this.currentUser.isForwarder) {
        texts.role = this.$t('Spediteur');
        config = this.companyConfigurations[CompanyType.FORWARDER];
      }

      if (config && config.welcomeScreenText) {
        texts.welcome = config.welcomeScreenText;
      }

      return texts;
    },
  },

  methods: {
    fetchCompanyConfigs() {
      // Operator is default config
      this.$store.dispatch(COMPANY.GET_CONFIG_BY_TYPE, CompanyType.OPERATOR);

      // Fetch other roles
      this.currentUser &&
        this.currentUser.userRole.forEach(role => {
          const types = Object.values(CompanyType);
          if (types.includes(role)) {
            this.$store.dispatch(COMPANY.GET_CONFIG_BY_TYPE, role);
          }
        });
    },
  },
};
