import { mapState } from 'vuex';
import isEqual from 'lodash/isEqual';
import Vue from 'vue';
import { Entity } from 'kv_shared/lib/data-types';
import { merge } from 'service/utils';

type EntityConstructor = new () => Entity;

export function editComponent(DefaultData: EntityConstructor, stateFunction) {
  return {
    props: ['uid'],

    data() {
      return {
        itemData: new DefaultData(),
        submitted: false,
      };
    },

    // computed properties. Add vuex store data here
    computed: {
      ...mapState({
        currentStoreData: stateFunction,
      }),

      isClean() {
        if (this.currentStoreData) {
          const initial = merge(new DefaultData(), this.currentStoreData);
          return isEqual(this.itemData, initial);
        } else {
          return isEqual(this.itemData, new DefaultData());
        }
      },
    },

    watch: {
      currentStoreData: 'resetData',
    },

    methods: {
      resetData() {
        if (this.currentStoreData) {
          this.itemData = merge(new DefaultData(), this.currentStoreData);
        } else {
          this.itemData = new DefaultData();
        }

        this.submitted = false;

        this.resetDataCallback();

        Vue.nextTick(() => {
          this.$errors.clear();
          for (const field in this.$fields) {
            this.$validator.flag(field, {
              dirty: false,
              invalid: false,
              pristine: true,
              touched: false,
            });
          }
        });
      },

      resetDataCallback() {
        // override in child components
      },

      checkError(field) {
        return (
          !this.isClean &&
          this.submitted &&
          (field ? this.$errors.has(field) : this.$errors.any())
        );
      },

      submit() {
        this.submitted = true;
        return this.$validator
          .validateAll()
          .then(valid => {
            if (!valid) {
              console.log('form errors');
              return;
            }

            return this.submitCallback();
          })
          .catch(e => {
            console.log('form errors', e);
          });
      },

      submitCallback() {
        // Override this function with custom save behavoir!
      },
    },
  };
}
