import { LOADING_POINT as ACTIONS, API } from 'store/actions';
import { LOADING_POINT as MUTATIONS } from 'store/mutations';
// tslint:disable-next-line:max-line-length
import {
  LOADING_POINT as GETTERS,
  LOCATION as LOCATION_GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
  COMPANY as COMPANY_GETTERS,
} from 'store/getters';
import { LOADING_POINT_COMPANY_TYPE } from 'service/constants';
import { listToIdMap, defaultValueOnHttpError } from 'service/utils';
import i18n from 'kv_shared/lib/vue/i18n';
import {
  defaultItemState,
  defaultItemActions,
  defaultItemMutations,
} from 'store/commons/item-list';
import {
  defaultSearchableActions,
  defaultSearchableMutations,
  defaultSearchableState,
} from 'store/commons/searchable';
import { freezeToStopVueReactivity } from 'service/vue-helpers';
import isEqual from 'lodash/isEqual';

const state = {
  ...defaultItemState(),
  ...defaultSearchableState(),
  companyTypes: [],
};

const getters = {
  [GETTERS.OF_CURRENT_USER]: (state, getters) => {
    const user = getters[AUTHENTICATION_GETTERS.CURRENT_USER];
    if (user.isSuperUser) {
      return state.list;
    } else {
      const companies = getters[COMPANY_GETTERS.OF_CURRENT_USER];
      return state.list.filter(loadingPoint =>
        companies.find(company => company.uid === loadingPoint.company.uid),
      );
    }
  },

  [GETTERS.COMPANY_TYPES_BY_ID]: state => listToIdMap(state.companyTypes, 'id'),

  [GETTERS.COMPANY_TYPE_LABELS]: (state, getters, rootState) => {
    const lpLocale = i18n.t('Ladestelle');
    const labels = { default: lpLocale };

    for (const type of state.companyTypes) {
      labels[type.id] = i18n.t(type.label);
    }

    return labels;
  },

  [GETTERS.FILTERED_LIST]: (state, getters, rootState) => {
    const filtered: any[] = [];
    const placeMissing = i18n.t('(kein Ort angegeben)');

    for (const lp of getters[GETTERS.OF_CURRENT_USER]) {
      const lpName = lp.name ? lp.name.toLowerCase() : '';
      const location = getters[LOCATION_GETTERS.BY_ID][lp.locality.uid];

      const locationName = location
        ? getters[LOCATION_GETTERS.LOCALE_NAMES][location.uid]
        : placeMissing;

      if (
        lpName.indexOf(state.searchString) > -1 ||
        locationName.toLowerCase().indexOf(state.searchString) > -1
      ) {
        const company =
          getters[COMPANY_GETTERS.BY_ID][lp.company && lp.company.uid];
        const type =
          getters[GETTERS.COMPANY_TYPES_BY_ID][
            company && company.loadingPointCompanyType
          ];
        const typeName =
          getters[GETTERS.COMPANY_TYPE_LABELS][(type && type.id) || 'default'];

        filtered.push(
          freezeToStopVueReactivity({
            ...lp,
            typeName,
            locationName,
          }),
        );
      }
    }

    return filtered;
  },
};

const actions = {
  ...defaultItemActions(ACTIONS, MUTATIONS),
  ...defaultSearchableActions(ACTIONS, MUTATIONS),

  [ACTIONS.LOAD_COMPANY_TYPES](
    { commit, dispatch, state },
    forceReload = false,
  ) {
    if (!forceReload && state.companyTypes && state.companyTypes.length) {
      return;
    }
    return dispatch(ACTIONS.API_GET_COMPANY_TYPES).then(list =>
      commit(MUTATIONS.REPLACE_COMPANY_TYPES, list),
    );
  },

  [ACTIONS.ADD_DOCUMENT]({ commit, dispatch }, { loadingPoint, file }) {
    return dispatch(ACTIONS.API_UPLOAD_DOCUMENT, {
      uid: loadingPoint.uid,
      file,
    }).then(newDocument => {
      if (newDocument) {
        const index = loadingPoint.documents.findIndex(d => isEqual(d, file));
        loadingPoint.documents.splice(
          index,
          1,
          Object.assign({ label: '' }, file, newDocument),
        );
      }
    });
  },

  [ACTIONS.REMOVE_DOCUMENT]({ commit, dispatch }, { loadingPoint, document }) {
    return dispatch(ACTIONS.API_DELETE_DOCUMENT, {
      uid: loadingPoint.uid,
      fileUid: document.uid,
    });
  },

  [ACTIONS.SAVE_COMPANY_TYPES]({ commit, dispatch }, types) {
    if (types && types.length) {
      return dispatch(ACTIONS.API_UPDATE_COMPANY_TYPES, types);
    }
  },

  // API ACTIONS

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, 'place/loadingPoint')
      .then(response => {
        return response.placeList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, 'place/loadingPoint/' + uid).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_UPDATE]({ dispatch }, loadingPoint) {
    return dispatch(API.PUT, {
      path: 'place/loadingPoint/' + loadingPoint.uid,
      body: loadingPoint,
    });
  },

  [ACTIONS.API_CREATE]({ dispatch }, newItem) {
    return dispatch(API.POST, { path: 'place/loadingPoint/create' }).then(
      response => {
        return dispatch(API.PUT, {
          path: 'place/loadingPoint/' + response.uid + '/init',
          body: newItem,
        }).then(() => response);
      },
    );
  },

  [ACTIONS.API_DELETE_BY_ID]({ dispatch }, uid) {
    return dispatch(API.DELETE, 'place/loadingPoint/' + uid);
  },

  [ACTIONS.API_UPLOAD_DOCUMENT]({ dispatch }, { uid, file }) {
    return dispatch(API.UPLOAD, {
      path: 'place/loadingPoint/' + uid + '/file/upload',
      file,
    })
      .then(response => {
        if (response.uploadedFiles && response.uploadedFiles.length) {
          return response.uploadedFiles[0];
        }
      })
      .catch(defaultValueOnHttpError(null));
  },

  [ACTIONS.API_DELETE_DOCUMENT]({ dispatch }, { uid, fileUid }) {
    return dispatch(
      API.DELETE,
      'place/loadingPoint/' + uid + '/file/' + fileUid,
    );
  },

  [ACTIONS.API_GET_COMPANY_TYPES]({ dispatch }) {
    return dispatch(API.GET, 'misc/singleton/loadingPointCompanyTypes')
      .then(response => {
        if (!(response.typeList && response.typeList.length >= 1)) {
          response.typeList = defaultCompanyTypes;
        }
        return response.typeList;
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_UPDATE_COMPANY_TYPES]({ dispatch, commit }, types) {
    return dispatch(API.PUT, {
      path: 'misc/singleton/loadingPointCompanyTypes',
      body: { typeList: types },
    }).then(() => commit(MUTATIONS.REPLACE_COMPANY_TYPES, types));
  },
};

const mutations = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSearchableMutations(MUTATIONS),

  [MUTATIONS.REPLACE_COMPANY_TYPES](state, newTypes) {
    state.companyTypes = newTypes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

const defaultCompanyTypes = [
  {
    id: LOADING_POINT_COMPANY_TYPE.RAILWAY_SIDING,
    label: 'Gleisanschluss',
    color: 'tomato',
    system: true,
  },
  {
    id: LOADING_POINT_COMPANY_TYPE.DB,
    label: 'DB',
    color: '#CC0000',
  },
  {
    id: LOADING_POINT_COMPANY_TYPE.NON_DB,
    label: 'Non-DB',
    color: '#333333',
  },
  {
    id: LOADING_POINT_COMPANY_TYPE.BLS,
    label: 'BLS',
    color: '#2E2EE5',
  },
  {
    id: LOADING_POINT_COMPANY_TYPE.OEBB,
    label: 'ÖBB',
    color: '#7B0E07',
  },
  {
    id: LOADING_POINT_COMPANY_TYPE.SBB,
    label: 'SBB',
    color: '#EF7F2B',
  },
  {
    id: LOADING_POINT_COMPANY_TYPE.PKP,
    label: 'PKP',
    color: '#1A3D70',
  },
  {
    id: LOADING_POINT_COMPANY_TYPE.NON_PKP,
    label: 'Non-PKP',
    color: '#1A3D70',
  },
  {
    id: LOADING_POINT_COMPANY_TYPE.DSB,
    label: 'DSB',
    color: '#B41730',
  },
];
