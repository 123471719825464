import { mapGetters } from 'vuex';
import { AUTHENTICATION, UI } from 'store/actions';
import { AUTHENTICATION as AUTHENTICATION_GETTERS } from 'store/getters';

export default {
  computed: {
    ...mapGetters({
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
    }),

    entries() {
      const user = this.currentUser;
      if (!user.isLoggedIn) {
        return [];
      }

      const entries = {
        userManagement: {
          label: this.$t('Nutzerverwaltung'),
          route: { name: 'user' },
        },
        locations: {
          label: this.$t('Orte'),
          route: { name: 'location' },
        },
        companies: {
          label: this.$t('Unternehmen'),
          route: { name: 'company' },
        },
        terminals: {
          label: this.$t('Terminals'),
          route: { name: 'terminal' },
        },
        trains: {
          label: this.$t('Züge'),
          route: { name: 'operator' },
        },
        loadingPoints: {
          label: this.$t('Ladestellen'),
          route: { name: 'loadingPoint' },
        },
        forwarder: {
          label: this.$t('Speditionen'),
          route: { name: 'forwarder' },
        },
        wagonLoad: {
          label: this.$t('Einzelwagenverkehr'),
          route: { name: 'wagonLoad' },
        },
        news: {
          label: this.$t('News'),
          route: { name: 'news' },
        },
        profile: {
          label: this.$t('Persönliche Daten'),
          route: {
            name: 'profile',
            params: { uid: '' },
          },
        },
        imprint: {
          label: this.$t('Impressum'),
          route: { name: 'imprint' },
        },
      };

      const es: any[] = [];

      if (user.isAdmin || user.isChiefEditor) {
        es.push(
          entries.userManagement,
          entries.locations,
          entries.companies,
          entries.terminals,
          entries.trains,
          entries.loadingPoints,
          entries.forwarder,
          entries.wagonLoad,
          entries.news,
          entries.imprint,
        );
      } else {
        if (user.isSubChiefEditor) {
          es.push(entries.userManagement);
          es.push(entries.companies);
        } else if (user.company && user.company.length === 1) {
          es.push({
            label: this.$t('Unternehmen'),
            route: {
              name: 'company.edit',
              params: {
                uid: user.company[0].uid,
              },
            },
          });
        } else {
          es.push(entries.companies);
        }

        if (user.isTerminalCarrier) {
          es.push(entries.terminals);
        }

        if (user.isOperator) {
          es.push(entries.trains);
        }

        if (user.isLoadingPointCarrier) {
          es.push(entries.loadingPoints);
        }

        if (user.isWagonLoad && !user.isBookingProvider) {
          es.push(entries.wagonLoad);
        }

        if (user.isForwarder) {
          if (user.company && user.company.length === 1) {
            es.push({
              label: this.$t('Spedition'),
              route: {
                name: 'forwarder.edit',
                params: {
                  uid: user.company[0].uid,
                },
              },
            });
          } else {
            es.push(entries.forwarder);
          }
        }
      }

      entries.profile.route.params = { uid: this.currentUser.uid };

      es.push(entries.profile);

      return es;
    },
  },

  methods: {
    logout() {
      this.$store
        .dispatch(UI.SHOW_CONFIRM_MODAL, {
          message: this.$t('Wollen Sie das Redaktionstool verlassen?'),
          confirm: this.$t('Ja'),
          close: this.$t('Nein'),
        })
        .then(response => {
          if (response.confirmed) {
            this.$store.dispatch(AUTHENTICATION.LOGOUT);
          }
        });
    },

    truncateLabel(label) {
      return label.indexOf(' ') >= 0;
    },
  },
};
