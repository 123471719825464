export default {
  computed: {
    breadcrumbs() {
      const crumbs: any = [];
      findCrumbs(this.$route, crumbs, this.$router.options.routes);
      return crumbs;
    },
  },
};

// private helpers

function findCrumbs(current, crumbs, all) {
  if (current && current.meta && current.meta.breadcrumb) {
    crumbs.unshift(current);
    if (current.meta.breadcrumb.parent) {
      findCrumbs(
        all.find(route => route.name === current.meta.breadcrumb.parent),
        crumbs,
        all,
      );
    }
  }
}
