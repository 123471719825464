import Vue from 'vue';
import Router from 'vue-router';
import Login from 'components/authentication/Login.vue';
import LostPassword from 'components/authentication/LostPassword.vue';
import SetPassword from 'components/authentication/SetPassword.vue';
import Welcome from 'components/Welcome.vue';
import WelcomeEdit from 'components/user/WelcomeEdit.vue';
import UserManagement from 'components/user/UserManagement.vue';
import User from 'components/user/User.vue';
import LocationList from 'components/location/LocationList.vue';
import Location from 'components/location/Location.vue';
import CompanyList from 'components/company/CompanyList.vue';
import Company from 'components/company/Company.vue';
import TerminalList from 'components/terminal/TerminalList.vue';
import TerminalCreate from 'components/terminal/TerminalCreate.vue';
import TerminalBase from 'components/terminal/TerminalBase.vue';
import TerminalCompany from 'components/terminal/TerminalCompany.vue';
import TerminalContact from 'components/terminal/TerminalContact.vue';
import TerminalFeatures from 'components/terminal/TerminalFeatures.vue';
import TerminalDocuments from 'components/terminal/TerminalDocuments.vue';
import Operator from 'components/operator/Operator.vue';
import Connection from 'components/operator/Connection.vue';
import Train from 'components/operator/Train.vue';
import TrainMultiCopy from 'components/operator/TrainMultiCopy.vue';
import WagonLoadList from 'components/wagonload/WagonLoadList.vue';
import WagonLoad from 'components/wagonload/WagonLoad.vue';
import ForwarderList from 'components/forwarder/ForwarderList.vue';
import Forwarder from 'components/forwarder/Forwarder.vue';
import ForwarderArea from 'components/forwarder/ForwarderArea.vue';
import LoadingPointList from 'components/loadingpoint/LoadingPointList.vue';
import LoadingPoint from 'components/loadingpoint/LoadingPoint.vue';
import SidingFeatures from 'components/loadingpoint/SidingFeatures.vue';
import LoadingPointCompanyTypes from 'components/loadingpoint/LoadingPointCompanyTypes.vue';
import NewsList from 'components/news/NewsList.vue';
import News from 'components/news/News.vue';
import Imprint from 'components/Imprint.vue';
import CountryCenters from 'components/country/CountryCenters.vue';
import store from 'store';
import i18n from 'kv_shared/lib/vue/i18n';
import { UI } from 'store/actions';
import { AUTHENTICATION as AUTHENTICATION_GETTERS } from 'store/getters';

Vue.use(Router as any);

const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: '/welcome',
    },

    {
      path: '/login',
      name: 'login',
      component: Login,
    },

    {
      path: '/lostpassword',
      name: 'lostpassword',
      component: LostPassword,
    },

    {
      path: '/setpassword/:uid?/:email?',
      name: 'user.setpassword',
      component: SetPassword,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Passwort bearbeiten'),
        },
      },
    },

    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
      meta: {
        breadcrumb: {
          label: i18n.t('Willkommen'),
        },
      },
    },

    {
      path: '/welcome/edit/:type',
      name: 'welcome.edit',
      component: WelcomeEdit,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Willkommenstext bearbeiten'),
          parent: 'user',
        },
      },
    },

    {
      path: '/user',
      name: 'user',
      component: UserManagement,
      meta: {
        breadcrumb: {
          label: i18n.t('Benutzer'),
        },
      },
    },

    {
      path: '/user/add',
      name: 'user.add',
      component: User,
      meta: {
        breadcrumb: {
          label: i18n.t('Benutzer hinzufügen'),
          parent: 'user',
        },
      },
    },

    {
      path: '/user/edit/:uid',
      name: 'user.edit',
      component: User,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Benutzer bearbeiten'),
          parent: 'user',
        },
      },
    },

    {
      path: '/profile',
      name: 'profile',
      component: User,
      props: {
        editLoggedInUser: true,
      },
      meta: {
        breadcrumb: {
          label: i18n.t('Profil bearbeiten'),
          parent: 'user',
        },
      },
    },

    {
      path: '/location',
      name: 'location',
      component: LocationList,
      meta: {
        breadcrumb: {
          label: i18n.t('Orte'),
        },
      },
    },

    {
      path: '/location/add',
      name: 'location.add',
      component: Location,
      meta: {
        breadcrumb: {
          label: i18n.t('Ort hinzufügen'),
          parent: 'location',
        },
      },
    },

    {
      path: '/location/edit/:uid',
      name: 'location.edit',
      component: Location,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Ort bearbeiten'),
          parent: 'location',
        },
      },
    },

    {
      path: '/company',
      name: 'company',
      component: CompanyList,
      meta: {
        breadcrumb: {
          label: i18n.t('Unternehmen'),
        },
      },
    },

    {
      path: '/company/add',
      name: 'company.add',
      component: Company,
      meta: {
        breadcrumb: {
          label: i18n.t('Unternehmen hinzufügen'),
          parent: 'company',
        },
      },
    },

    {
      path: '/company/edit/:uid',
      name: 'company.edit',
      component: Company,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Unternehmen bearbeiten'),
          parent: 'company',
        },
      },
    },

    {
      path: '/terminal',
      name: 'terminal',
      component: TerminalList,
      meta: {
        breadcrumb: {
          label: i18n.t('Terminals'),
        },
      },
    },

    {
      path: '/terminal/add',
      name: 'terminal.add',
      component: TerminalCreate,
      meta: {
        breadcrumb: {
          label: i18n.t('Terminal hinzufügen'),
          parent: 'terminal',
        },
      },
    },

    {
      path: '/terminal/edit/:uid',
      name: 'terminal.edit',
      component: TerminalBase,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Terminal bearbeiten'),
          parent: 'terminal',
        },
      },
    },

    {
      path: '/terminal/edit/:uid/company',
      name: 'terminal.edit.company',
      component: TerminalCompany,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Terminalbetreiber ändern'),
          parent: 'terminal.edit',
        },
      },
    },

    {
      path: '/terminal/edit/:uid/contact',
      name: 'terminal.edit.contact',
      component: TerminalContact,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Kontaktdaten bearbeiten'),
          parent: 'terminal.edit',
        },
      },
    },

    {
      path: '/terminal/edit/:uid/features',
      name: 'terminal.edit.features',
      component: TerminalFeatures,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Features bearbeiten'),
          parent: 'terminal.edit',
        },
      },
    },

    {
      path: '/terminal/edit/:uid/documents',
      name: 'terminal.edit.documents',
      component: TerminalDocuments,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Dokumente bearbeiten'),
          parent: 'terminal.edit',
        },
      },
    },

    {
      path: '/operator',
      name: 'operator',
      component: Operator,
      meta: {
        breadcrumb: {
          label: i18n.t('Züge'),
        },
      },
    },

    {
      path: '/connection/add',
      name: 'connection.add',
      component: Connection,
      meta: {
        breadcrumb: {
          label: i18n.t('Verbindung hinzufügen'),
          parent: 'operator',
        },
      },
    },

    {
      path: '/connection/add/template/:templateId',
      name: 'connection.copy',
      props: true,
      component: Connection,
      meta: {
        breadcrumb: {
          label: i18n.t('Verbindung kopieren'),
          parent: 'operator',
        },
      },
    },

    {
      path: '/connection/edit/:uid',
      name: 'connection.edit',
      props: true,
      component: Connection,
      meta: {
        breadcrumb: {
          label: i18n.t('Verbindung bearbeiten'),
          parent: 'operator',
        },
      },
    },

    {
      path: '/train/add/:connectionId',
      name: 'train.add',
      props: true,
      component: Train,
      meta: {
        breadcrumb: {
          label: i18n.t('Zug hinzufügen'),
          parent: 'operator',
        },
      },
    },

    {
      path: '/train/add/template/:templateId',
      name: 'train.copy',
      props: true,
      component: Train,
      meta: {
        breadcrumb: {
          label: i18n.t('Zug kopieren'),
          parent: 'operator',
        },
      },
    },

    {
      path: '/train/copy/multi/:templateId',
      name: 'train.multicopy',
      props: true,
      component: TrainMultiCopy,
      meta: {
        breadcrumb: {
          label: i18n.t('Zug mehrfach kopieren'),
          parent: 'operator',
        },
      },
    },

    {
      path: '/train/edit/:uid',
      name: 'train.edit',
      component: Train,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Zug bearbeiten'),
          parent: 'operator',
        },
      },
    },

    {
      path: '/loadingpoint',
      name: 'loadingPoint',
      component: LoadingPointList,
      meta: {
        breadcrumb: {
          label: i18n.t('Ladestellen'),
        },
      },
    },

    {
      path: '/loadingpoint/add',
      name: 'loadingPoint.add',
      component: LoadingPoint,
      meta: {
        breadcrumb: {
          label: i18n.t('Ladestelle hinzufügen'),
          parent: 'loadingPoint',
        },
      },
    },

    {
      path: '/loadingpoint/edit/:uid',
      name: 'loadingPoint.edit',
      component: LoadingPoint,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Ladestelle bearbeiten'),
          parent: 'loadingPoint',
        },
      },
    },

    {
      path: '/loadingpoint/edit/:uid/sidingfeatures',
      name: 'loadingPoint.edit.sidingFeatures',
      component: SidingFeatures,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Gleißanschluss bearbeiten'),
          parent: 'loadingPoint.edit',
        },
      },
    },

    {
      path: '/loadingpoint/companytypes',
      name: 'loadingPoint.companyTypes',
      component: LoadingPointCompanyTypes,
      meta: {
        breadcrumb: {
          label: i18n.t('Unternehmenstypen der Ladestellen'),
          parent: 'loadingPoint',
        },
      },
    },

    {
      path: '/wagonload',
      name: 'wagonLoad',
      component: WagonLoadList,
      meta: {
        breadcrumb: {
          label: i18n.t('Einzelwagenverkehr'),
        },
      },
    },

    {
      path: '/wagonload/add',
      name: 'wagonLoad.add',
      component: WagonLoad,
      meta: {
        breadcrumb: {
          label: i18n.t('Einzelwagenverkehr anlegen'),
          parent: 'wagonLoad',
        },
      },
    },

    {
      path: '/wagonload/edit/:uid',
      name: 'wagonLoad.edit',
      component: WagonLoad,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Einzelwagenverkehr bearbeiten'),
          parent: 'WagonLoad',
        },
      },
    },

    {
      path: '/forwarder',
      name: 'forwarder',
      component: ForwarderList,
      meta: {
        breadcrumb: {
          label: i18n.t('Speditionen'),
        },
      },
    },

    {
      path: '/forwarder/edit/:uid',
      name: 'forwarder.edit',
      component: Forwarder,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Spedition bearbeiten'),
          parent: 'forwarder',
        },
      },
    },

    {
      path: '/forwarder/area/:forwarderUid/add/',
      name: 'forwarder.area.add',
      component: ForwarderArea,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Portfolio anlegen'),
          parent: 'forwarder.edit',
        },
      },
    },

    {
      path: '/forwarder/area/:forwarderUid/edit/:uid',
      name: 'forwarder.area.edit',
      component: ForwarderArea,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('Portfolio bearbeiten'),
          parent: 'forwarder.edit',
        },
      },
    },

    {
      path: '/news',
      name: 'news',
      component: NewsList,
      meta: {
        breadcrumb: {
          label: i18n.t('News'),
        },
      },
    },

    {
      path: '/news/add',
      name: 'news.add',
      component: News,
      meta: {
        breadcrumb: {
          label: i18n.t('News anlegen'),
          parent: 'news',
        },
      },
    },

    {
      path: '/news/edit/:uid',
      name: 'news.edit',
      component: News,
      props: true,
      meta: {
        breadcrumb: {
          label: i18n.t('News ändern'),
          parent: 'news',
        },
      },
    },

    {
      path: '/imprint',
      name: 'imprint',
      component: Imprint,
      meta: {
        breadcrumb: {
          label: i18n.t('Impressum'),
        },
      },
    },

    {
      path: '/country/centers',
      name: 'country.centers',
      component: CountryCenters,
      meta: {
        breadcrumb: {
          label: i18n.t('Länder Mittelpunkte'),
        },
      },
    },

    // catch all redirect
    { path: '*', redirect: '/welcome' },
  ],
} as any);

const allowedRoutes = ['login', 'lostpassword', 'user.setpassword'];

router.beforeEach((to, from, next) => {
  store.dispatch(UI.SHOW_LOADER);
  store.dispatch(UI.CLOSE_MENU);
  setTimeout(() => {
    const restrictedPage = !allowedRoutes.find(name => to.name === name);
    const loggedIn =
      store.getters[AUTHENTICATION_GETTERS.CURRENT_USER].isLoggedIn;

    if (restrictedPage && !loggedIn) {
      next({ name: 'login' });
    } else if (to.name === 'login' && loggedIn) {
      next({ name: 'welcome' });
    } else {
      next();
    }

    setTimeout(store.dispatch, 30, UI.HIDE_LOADER);
  }, 30);
});

export default router;
