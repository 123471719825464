import { mapGetters, mapState } from 'vuex';
import { NEWS, UI } from 'store/actions';
import { NEWS as NEWS_GETTERS } from 'store/getters';
import { sortListBy } from 'service/utils';
import moment from 'moment';
import { getTranslation } from 'kv_shared/lib/utils/localization';

export default {
  // add props here that are passed from parent templates or from router,
  // like item uid etc for data fetching.
  // do not mutate these, but use them as initial values for 'data'
  props: [],

  // Data fetching here if necessary
  created() {
    this.$store.dispatch(NEWS.LOAD_LIST);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      orderBy: 'publishDate',
      reverse: false,
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapGetters({
      news: NEWS_GETTERS.FILTERED_LIST,
    }),

    ...mapState<any>({
      currentLanguage: state => state.locale,
    }),

    filteredItems() {
      const filtered = this.news.map(n => ({
        ...n,
        title: getTranslation(n.localeTitle, this.currentLanguage, n.title),
      }));

      sortListBy(filtered, this.orderBy);
      if (this.reverse) {
        filtered.reverse();
      }
      return filtered;
    },

    searchFilter: {
      get() {
        return this.$store.state.news.searchString;
      },
      set(value) {
        this.$store.dispatch(NEWS.FILTER_LIST, value);
      },
    },
  },

  // methods that are available in the component or in templates
  methods: {
    order(orderBy) {
      if (this.orderBy === orderBy) {
        this.reverse = !this.reverse;
      } else {
        this.orderBy = orderBy;
        this.reverse = false;
      }
    },

    deleteNews(item) {
      this.$store
        .dispatch(UI.SHOW_CONFIRM_MODAL, {
          message: this.$t('Wollen Sie die News "{name}" wirklich löschen?', {
            name: item.title,
          }),
          confirm: this.$t('Ja'),
          close: this.$t('Nein'),
        })
        .then(response => {
          if (response.confirmed) {
            this.$store.dispatch(NEWS.REMOVE_ITEM, item);
          }
        });
    },

    // Maybe use filters --> https://github.com/brockpetrie/vue-moment ??
    parseDate(dateObj) {
      return moment(dateObj).format('DD.MM.YYYY');
    },
  },
};
