import Vue from 'vue';
import { mapState } from 'vuex';
import { editComponent } from 'mixins/edit-component';
import { TERMINAL, UI } from 'store/actions';
import isEqual from 'lodash/isEqual';
import { Terminal } from 'kv_shared/lib/data-types';

export default (Vue as any).extend({
  mixins: [editComponent(Terminal, state => state.terminals.current)],

  // Data fetching
  created() {
    this.$store.dispatch(TERMINAL.LOAD_CURRENT_ITEM, this.uid);
  },

  data() {
    return {
      isUploading: false,
      filesToDelete: [],
      filesToUpload: [],
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapState<any>({
      apiUrl: state => state.api.apiUrl,
    }),
  },

  // methods that are available in the component or in templates
  methods: {
    prepareDelete(doc) {
      this.$store
        .dispatch(UI.SHOW_CONFIRM_MODAL, {
          message: this.$t('Wollen Sie das Dokument löschen?'),
          confirm: this.$t('Ja'),
          close: this.$t('Nein'),
        })
        .then(response => {
          if (response.confirmed) {
            if (doc.uid && !this.filesToDelete.find(d => isEqual(d, doc))) {
              this.filesToDelete.push(doc);
            }
            this.itemData.documents = this.itemData.documents.filter(
              d => !isEqual(d, doc),
            );
            this.filesToUpload = this.filesToUpload.filter(
              d => !isEqual(d, doc),
            );
          }
        });
    },

    prepareUpload(files) {
      const doc = files && files[0];

      if (doc && !this.filesToUpload.find(d => isEqual(d, doc))) {
        doc.label = doc.label || '';
        this.filesToUpload.push(doc);
        this.itemData.documents.push(doc);
      }
    },

    cancelEdit() {
      this.filesToDelete = [];
      this.filesToUpload = [];
      this.resetData();
    },

    submit() {
      this.isUploading = true;

      // Sequentially delete files
      const deletePromise = this.filesToDelete
        .reduce((promise, doc) => {
          return promise.then(() => {
            return this.$store
              .dispatch(TERMINAL.REMOVE_DOCUMENT, {
                terminal: this.itemData,
                document: doc,
              })
              .catch(e => {
                console.log('error deleting file', doc, e);
              });
          });
        }, Promise.resolve())
        .then(() => (this.filesToDelete = []));

      // Sequentially upload files
      const uploadPromise = this.filesToUpload
        .reduce((promise, doc) => {
          return promise.then(() => {
            return this.$store
              .dispatch(TERMINAL.ADD_DOCUMENT, {
                terminal: this.itemData,
                file: doc,
              })
              .catch(e => {
                console.log('error uploading file', doc, e);
                this.itemData.documents = this.itemData.documents.filter(
                  d => !isEqual(d, doc),
                );
              });
          });
        }, deletePromise)
        .then(() => (this.filesToUpload = []));

      // Save terminal if changed
      uploadPromise
        .then(() => {
          if (!this.isClean) {
            return this.$store
              .dispatch(TERMINAL.SAVE_ITEM, this.itemData)
              .catch(e => {
                alert(
                  this.$t(
                    'Beim Speichern des Terminals ist ein Fehler aufgetreten.',
                  ),
                );
                console.error('item save error', e);
              });
          }
        })
        .then(() => {
          this.isUploading = false;
        });
    },
  },
});
