import { AUTHENTICATION } from 'store/actions';

export default {
  data() {
    return {
      email: null,
      messages: null,
    };
  },

  methods: {
    handleLostPassword() {
      this.$validator
        .validateAll()
        .then(valid => {
          if (!valid) {
            console.log('form errors');
            return;
          }

          this.messages = null;

          const passwordData = {
            email: this.email.toLowerCase(),
          };

          this.$store
            .dispatch(AUTHENTICATION.RESET_PASSWORD, passwordData)
            .then(() => {
              this.messages = {
                passwordSent: true,
              };
            })
            .catch(error => {
              // handle system specific errors
              if (error.dataPromise) {
                error.dataPromise.then(data => {
                  switch (data.S_notification) {
                    default:
                      this.messages = {
                        unknownError: true,
                      };
                      console.log('unhandled error code', data);
                  }
                });
              } else {
                console.error('unknown (not system specific) error', error);
              }
            });
        })
        .catch(e => {
          console.log('form errors', e);
        });
    },
  },
};
