import { COUNTRY as COUNTRY_GETTERS } from 'store/getters';
import debounce from 'lodash/debounce';

export default {
  props: ['geoLocationLabel', 'radius', 'isActive', 'geoLocationOptions'],

  data() {
    return {
      radiusOptions: [
        { value: 10, label: '10km' },
        { value: 25, label: '25km' },
        { value: 50, label: '50km' },
        { value: 100, label: '100km' },
        { value: 150, label: '150km' },
        { value: 200, label: '200km' },
      ],
      currentLocationOptions: [],
    };
  },

  computed: {
    radiusSelection() {
      return this.radiusOptions.find(opt => opt.value === this.radius);
    },

    locationSelection() {
      return (
        this.geoLocationOptions.find(
          opt => opt.label === this.geoLocationLabel,
        ) || { label: this.geoLocationLabel, value: null }
      );
    },

    countryNames() {
      return this.$store.getters[COUNTRY_GETTERS.NAMES_BY_ID];
    },

    selectionText() {
      if (this.countryCode && this.geoLocation) {
        return this.countryCode.label + ', ' + this.geoLocation.label;
      }
    },
  },

  watch: {
    geoLocationOptions() {
      if (
        this.isActive &&
        this.geoLocationOptions &&
        this.geoLocationOptions.length
      ) {
        this.currentLocationOptions = this.geoLocationOptions;
      }
    },
  },

  methods: {
    selectGeoLocation(value) {
      this.$emit('selectGeoLocation', value);
    },

    selectRadius(value) {
      this.$emit('selectRadius', value);
    },

    setGeoPosQuery(query) {
      submitGeoPosQuery(query, this);
    },

    removeLocation() {
      this.$emit('removeLocation');
    },
  },
};

const submitGeoPosQuery = debounce((searchString, component) => {
  if (searchString && searchString.length > 1) {
    component.$emit('setGeoPosQuery', searchString);
  } else {
    component.currentLocationOptions = [];
  }
}, 200);
