import { LOCATION as ACTIONS, API } from 'store/actions';
import { LOCATION as MUTATIONS } from 'store/mutations';
// tslint:disable-next-line:max-line-length
import { LOCATION as GETTERS, COUNTRY as COUNTRY_GETTERS } from 'store/getters';
import {
  listToIdMap,
  localeCompareAvailable,
  defaultValueOnHttpError,
} from 'service/utils';
import {
  defaultItemActions,
  defaultItemMutations,
  defaultItemState,
} from 'store/commons/item-list';
import {
  defaultSearchableState,
  defaultSearchableActions,
  defaultSearchableMutations,
} from 'store/commons/searchable';
import { freezeToStopVueReactivity } from 'service/vue-helpers';
import i18n from 'kv_shared/lib/vue/i18n';

const state = {
  ...defaultItemState(),
  ...defaultSearchableState(),
};

const getters = {
  [GETTERS.BY_ID]: state => listToIdMap(state.list),

  [GETTERS.LOCALE_NAMES]: (state, getters, rootState) => {
    const names: { [id: string]: string } = {};
    const placeUnavailable = i18n.t('(kein Ortsname verfügbar)');

    for (const location of state.list) {
      const locationName =
        location.name[rootState.locale] ||
        location.name.locale ||
        (rootState.availableLanguages[0] &&
          location.name[rootState.availableLanguages[0]]) ||
        (rootState.availableLanguages[1] &&
          location.name[rootState.availableLanguages[1]]) ||
        (rootState.availableLanguages[2] &&
          location.name[rootState.availableLanguages[2]]) ||
        (rootState.availableLanguages[3] &&
          location.name[rootState.availableLanguages[3]]) ||
        (rootState.availableLanguages[4] &&
          location.name[rootState.availableLanguages[4]]) ||
        placeUnavailable;

      names[location.uid] = locationName;
    }

    return names;
  },

  [GETTERS.FILTERED_LIST]: (state, getters, rootState) => {
    const filtered: any[] = [];
    for (const location of state.list) {
      const countryName =
        getters[COUNTRY_GETTERS.NAMES_BY_ID][location.country.uid];

      const locationName = getters[GETTERS.LOCALE_NAMES][location.uid];

      if (
        locationName.toLowerCase().indexOf(state.searchString) > -1 ||
        countryName.toLowerCase().indexOf(state.searchString) > -1
      ) {
        filtered.push(
          freezeToStopVueReactivity({
            ...location,
            countryName,
          }),
        );
      }
    }

    return filtered;
  },

  [GETTERS.SORTED_BY_NAME]: (state, getters, rootState) => {
    function sort(a, b) {
      const nameA = (
        a.name[rootState.locale] ||
        a.name.locale ||
        ''
      ).toLowerCase();
      const nameB = (
        b.name[rootState.locale] ||
        b.name.locale ||
        ''
      ).toLowerCase();
      if (localeCompareAvailable) {
        return nameA.localeCompare(nameB);
      }
      return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
    }

    return state.list.sort(sort);
  },
};

const actions = {
  ...defaultItemActions(ACTIONS, MUTATIONS),
  ...defaultSearchableActions(ACTIONS, MUTATIONS),

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, 'location/locality')
      .then(response => {
        return response.localityList || [];
      })
      .catch(error => {
        console.error('handleHttpError', error);
        return [];
      });
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, 'location/locality/' + uid).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_DELETE_BY_ID]({ dispatch }, uid) {
    return dispatch(API.DELETE, 'location/locality/' + uid);
  },

  [ACTIONS.API_CREATE]({ dispatch }, newItem) {
    return dispatch(API.POST, { path: 'location/locality/create' }).then(
      response => {
        return dispatch(API.PUT, {
          path: 'location/locality/' + response.uid + '/init',
          body: newItem,
        }).then(() => response);
      },
    );
  },

  [ACTIONS.API_UPDATE]({ dispatch }, location) {
    return dispatch(API.PUT, {
      path: 'location/locality/' + location.uid,
      body: location,
    });
  },
};

const mutations = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSearchableMutations(MUTATIONS),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
