import isEqual from 'lodash/isEqual';
import deepmerge from 'deepmerge';

export function listToIdMap(list, propName = 'uid') {
  const map = {};
  for (const item of list) {
    map[item[propName]] = item;
  }
  return map;
}

export const localeCompareAvailable = typeof 'x'.localeCompare === 'function';

export function sortListBy(list, ...properties) {
  return list.sort((a, b) => {
    let result = 0;

    for (const prop of properties) {
      const valA = a[prop];
      const valB = b[prop];

      if (
        typeof valA === 'string' &&
        typeof valB === 'string' &&
        localeCompareAvailable
      ) {
        result = valA.toLowerCase().localeCompare(valB.toLowerCase());
      } else {
        result = valA > valB ? 1 : valA < valB ? -1 : 0;
      }
      if (result !== 0) {
        return result;
      }
    }
    return result;
  });
}

export function defaultValueOnHttpError(val) {
  return error => {
    console.error('handleHttpError', error);
    return val;
  };
}

export function normalizeUrl(url) {
  if (url && url.toLowerCase().indexOf('http') !== 0) {
    url = 'http://' + url;
  }
  return url;
}

// compare data with default values and return changes, to save traffic send to server.
// unused for now cause api does not delete untransmitted keys.
export function deepDiffWithDefaults(data: object, defaults: object): object {
  const result = Array.isArray(data) ? [] : {};

  for (const key in data) {
    const value = data[key];

    // include missing defaults,
    // or default values that needs to be transmitted,
    // like true boolean, non empty strings, numbers, arrays
    if (
      !defaults ||
      (typeof value === 'boolean' && value) ||
      (typeof value === 'string' && value) ||
      typeof value === 'number' ||
      Array.isArray(value)
    ) {
      result[key] = value;
      continue;

      // omit if values are equal
    } else if (isEqual(defaults[key], value)) {
      continue;
    }

    // traverse tree if needed
    result[key] =
      typeof value === 'object'
        ? deepDiffWithDefaults(value, defaults[key])
        : value;
  }

  return result;
}

// Deepmerge old arraymerge behavior
// See: https://github.com/KyleAMathews/deepmerge

const emptyTarget = value => (Array.isArray(value) ? [] : {});
const clone = (value, options) => deepmerge(emptyTarget(value), value, options);

function oldArrayMerge(target, source, options) {
  const destination = target.slice();

  source.forEach((e, i) => {
    if (typeof destination[i] === 'undefined') {
      const cloneRequested = options.clone !== false;
      const shouldClone = cloneRequested && options.isMergeableObject(e);
      destination[i] = shouldClone ? clone(e, options) : e;
    } else if (options.isMergeableObject(e)) {
      destination[i] = deepmerge(target[i], e, options);
    } else if (target.indexOf(e) === -1) {
      destination.push(e);
    }
  });
  return destination;
}

export function merge<A>(obj1: Partial<A>, obj2: Partial<A>) {
  return deepmerge<A>(obj1, obj2, { arrayMerge: oldArrayMerge });
}
