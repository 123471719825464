// Api module uses the new fetch API and a polyfill for older browsers
// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API

import { API as ACTIONS, UI, AUTHENTICATION } from 'store/actions';
import { API as MUTATIONS } from 'store/mutations';
import * as stringify from 'qs/lib/stringify';

// Interfaces and Types

interface NetError extends Error {
  response?: any;
  dataPromise?: any;
}

// Module Definition

const state = {
  apiUrl: 'https://' + window.location.hostname + '/api/',
};

const actions = {
  [ACTIONS.GET](ctx, path) {
    if (typeof path === 'object') {
      path = path.path + (path.query ? '?' + stringify(path.query) : '');
    }
    const config: RequestInit = {
      method: 'GET',
    };
    return httpRequest(path, config, ctx);
  },

  [ACTIONS.POST](ctx, { path, body }) {
    const config: RequestInit = {
      method: 'POST',
      body: body && JSON.stringify(body),
    };
    return httpRequest(path, config, ctx);
  },

  [ACTIONS.PUT](ctx, { path, body }) {
    const config: RequestInit = {
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return httpRequest(path, config, ctx);
  },

  [ACTIONS.DELETE](ctx, path) {
    const config: RequestInit = {
      method: 'DELETE',
    };
    return httpRequest(path, config, ctx);
  },

  [ACTIONS.UPLOAD](ctx, { path, file }) {
    const data = new FormData();
    data.append('file', file);
    const config: RequestInit = {
      method: 'POST',
      body: data,
    };
    return httpRequest(path, config, ctx);
  },
};

const mutations = {
  [MUTATIONS.SET_URL](state, url) {
    state.apiUrl = sanitizeApiUrl(url);
  },
};

export default {
  state,
  actions,
  mutations,
};

// Private helper functions

function addAuthentication(state, headers: Headers) {
  const authData = state.authentication.authData;
  if (authData && authData.oat) {
    let oat = authData.oat;
    if (state.authentication.reauthRequest && authData.roat) {
      oat = authData.roat;
    }
    headers.append('Authorization', 'Bearer ' + oat);
  }
}

function checkStatus(response: Response, path, config, actionContext) {
  if (response.status >= 200 && response.status < 300) {
    return (
      response
        .json()
        // catch empty body exeption with .json()
        .catch(e => {
          console.warn && console.warn('parsing response json', e);
          return {};
        })
    );
  } else if (response.status === 401 && path.indexOf('user/roat') < 0) {
    return actionContext
      .dispatch(AUTHENTICATION.REAUTHENTICATE)
      .then(() => httpRequest(path, config, actionContext));
  } else {
    const error: NetError = new Error(response.statusText);
    error.response = response;
    error.dataPromise = response.json();
    throw error;
  }

  // TODO: catch 401 and do Reauthorization with roat
  // see old app.module.js
}

function sanitizeApiUrl(url: string) {
  url = url.trim();
  if (url[url.length - 1] !== '/') {
    url += '/';
  }
  return url;
}

function sanitizeEntpoint(url: string) {
  url = url.trim();
  if (url[0] === '/') {
    url = url.substr(1);
  }
  return url;
}

function httpRequest(path, config, actionContext) {
  const { dispatch, state, rootState } = actionContext;

  if (!config.headers) {
    config.headers = new Headers();
  }

  // config.credentials = 'include';

  addAuthentication(rootState, config.headers);
  dispatch(UI.SHOW_LOADER);

  return fetch(state.apiUrl + sanitizeEntpoint(path), config)
    .catch(e => {
      setTimeout(dispatch, 50, UI.HIDE_LOADER);
      throw e;
    })
    .then(response => {
      setTimeout(dispatch, 50, UI.HIDE_LOADER);
      return checkStatus(response, path, config, actionContext);
    });
}
