import { COUNTRY as ACTIONS, API } from 'store/actions';
import { COUNTRY as MUTATIONS } from 'store/mutations';
import { COUNTRY as GETTERS } from 'store/getters';
import { listToIdMap, defaultValueOnHttpError } from 'service/utils';
import {
  defaultItemActions,
  defaultItemMutations,
  defaultItemState,
} from 'store/commons/item-list';
import i18n from 'kv_shared/lib/vue/i18n';
import { DEFAULT_LANGUAGE } from 'kv_shared/lib/utils/localization';

const state = {
  ...defaultItemState(),
};

const getters = {
  [GETTERS.BY_ID]: state => listToIdMap(state.list),

  [GETTERS.BY_CODE]: state => listToIdMap(state.list, 'code'),

  [GETTERS.NAMES_BY_ID]: (state, getters, rootState) => {
    const name = {};
    for (const country of state.list) {
      const localeName =
        country.name[rootState.locale] ||
        country.name[DEFAULT_LANGUAGE] ||
        i18n.t('(unbekannt)');

      name[country.uid] = localeName;
    }

    return name;
  },
};

const actions = {
  ...defaultItemActions(ACTIONS, MUTATIONS),

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, 'location/country')
      .then(response => {
        return response.countryList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, 'location/country/' + uid).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_DELETE_BY_ID]({ dispatch }, uid) {
    return dispatch(API.DELETE, 'location/country/' + uid);
  },

  [ACTIONS.API_CREATE]({ dispatch }, newItem) {
    return dispatch(API.POST, { path: 'location/country/create' }).then(
      response => {
        return dispatch(API.PUT, {
          path: 'location/country/' + response.uid + '/init',
          body: newItem,
        }).then(() => response);
      },
    );
  },

  [ACTIONS.API_UPDATE]({ dispatch }, country) {
    return dispatch(API.PUT, {
      path: 'location/country/' + country.uid,
      body: country,
    });
  },
};

const mutations = {
  ...defaultItemMutations(MUTATIONS),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
