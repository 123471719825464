// import { mapState } from "vuex";
import Vue from 'vue';
import { Country } from 'kv_shared/lib/data-types';
import { merge, sortListBy } from 'service/utils';
import isEqual from 'lodash/isEqual';
import { COUNTRY } from 'store/actions';
import { mapState, mapGetters } from 'vuex';
import { COUNTRY as COUNTRY_GETTERS } from 'store/getters';
import map from 'lodash/map';

export default {
  // add props here that are passed from parent templates or from router,
  // like item uid etc for data fetching.
  // do not mutate these, but use them as initial values for 'data'
  props: [],

  // Data fetching here if necessary
  created() {
    this.$store.dispatch(COUNTRY.LOAD_LIST);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      countryData: new Country(),
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapState<any>({
      countries: state => state.countries.list,
      initialData: state => state.countries.current,
    }),
    ...mapGetters({
      countryNamesById: COUNTRY_GETTERS.NAMES_BY_ID,
    }),

    countryOptions() {
      const options = map(this.countryNamesById, (name, uid) => ({
        value: uid,
        label: name,
      }));
      return sortListBy(options, 'label');
    },

    countrySelection: {
      get() {
        return this.countryOptions.find(
          opt => opt.value === this.countryData.uid,
        );
      },
      set(value) {
        this.$store.dispatch(COUNTRY.LOAD_CURRENT_ITEM, value && value.value);
      },
    },

    isClean() {
      if (this.initialData) {
        const initial = merge(new Country(), this.initialData);
        return isEqual(this.countryData, initial);
      } else {
        return isEqual(this.countryData, new Country());
      }
    },
  },

  // watch some data. Use with care, prefer computed properties if possible
  watch: {
    initialData: 'resetData',
  },

  // methods that are available in the component or in templates
  methods: {
    resetData() {
      if (this.initialData) {
        this.countryData = merge(new Country(), this.initialData);
      } else {
        this.countryData = new Country();
      }
    },

    submitForm() {
      this.$validator
        .validateAll()
        .then(valid => {
          if (!valid) {
            console.log('form errors');
            return;
          }

          this.$store
            .dispatch(COUNTRY.SAVE_ITEM, this.countryData)
            .catch(error => {
              // handle system specific errors
              console.error('unknown (not system specific) error', error);
            });
        })
        .catch(() => {
          console.log('form errors, saving failed');
        });
    },

    resetForm() {
      this.resetData();
      Vue.nextTick(() => {
        this.$errors.clear();
      });
    },
  },
};
