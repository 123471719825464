import Vue from 'vue';
import { editComponent } from 'mixins/edit-component';
import { editPlaceComponent } from 'mixins/edit-place-component';
import { TERMINAL } from 'store/actions';
import { COMPANY as COMPANY_GETTERS } from 'store/getters';
import { Terminal } from 'kv_shared/lib/data-types';

export default (Vue as any).extend({
  mixins: [
    editComponent(Terminal, state => state.terminals.current),
    editPlaceComponent(COMPANY_GETTERS.TERMINAL_CARRIERS),
  ],

  created() {
    this.$store.dispatch(TERMINAL.LOAD_CURRENT_ITEM, this.uid);
  },

  methods: {
    submitCallback() {
      this.$store.dispatch(TERMINAL.SAVE_ITEM, this.itemData).catch(e => {
        alert(this.$t('Beim Ändern des Terminals ist ein Fehler aufgetreten.'));
        console.error('item save error', e);
      });
    },
  },
});
