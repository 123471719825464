import { mapGetters, mapState } from 'vuex';
import { TRAIN, CONNECTION, LOCATION, TERMINAL } from 'store/actions';
// tslint:disable-next-line:max-line-length
import {
  CONNECTION as CONNECTION_GETTERS,
  LOCATION as LOCATION_GETTERS,
  TERMINAL as TERMINAL_GETTERS,
} from 'store/getters';
import { editComponent } from 'mixins/edit-component';
import Vue from 'vue';
import { weekDays, weekDaysMini } from 'service/moment-service';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { TIME_REGEX, PRICE_REGEX } from 'service/constants';
import { DEFAULT_LANGUAGE } from 'kv_shared/lib/utils/localization';
import { Train } from 'kv_shared/lib/data-types';
import router from '../../config/router';

class DefaultStation {
  name = '';
  uid = '';
  terminals = [];
  terminalOptions = [];
  sectionPrice = null;
}

class DefaultTerminalTiming {
  uid = '';
  delivery = {
    day: '',
    time: '',
  };
  collection = {
    day: '',
    time: '',
  };
}

export default (Vue as any).extend({
  mixins: [editComponent(Train, state => state.trains.current)],

  created() {
    if (this.uid) {
      this.$store.dispatch(TRAIN.LOAD_CURRENT_ITEM, this.uid);
    } else if (this.templateId) {
      this.$store.dispatch(TRAIN.LOAD_CURRENT_ITEM, this.templateId);
    }
    if (this.connectionId) {
      this.$store.dispatch(CONNECTION.LOAD_CURRENT_ITEM, this.connectionId);
    }
    this.$store.dispatch(CONNECTION.LOAD_LIST);
    this.$store.dispatch(LOCATION.LOAD_LIST);
    this.$store.dispatch(TERMINAL.LOAD_LIST);
  },

  // add props here that are passed from parent templates or from router,
  // like item uid etc for data fetching.
  // do not mutate these, but use them as initial values for 'data'
  props: ['connectionId', 'templateId'],

  // local component state, that is not saved in global vuex store
  data() {
    return {
      stations: [new DefaultStation(), new DefaultStation()],
      initialStations: null,

      itemDataBackup: null,
      stationsBackup: null,
      currentBlockEdit: this.uid ? 0 : 1,
      finishedBlock: this.uid ? 6 : 0,

      timeRegex: TIME_REGEX,
      priceRegex: PRICE_REGEX,
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapGetters({
      connectionList: CONNECTION_GETTERS.OF_CURRENT_USER,
      locationList: LOCATION_GETTERS.BY_ID,
      locationNames: LOCATION_GETTERS.LOCALE_NAMES,
      terminalsById: TERMINAL_GETTERS.BY_ID,
    }),

    ...mapState<any>({
      currentLanguage: state => state.locale,
      terminalList: state => state.terminals.list,
      connectionData: state => state.connections.current,
    }),

    isTrainDirectionReversed() {
      let isReversed = false;

      if (this.itemData && this.itemData.uid && this.connectionData) {
        const firstTerminal = this.terminalsById[this.itemData.terminal[0].uid];
        if (firstTerminal) {
          isReversed =
            firstTerminal.locality.uid !== this.connectionData.locality[0].uid;
        }
      }

      return isReversed;
    },

    weekdayOptions() {
      return weekDays.map(weekday => ({
        value: weekday.id,
        label: this.$t(weekday.label),
      }));
    },

    departureDaySelection: {
      get() {
        return this.weekdayOptions.find(
          weekday => weekday.value === this.itemData.departure.day,
        );
      },

      set(value) {
        this.itemData.departure.day = value && value.value;
      },
    },

    arrivalDaySelection: {
      get() {
        return this.weekdayOptions.find(
          weekday => weekday.value === this.itemData.arrival.day,
        );
      },

      set(value) {
        this.itemData.arrival.day = value && value.value;
      },
    },

    bookingDaySelection: {
      get() {
        return this.weekdayOptions.find(
          weekday => weekday.value === this.itemData.endOfBooking.day,
        );
      },

      set(value) {
        this.itemData.endOfBooking.day = value && value.value;
      },
    },

    weekdayMiniOptions() {
      return weekDaysMini.map(weekday => ({
        value: weekday.id,
        label:
          weekday.label[this.currentLanguage] ||
          weekday.label[DEFAULT_LANGUAGE],
      }));
    },

    usableUnitOptions() {
      const usableUnits = [
        {
          value: 'container20',
          label: this.$t("20' Ct"),
        },
        {
          value: 'container30',
          label: this.$t("30' Ct"),
        },
        {
          value: 'container40',
          label: this.$t("40' Ct"),
        },
        {
          value: 'container45',
          label: this.$t("45' Ct"),
        },
        {
          value: 'exchangeableContainer',
          label: this.$t('Wechselbehälter'),
        },
        {
          value: 'tankContainer',
          label: this.$t('Tankcontainer'),
        },
        {
          value: 'semiTrailer',
          label: this.$t('Sattelanhänger'),
        },
        {
          value: 'nikrasa',
          label: this.$t('Nichtkranbare Sattelanhänger'),
        },
        {
          value: 'bulk',
          label: this.$t('Bulk'),
        },
        {
          value: 'roLa',
          label: this.$t('RoLa'),
        },
      ];

      return usableUnits;
    },

    usableUnitSelection: {
      get() {
        return this.usableUnitOptions.find(
          unit => unit.value === this.itemData.prices.usableUnit,
        );
      },

      set(value) {
        this.itemData.prices.usableUnit = value && value.value;
      },
    },

    usableUnitRequired() {
      return (
        this.stations.length > 2 &&
        this.stations.some(station => station.sectionPrice)
      );
    },

    allPriceRequired() {
      return !!this.itemData.prices.usableUnit;
    },

    isTrainClean() {
      return this.isClean && isEqual(this.stations, this.initialStations);
    },
  },

  // watch some data. Use with care, prefer computed properties if possible
  watch: {
    connectionData: 'setupStations',
    locationList: 'setupStations',
    terminalList: 'setupStations',
    $route: 'resetData', // react to route change after copy
    itemData: {
      deep: true,
      handler: 'validateBlock',
    },
    stations: {
      deep: true,
      handler: 'validateBlock',
    },
  },

  methods: {
    resetDataCallback() {
      const defaultData = new Train();
      if (this.templateId) {
        this.itemData.uid = defaultData.uid;
        this.itemData.name = defaultData.name;
        this.itemData.capacitiesLeft = defaultData.capacitiesLeft;
      }

      if (!this.connectionData && !this.connectionId && this.currentStoreData) {
        this.$store.dispatch(
          CONNECTION.LOAD_CURRENT_ITEM,
          this.currentStoreData.connection.uid,
        );
      }

      this.setupStations();
    },

    setupStations() {
      if (this.connectionData && this.locationList && this.terminalList) {
        this.stations = this.connectionData.locality.map(locality => ({
          name: this.getLocationName(locality.uid),
          uid: locality.uid,
          terminals: this.getSelectedTerminals(locality.uid),
          terminalOptions: this.getTerminalOptions(locality.uid),
        }));

        if (this.isTrainDirectionReversed) {
          this.changeTrainDirection();
        }

        // Assign sectionPrice AFTER the stations have been reversed because we can only
        // reference the sectionPrices via index.
        this.stations.forEach((station, index) => {
          station.sectionPrice =
            (this.itemData.prices.sectionPrices[index] &&
              this.itemData.prices.sectionPrices[index].price) ||
            '';
        });

        this.initialStations = cloneDeep(this.stations);

        requestAnimationFrame(() => {
          this.$errors.clear();
          for (const field in this.$fields) {
            this.$validator.flag(field, {
              dirty: false,
              invalid: false,
              pristine: true,
              touched: false,
            });
          }
        });
      }
    },

    validateBlock: debounce(function() {
      this.$validator.validateAll('block' + this.currentBlockEdit);
    }, 60),

    createStationTerminal(terminal) {
      // Neeed to copy because Object is frozen
      return {
        ...terminal,
        timing: this.getTerminalTimings(terminal.uid),
      };
    },

    setStationTerminals(selected, station) {
      station.terminals = selected.map(this.createStationTerminal);
    },

    getLocationName(localityUid) {
      return this.locationNames[localityUid] || '';
    },

    getSelectedTerminals(localityUid) {
      const list = this.terminalList.filter(terminal => {
        return (
          terminal.locality.uid === localityUid &&
          this.itemData.terminal.find(
            itemTerminal => itemTerminal.uid === terminal.uid,
          )
        );
      });

      return list.map(this.createStationTerminal);
    },

    getTerminalTimings(terminalUid) {
      const oldTiming = this.itemData.terminalTimings.find(
        terminal => terminal.uid === terminalUid,
      );
      return oldTiming
        ? { ...new DefaultTerminalTiming(), ...oldTiming, uid: terminalUid }
        : { ...new DefaultTerminalTiming(), uid: terminalUid };
    },

    getTerminalOptions(localityUid) {
      return this.terminalList.filter(
        terminal => terminal.locality.uid === localityUid,
      );
    },

    getTimingDay(timingObj) {
      return this.weekdayMiniOptions.find(
        weekday => weekday.value === timingObj.day,
      );
    },

    getTimingTime(timingObj) {
      let time = timingObj.time;

      // Default: see old projekt
      if (timingObj.time && timingObj.time.h !== undefined) {
        if (timingObj.time.h === '' && timingObj.time.m === '') {
          time = '';
        } else {
          time =
            ('00' + timingObj.time.h).substr(-2) +
            ':' +
            ('00' + timingObj.time.m).substr(-2);
        }
      }

      return time;
    },

    // Implemented from old code
    // TODO: find out real use case and implement proper functionality
    checkAndCopyTimingsOfStation(station, type) {
      let time;
      let day;

      for (const terminal of station.terminals) {
        const timing = terminal.timing[type];
        day = day || timing.day;
        time = time || timing.time;

        timing.day = timing.day || day;
        timing.time = timing.time || time;
      }
    },

    changeTrainDirection() {
      this.stations.reverse();
    },

    storeFormBlock(blockId) {
      if (!this.$errors.any('block' + blockId)) {
        this.finishedBlock = this.itemData.uid ? 6 : blockId;
        if (this.itemData.uid || this.finishedBlock >= 6) {
          this.currentBlockEdit = 0;
        } else {
          this.editFormBlock(blockId + 1);
        }
      } else {
        console.warn('blockId: ', blockId, this.$errors.all('block' + blockId));
      }
    },

    abortFormBlockEdit() {
      this.itemData = this.itemDataBackup;
      this.stations = this.stationsBackup;
      this.currentBlockEdit = 0;
      this.finishedBlock = this.finishedBlockBeforeEdit;
    },

    editFormBlock(blockId) {
      this.validateBlock();
      console.log('editFormBlock: ', blockId);
      this.itemDataBackup = cloneDeep(this.itemData);
      this.stationsBackup = cloneDeep(this.stations);
      this.finishedBlockBeforeEdit = this.finishedBlock;
      this.finishedBlock = blockId - 1;
      this.currentBlockEdit = blockId;
    },

    checkErrorByBlock(field, blockNr) {
      return (
        (this.submitted || this.currentBlockEdit === blockNr) &&
        (field
          ? this.$errors.has(field, 'block' + blockNr)
          : this.$errors.any())
      );
    },

    submit() {
      this.submitted = true;
      Promise.all(
        [0, 1, 2, 3, 4].map(i => this.$validator.validateAll('block' + i)),
      ).then(() => {
        // write stations into train
        const newTerminals: any[] = [];
        const newTimings: any[] = [];
        const newSectionPrices: any[] = [];

        this.stations.forEach(station => {
          station.terminals.forEach(terminal => {
            newTerminals.push({ uid: terminal.uid });
            newTimings.push(terminal.timing);
          });

          newSectionPrices.push({ price: station.sectionPrice });
        });

        this.itemData.terminal = newTerminals;
        this.itemData.terminalTimings = newTimings;
        this.itemData.prices.sectionPrices = newSectionPrices;

        // update connection and operator
        if (!this.itemData.connection.uid) {
          this.itemData.connection.uid = this.connectionData.uid;
        }
        if (!this.itemData.operator.uid) {
          this.itemData.operator = this.connectionData.operator;
        }

        return this.$store
          .dispatch(TRAIN.SAVE_ITEM, this.itemData)
          .then(() => {
            if (this.$route.name === 'train.copy') {
              router.replace({
                name: 'train.edit',
                params: {
                  uid: this.currentStoreData.uid,
                  connectionId: this.itemData.connection.uid,
                },
              });
            }
          })
          .catch(e => {
            if (this.itemData.uid) {
              alert(
                this.$t('Beim Ändern des Zugs ist ein Fehler aufgetreten.'),
              );
            } else {
              alert(
                this.$t('Beim Anlegen des Zugs ist ein Fehler aufgetreten.'),
              );
            }
            console.error('update error', e);
          });
      });
    },
  },
});
