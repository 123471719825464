import { CONNECTION, LOCATION } from 'store/actions';
import {
  AUTHENTICATION as AUTHENTICATION_GETTERS,
  LOCATION as LOCATION_GETTERS,
  CONNECTION as CONNECTION_GETTERS,
} from 'store/getters';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Connection } from 'kv_shared/lib/data-types';
import { flatMap } from 'lodash';
import { bookableConnectionId } from 'kv_shared/lib/bookingProviderHack';
// import isEqual from 'lodash/isEqual';

interface BookableConnection {
  id: string;
  localities: string[];
}

export default (Vue as any).extend({
  model: {
    prop: 'connectionIds',
    event: 'change',
  },

  props: ['connectionIds'],

  data: () => ({ nameFilter: '', reverseSort: true }),

  created() {
    this.$store.dispatch(CONNECTION.LOAD_LIST);
    this.$store.dispatch(LOCATION.LOAD_LIST);
  },

  computed: {
    ...mapGetters({
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
      connectionById: CONNECTION_GETTERS.BY_ID,
      locationNames: LOCATION_GETTERS.LOCALE_NAMES,
    }),

    bookableById() {
      return this.$store.state.connections.list.reduce(
        (conns: { [id: string]: BookableConnection }, c: Connection) => {
          let connections = permutatePairs(
            c.locality.filter(e => !!(e.uid && this.locationNames[e.uid])),
          );

          for (const connection of connections) {
            const id = bookableConnectionId(connection);
            const conn: BookableConnection = conns[id] || {
              id,
              localities: connection.map(l => l.uid),
            };

            conns[id] = conn;
          }

          return conns;
        },
        {},
      );
    },

    bookableByLocation() {
      return Object.values<BookableConnection>(this.bookableById).reduce(
        (conns, bookable) => {
          for (const locId of bookable.localities) {
            const conn = conns[locId] || { connections: [] };
            conn.connections.push(bookable);
            conns[locId] = conn;
          }
          return conns;
        },
        {} as { [id: string]: { connections: BookableConnection[] } },
      );
    },

    selected() {
      return this.connectionIds.reduce((selection, connId) => {
        selection[connId] = true;
        return selection;
      }, {});
    },

    selectionOptions() {
      const selected = this.selected;
      return flatMap(
        Object.entries<{ connections: BookableConnection[] }>(
          this.bookableByLocation,
        ),
        ([locId, { connections }]) =>
          connections
            .map(c => {
              const from = this.locationNames[locId];
              const to = this.locationNames[
                c.localities.filter(l => l !== locId)[0]
              ];
              return {
                id: c.id,
                key: c.id + '::' + from + '-' + to,
                from,
                to,
                selected: !!selected[c.id],
              };
            })
            .filter(c => c.from && c.to),
      );
    },

    filteredOptions() {
      const str = this.nameFilter.toLowerCase().trim();

      const result = str
        ? this.selectionOptions.filter(o =>
            o.from
              .toLowerCase()
              .trim()
              .includes(str),
          )
        : this.selectionOptions;

      return result.sort((o1, o2) =>
        this.reverseSort
          ? o1.from.localeCompare(o2.from) || o1.to.localeCompare(o2.to)
          : o2.from.localeCompare(o1.from) || o2.to.localeCompare(o1.to),
      );
    },
  },

  methods: {
    addIdToSelection(id: string) {
      const s = new Set(this.connectionIds);
      s.add(id);
      this.$emit('change', Array.from(s));
    },

    removeIdFromSelection(removeId: string) {
      this.$emit('change', this.connectionIds.filter(id => id !== removeId));
    },

    toggleConnectionSort() {
      this.reverseSort = !this.reverseSort;
    },
  },
});

// helpers

function permutatePairs<T>(entities: T[]): [T, T][] {
  let res: [T, T][] = [];
  for (let i = 0; i < entities.length; i++) {
    for (let j = i + 1; j < entities.length; j++) {
      res.push([entities[i], entities[j]]);
    }
  }

  return res;
}
