import { NEWS as ACTIONS, API } from 'store/actions';
import { NEWS as MUTATIONS } from 'store/mutations';
import { NEWS as GETTERS } from 'store/getters';
import { defaultValueOnHttpError } from 'service/utils';
import {
  defaultItemState,
  defaultItemActions,
  defaultItemMutations,
} from 'store/commons/item-list';
import {
  defaultSearchableState,
  defaultSearchableActions,
  defaultSearchableMutations,
} from 'store/commons/searchable';

const state = {
  ...defaultItemState(),
  ...defaultSearchableState(),
};

const getters = {
  // newsById: state => listToIdMap(state.list),

  [GETTERS.FILTERED_LIST]: (state, getters) => {
    const filtered: any[] = [];

    for (const news of state.list) {
      const newsName = news.title ? news.title.toLowerCase() : '';
      // const country = news.address.country ? news.address.country.toLowerCase() : '';

      if (newsName.indexOf(state.searchString) > -1) {
        filtered.push(news);
      }
    }

    return filtered;
  },
};

const actions = {
  ...defaultItemActions(ACTIONS, MUTATIONS),
  ...defaultSearchableActions(ACTIONS, MUTATIONS),

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, 'misc/news')
      .then(response => {
        return response.newsList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, 'misc/news/' + uid).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_UPDATE]({ dispatch }, news) {
    return dispatch(API.PUT, {
      path: 'misc/news/' + news.uid,
      body: news,
    });
  },

  [ACTIONS.API_CREATE]({ dispatch }, newItem) {
    return dispatch(API.POST, { path: 'misc/news/create' }).then(response => {
      return dispatch(API.PUT, {
        path: 'misc/news/' + response.uid + '/init',
        body: newItem,
      }).then(() => response);
    });
  },

  [ACTIONS.API_DELETE_BY_ID]({ dispatch }, uid) {
    return dispatch(API.DELETE, 'misc/news/' + uid);
  },
};

const mutations = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSearchableMutations(MUTATIONS),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
