import {
  Company,
  CompanyHackType,
  CompanyType,
  Entity,
  User,
  UserHackRole,
  UserRole,
} from './data-types';

/**
 * Hack to identify booking provider without proper user role and company type
 * TODO: Remove after creating proper booking provider in API
 * @deprecated
 */
export function isUserBookingProvider(
  user: User | null,
  companiesById: { [id: string]: Company },
): boolean {
  return !!(
    user &&
    user.userRole.includes(UserRole.WAGON_LOAD) &&
    user.company
      .map(c => c.uid && companiesById[c.uid])
      .filter(Boolean)
      .find(comp => {
        const c = comp as Company;
        return isCompanyBookingProvider(c);
      })
  );
}

/**
 * Hack to identify booking provider without proper user role and company type
 * TODO: Remove after creating proper booking provider in API
 * @deprecated
 */
export function makeUserIntoHackBookingProvider(user: User): User {
  const userRole = user.userRole
    .filter(
      r =>
        r !== UserRole.WAGON_LOAD &&
        r !== (UserHackRole.BOOKING_PROVIDER as any),
    )
    .concat([UserRole.WAGON_LOAD]);
  return {
    ...user,
    userRole,
  };
}

/**
 * Hack to identify booking provider without proper user role and company type
 * TODO: Remove after creating proper booking provider in API
 * @deprecated
 */
export function isCompanyBookingProvider(company: Company): boolean {
  return !!(
    company.companyType.includes(CompanyType.WAGON_LOAD) &&
    company.isBookingProvider
  );
}

/**
 * Hack to identify booking provider without proper user role and company type
 * TODO: Remove after creating proper booking provider in API
 * @deprecated
 */
export function makeCompanyIntoHackBookingProvider(company: Company): Company {
  const companyType = company.companyType
    .filter(
      t =>
        t !== CompanyType.WAGON_LOAD &&
        t !== (CompanyHackType.BOOKING_PROVIDER as any),
    )
    .concat([CompanyType.WAGON_LOAD]);

  return {
    ...company,
    companyType,
    isBookingProvider: true,
    bookableConnections: [],
  };
}

export function bookableConnectionId(locations: [Entity, Entity]): string {
  return locations
    .map(loc => loc.uid)
    .sort()
    .join('::');
}
