import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { LOADING_POINT } from 'store/actions';
import { AUTHENTICATION as AUTHENTICATION_GETTERS } from 'store/getters';

export default {
  // Data fetching here if necessary
  created() {
    this.$store.dispatch(LOADING_POINT.LOAD_COMPANY_TYPES);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      typeList: [],
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    currentStoreData() {
      return this.$store.state.loadingPoints.companyTypes;
    },

    currentUser() {
      return this.$store.getters[AUTHENTICATION_GETTERS.CURRENT_USER];
    },

    isClean() {
      return isEqual(this.typeList, this.currentStoreData);
    },
  },

  mounted() {
    this.resetData();
  },

  watch: {
    currentStoreData: 'resetData',
  },

  // methods that are available in the component or in templates
  methods: {
    resetData() {
      this.typeList = cloneDeep(this.currentStoreData);
    },

    deleteType(index) {
      this.typeList.splice(index, 1);
    },

    addType() {
      this.typeList.push({ id: '', label: '', color: '' });
    },

    submitTypes() {
      if (this.currentUser.isSuperUser && !this.isClean) {
        this.$store.dispatch(LOADING_POINT.SAVE_COMPANY_TYPES, this.typeList);
      }
    },
  },
};
