// import { mapState } from "vuex";
import { MISC } from 'store/actions';
import Vue from 'vue';
import { Imprint } from 'kv_shared/lib/data-types';
import { merge } from 'service/utils';
import isEqual from 'lodash/isEqual';

export default {
  // add props here that are passed from parent templates or from router,
  // like item uid etc for data fetching.
  // do not mutate these, but use them as initial values for 'data'
  props: [],

  // Data fetching here if necessary
  created() {
    this.$store.dispatch(MISC.GET_IMPRINT);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      imprintData: new Imprint(),
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    initialImprintData() {
      return this.$store.state.misc.imprintData;
    },

    isClean() {
      if (this.initialImprintData) {
        const initial = merge(new Imprint(), this.initialImprintData);
        return isEqual(this.imprintData, initial);
      } else {
        return isEqual(this.imprintData, new Imprint());
      }
    },
  },

  // watch some data. Use with care, prefer computed properties if possible
  watch: {
    initialImprintData: 'resetData',
  },

  // methods that are available in the component or in templates
  methods: {
    resetData() {
      if (this.initialImprintData) {
        this.imprintData = merge(new Imprint(), this.initialImprintData);
      } else {
        this.imprintData = new Imprint();
      }
    },

    submitForm() {
      this.$validator
        .validateAll()
        .then(valid => {
          if (!valid) {
            console.log('form errors');
            return;
          }

          this.$store
            .dispatch(MISC.UPDATE_IMRPINT, this.imprintData)
            .catch(error => {
              // handle system specific errors
              console.error('unknown (not system specific) error', error);
            });
        })
        .catch(() => {
          console.log('form errors, saving failed');
        });
    },

    resetForm() {
      this.resetData();
      Vue.nextTick(() => {
        this.$errors.clear();
      });
    },
  },
};
