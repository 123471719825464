import { AUTHENTICATION } from 'store/actions';

export default {
  data() {
    return {
      loginData: {
        email: '',
        sed: '',
      },
      apiErrors: {
        wrongEmailOrPassword: false,
        unknownError: false,
      },
    };
  },

  computed: {
    hasApiErrors() {
      return this.apiErrors.wrongEmailOrPassword || this.apiErrors.unknownError;
    },
  },

  methods: {
    login() {
      this.$validator.validateAll().then(valid => {
        if (!valid) {
          console.log('form errors');
          return;
        }
        this.$store
          .dispatch(AUTHENTICATION.LOGIN, this.loginData)
          .then(() => {
            const user = this.$store.state.authentication.user;
            if (user.active === false) {
              this.$router.push({
                name: 'user.setpassword',
                params: {
                  uid: user.uid,
                },
              });
            } else {
              this.$router.push({ name: 'welcome' });
            }
          })

          .catch(error => {
            // handle system specific errors
            if (error.dataPromise) {
              error.dataPromise.then(data => {
                switch (data.S_notification.code) {
                  // wrong email/login
                  case 2103:
                  // wrong password
                  case 2104:
                    this.apiErrors.wrongEmailOrPassword = true;
                    break;
                  // not yet active
                  case 2109:
                    this.$router.push({
                      name: 'user.setpassword',
                      params: { email: this.loginData.email },
                    });
                    break;
                  default:
                    this.apiErrors.unknownError = true;
                    console.error('unhandled error code', data.S_notification);
                }
              });
            } else {
              console.error('unknown (not system specific) error', error);
            }
          });
      });
    },
  },
};
