function createDefaultItemMutations(prefix) {
  return {
    REPLACE_LIST: prefix + '_replace_list',
    UPDATE_LIST: prefix + '_update_list',
    REMOVE_FROM_LIST: prefix + '_remove_from_list',
    SET_CURRENT: prefix + '_set_current',
  };
}

export const GLOBAL = {
  SET_CONFIG: 'global_set_api_config',
  SET_LANGUAGE: 'global_set_language',
  SET_AVAILABLE_LANGUAGES: 'global_set_available_languages',
};

export const UI = {
  SET_LOADER: 'ui_set_loader',
  SET_MENU_OPEN: 'ui_set_menu_open',
  SET_MODAL: 'ui_set_modal',
};

export const AUTHENTICATION = {
  SET_USER: 'authentication_set_user',
  SET_AUTH: 'authentication_set_auth',
  RESET: 'authentication_reset',
  SET_REAUTH_REQUEST: 'authentication_set_reauth_request',
};

export const API = {
  SET_URL: 'api_set_url',
};

export const MISC = {
  SET_IMPRINT: 'set_imprint',
};

export const USER = {
  ...createDefaultItemMutations('user'),
  SET_SEARCH_STRING: 'user_set_search_string',
};

export const COMPANY = {
  ...createDefaultItemMutations('company'),
  SET_CONFIG_BY_TYPE: 'company_set_config_by_type',
  SET_SEARCH_STRING: 'company_set_search_string',
};

export const LOCATION = {
  ...createDefaultItemMutations('location'),
  SET_SEARCH_STRING: 'location_set_search_string',
};

export const COUNTRY = {
  ...createDefaultItemMutations('country'),
};

export const CONNECTION = {
  ...createDefaultItemMutations('connection'),
  SET_SELECTED_CONNECTION: 'connection_set_selected',
  SET_SEARCH_STRING: 'connection_set_search_string',
};

export const TRAIN = {
  ...createDefaultItemMutations('train'),
  SET_SELECTED_TRAIN: 'train_set_selected',
  SET_SEARCH_STRING: 'train_set_search_string',
};

export const TERMINAL = {
  ...createDefaultItemMutations('terminal'),
  SET_SEARCH_STRING: 'terminal_set_search_string',
};

export const LOADING_POINT = {
  ...createDefaultItemMutations('loadingpoint'),
  REPLACE_COMPANY_TYPES: 'loadingpoint_replace_company_types',
  SET_SEARCH_STRING: 'loadingpoint_set_search_string',
};

export const WAGON_LOAD = {
  ...createDefaultItemMutations('wagonload'),
  SET_SEARCH_STRING: 'wagonload_set_search_string',
};

export const CONVEYANCE = {
  ...createDefaultItemMutations('conveyance'),
  SET_SEARCH_STRING: 'conveyance_set_search_string',
  SET_POSITION_RESULTS: 'conveyance_set_position_results',
};

export const NEWS = {
  ...createDefaultItemMutations('news'),
  SET_SEARCH_STRING: 'news_set_search_string',
};
