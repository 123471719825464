import { UI as MUTATIONS } from 'store/mutations';
import { UI as ACTIONS } from 'store/actions';

const state = {
  loaderCount: 1,
  showLoader: true,
  openMenu: false,
  modal: {
    open: false,
    confirm: false,
    message: '',
    resolve: null,
    closeLabel: '',
    confirmLabel: '',
  },
};

const actions = {
  [ACTIONS.HIDE_LOADER]({ commit }) {
    commit(MUTATIONS.SET_LOADER, false);
  },

  [ACTIONS.SHOW_LOADER]({ commit }) {
    commit(MUTATIONS.SET_LOADER, true);
  },

  [ACTIONS.OPEN_MENU]({ commit }) {
    commit(MUTATIONS.SET_MENU_OPEN, true);
  },

  [ACTIONS.CLOSE_MENU]({ commit }) {
    commit(MUTATIONS.SET_MENU_OPEN, false);
  },

  [ACTIONS.TOGGLE_MENU]({ commit, state }) {
    commit(MUTATIONS.SET_MENU_OPEN, !state.openMenu);
  },

  [ACTIONS.SHOW_CONFIRM_MODAL]({ commit }, modalConfig) {
    const { message = '', confirm = '', close = '' } = modalConfig;

    return new Promise(resolve => {
      commit(MUTATIONS.SET_MODAL, {
        open: true,
        confirm: true,
        message,
        confirmLabel: confirm,
        closeLabel: close,
        resolve,
      });
    });
  },

  [ACTIONS.SHOW_INFORM_MODAL]({ commit }, modalConfig) {
    const { message = '', close = '' } = modalConfig;

    return new Promise(resolve => {
      commit(MUTATIONS.SET_MODAL, {
        open: true,
        confirm: false,
        message,
        closeLabel: close,
        confirmLabel: '',
        resolve,
      });
    });
  },

  [ACTIONS.CLOSE_MODAL]({ state, commit }, answer) {
    if (typeof state.modal.resolve === 'function') {
      state.modal.resolve(answer || {});
    }

    commit(MUTATIONS.SET_MODAL, {
      open: false,
      confirm: false,
      message: '',
      closeLabel: '',
      confirmLabel: '',
      resolve: null,
    });
  },
};

const mutations = {
  [MUTATIONS.SET_LOADER](state, isShowing) {
    if (isShowing) {
      state.loaderCount += 1;
    } else {
      state.loaderCount -= 1;
    }
    state.showLoader = state.loaderCount > 0;
  },

  [MUTATIONS.SET_MENU_OPEN](state, isOpen) {
    state.openMenu = isOpen;
  },

  [MUTATIONS.SET_MODAL](state, modalConfig) {
    state.modal = modalConfig;
  },
};

export default { state, mutations, actions };
