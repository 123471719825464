import Vue from 'vue';
import { editComponent } from 'mixins/edit-component';
import { LOADING_POINT } from 'store/actions';
import times from 'lodash/times';
import { RailwaySiding, Option } from 'kv_shared/lib/data-types';

export default (Vue as any).extend({
  mixins: [editComponent(RailwaySiding, state => state.loadingPoints.current)],

  // Data fetching
  created() {
    this.$store.dispatch(LOADING_POINT.LOAD_CURRENT_ITEM, this.uid);
  },

  // computed properties. Add vuex store data here
  computed: {
    interchangesQuantity: {
      get() {
        return (this.itemData || new RailwaySiding()).features.interchanges
          .tracks.length;
      },
      set(count) {
        if (this.itemData) {
          this.itemData.features.interchanges.tracks = times(
            count,
            i => this.itemData.features.interchanges.tracks[i] || '',
          );
        }
      },
    },

    otherMachinesQuantity: {
      get() {
        return (this.itemData || new RailwaySiding()).features.machines.others
          .length;
      },
      set(count) {
        if (this.itemData) {
          this.itemData.features.machines.others = times(
            count,
            i => this.itemData.features.machines.others[i] || '',
          );
        }
      },
    },
  },

  methods: {
    resetValue(object, prop) {
      (object[prop] as Option) = 'null';
    },

    getRange(num) {
      const nums: number[] = [];
      for (let i = 0; i <= num; i++) {
        nums.push(i);
      }
      return nums.map(n => n.toString());
    },

    submitCallback() {
      this.$store.dispatch(LOADING_POINT.SAVE_ITEM, this.itemData).catch(e => {
        alert(
          this.$t('Beim Speichern des Terminals ist ein Fehler aufgetreten.'),
        );
        console.error('item save error', e);
      });
    },
  },
});
