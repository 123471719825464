import Vue from 'vue';
import { editComponent } from 'mixins/edit-component';
import { TERMINAL } from 'store/actions';
import times from 'lodash/times';
import take from 'lodash/take';
import { Terminal } from 'kv_shared/lib/data-types';
import { merge } from 'service/utils';

function makeAccomplishment() {
  return {
    name: '',
    distance: '',
  };
}

export default (Vue as any).extend({
  mixins: [editComponent(Terminal, state => state.terminals.current)],

  // Data fetching
  created() {
    this.$store.dispatch(TERMINAL.LOAD_CURRENT_ITEM, this.uid);
  },

  // computed properties. Add vuex store data here
  computed: {
    furtherAccomplishmentsQuantity: {
      get() {
        return (this.itemData || new Terminal()).features.accomplishments
          .furtherAccomplishmentsList.length;
      },
      set(count) {
        if (this.itemData) {
          this.itemData.features.accomplishments.furtherAccomplishmentsList = take(
            merge(
              times(count, makeAccomplishment),
              this.itemData.features.accomplishments.furtherAccomplishmentsList,
            ),
            count,
          );
        }
      },
    },

    interchangesQuantity: {
      get() {
        return (this.itemData || new Terminal()).features.interchanges.length;
      },
      set(count) {
        if (this.itemData) {
          this.itemData.features.interchanges = times(
            count,
            i => this.itemData.features.interchanges[i] || '',
          );
        }
      },
    },

    mobileInterchangeMachinesQuantity: {
      get() {
        return (this.itemData || new Terminal()).features
          .mobileInterchangeMachines.length;
      },
      set(count) {
        if (this.itemData) {
          this.itemData.features.mobileInterchangeMachines = times(
            count,
            i => this.itemData.features.mobileInterchangeMachines[i] || '',
          );
        }
      },
    },

    furtherUnitsQuantity: {
      get() {
        return (this.itemData || new Terminal()).features.usableUnits
          .furtherUnits.length;
      },
      set(count) {
        if (this.itemData) {
          this.itemData.features.usableUnits.furtherUnits = times(
            count,
            i => this.itemData.features.usableUnits.furtherUnits[i] || '',
          );
        }
      },
    },

    hydrogenProvider: {
      get() {
        return (this.itemData || new Terminal()).features.hydrogen.isProvider;
      },
      set(value) {
        if (this.itemData) {
          this.itemData.features.hydrogen.isProvider = value;
          if (value) {
            this.itemData.features.hydrogen.isHandling = false;
          }
        }
      },
    },

    hydrogenHandling: {
      get() {
        return (this.itemData || new Terminal()).features.hydrogen.isHandling;
      },
      set(value) {
        if (this.itemData) {
          this.itemData.features.hydrogen.isHandling = value;
          if (value) {
            this.itemData.features.hydrogen.isProvider = false;
          }
        }
      },
    },
  },

  watch: {
    itemData: {
      deep: true,
      handler() {
        const f = this.itemData && this.itemData.features;
        if (f) {
          if (
            !f.accomplishments.furtherAccomplishments &&
            f.accomplishments.furtherAccomplishmentsList.length
          ) {
            f.accomplishments.furtherAccomplishmentsList = [];
          }
          if (!f.interchangesAvailable && f.interchanges.length) {
            f.interchanges = [];
          }
          if (
            !f.mobileInterchangeMachinesAvailable &&
            f.mobileInterchangeMachines.length
          ) {
            f.mobileInterchangeMachines = [];
          }
          if (
            !f.usableUnits.furtherUnitsAvailable &&
            f.usableUnits.furtherUnits.length
          ) {
            f.usableUnits.furtherUnits = [];
          }
        }
      },
    },
  },

  methods: {
    getRange(num) {
      const nums: number[] = [];
      for (let i = 1; i <= num; i++) {
        nums.push(i);
      }
      return nums.map(n => n.toString());
    },

    submitCallback() {
      this.$store.dispatch(TERMINAL.SAVE_ITEM, this.itemData).catch(e => {
        alert(
          this.$t('Beim Speichern des Terminals ist ein Fehler aufgetreten.'),
        );
        console.error('item save error', e);
      });
    },
  },
});
