import { app, store } from './app';
import { GLOBAL, UI, AUTHENTICATION } from 'store/actions';
import * as MUTATIONS from 'store/mutations';
import { default as i18n } from 'kv_shared/lib/vue/i18n';
import { availableLanguages } from 'kv_shared/lib/utils/localization';

// Fetch local config and add it to the store
fetch('config.local.json', { credentials: 'include' })
  .then(response => response.json())
  .then((config: any) => {
    store.dispatch(GLOBAL.ADD_CONFIG, config);
  })
  .catch(e => {
    console.error('Could not load config! System will be unusable.', e);
  })
  .then(() => {
    store.dispatch(UI.HIDE_LOADER);
    app.$mount('#kv_editor');
  });

store.commit(MUTATIONS.GLOBAL.SET_AVAILABLE_LANGUAGES, availableLanguages);

store.watch(
  state => state.authentication.user || state.authentication.authData,
  () => store.dispatch(AUTHENTICATION.SAVE_LOCAL_SESSION),
);

store.watch(
  state => state.locale,
  value => {
    const lang = value.toLowerCase();
    if (store.state.availableLanguages.includes(lang)) {
      i18n.locale = value;
    }
  },
);

if (
  store.state.authentication.user &&
  store.state.authentication.user.language !== store.state.locale
) {
  store.dispatch(
    GLOBAL.CHANGE_LANGUAGE,
    store.state.authentication.user.language,
  );
}

// @TODO: Remove after testing reauth
// (window as any).reaut = () => store.dispatch(AUTHENTICATION.REAUTHENTICATE);

(window as any).store = store;
