import { mapGetters } from 'vuex';
import { COMPANY, UI, CONVEYANCE } from 'store/actions';
// tslint:disable-next-line:max-line-length
import {
  CONVEYANCE as CONVEYANCE_GETTERS,
  COMPANY as COMPANY_GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
} from 'store/getters';
import { sortListBy } from 'service/utils';

export default {
  // add props here that are passed from parent templates or from router,
  // like item uid etc for data fetching.
  // do not mutate these, but use them as initial values for 'data'
  props: [],

  // Data fetching here if necessary
  created() {
    this.$store.dispatch(COMPANY.LOAD_LIST);
    this.$store.dispatch(CONVEYANCE.LOAD_LIST);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      orderBy: 'name',
      reverse: false,
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapGetters({
      forwarders: COMPANY_GETTERS.FORWARDERS,
      filteredForwarders: CONVEYANCE_GETTERS.FILTERED_FORWARDER_LIST,
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
    }),

    filteredItems() {
      const filtered = [...this.filteredForwarders];
      if (this.orderBy === 'companyTypeName') {
        sortListBy(filtered, 'companyTypeName', 'name');
      } else {
        sortListBy(filtered, this.orderBy);
      }
      if (this.reverse) {
        filtered.reverse();
      }
      return filtered;
    },

    searchFilter: {
      get() {
        return this.$store.state.conveyance.searchString;
      },
      set(value) {
        this.$store.dispatch(CONVEYANCE.FILTER_FORWARDERS, value);
      },
    },

    isSuperUser() {
      return this.currentUser.isSuperUser;
    },
  },

  // methods that are available in the component or in templates
  methods: {
    order(orderBy) {
      if (this.orderBy === orderBy) {
        this.reverse = !this.reverse;
      } else {
        this.orderBy = orderBy;
        this.reverse = false;
      }
    },

    recalculateAllAreas() {
      this.$store.dispatch(CONVEYANCE.RECALCULATE_ALL).then(() =>
        this.$store.dispatch(UI.SHOW_INFORM_MODAL, {
          message: this.$t('Die Portfolios wurden aktualisiert'),
          close: this.$t('Ok'),
        }),
      );
    },

    deleteCompany(item) {
      this.$store
        .dispatch(UI.SHOW_CONFIRM_MODAL, {
          message: this.$t(
            'Wollen Sie das Unternehmen {name} wirklich löschen?',
            { name: item.name },
          ),
          confirm: this.$t('Ja'),
          close: this.$t('Nein'),
        })
        .then(response => {
          if (response.confirmed) {
            this.$store.dispatch(COMPANY.REMOVE_ITEM, item);
          }
        });
    },
  },
};
