import { LocalizedMessage } from '../data-types';
import translations from '../../po/translations.json';

interface Translations {
  [key: string]: LocalizedMessage;
}

export const DEFAULT_LANGUAGE = 'en';

export const ORIGINAL_LANGUAGE = 'de';

export const cachedTranslations: Translations = {};

const foundLanguages: { [lang: string]: true } = {
  [ORIGINAL_LANGUAGE]: true,
};

function replaceUnicode(str: string) {
  return str.replace(/\\u(\d{4})/g, function() {
    return String.fromCharCode(parseInt(arguments[1], 16));
  });
}

// now fill translation cache
for (const key in translations) {
  const translation = translations[key];
  const id = replaceUnicode(key);

  const localeMessage: LocalizedMessage = {
    [ORIGINAL_LANGUAGE]: id,
  };

  cachedTranslations[id] = localeMessage;

  for (const lang in translation) {
    if (!foundLanguages[lang]) {
      foundLanguages[lang] = true;
    }

    let result = translation[lang];
    if (Array.isArray(result)) {
      // Pipe syntax for pluralisation, if array has more items
      // result = result.join(' | '); // used in vue-i18n which was removed

      // Ignore pluralisation for now
      result = result[0];
    }

    if (result) {
      localeMessage[lang] = replaceUnicode(result);
    }
  }
}

export const availableLanguages = Object.keys(foundLanguages).sort();

export function getTranslation(
  translations: LocalizedMessage | undefined,
  language: string,
  defaultMsg: string = '',
) {
  return (
    (translations &&
      (translations[language] ||
        translations[DEFAULT_LANGUAGE] ||
        translations[ORIGINAL_LANGUAGE])) ||
    defaultMsg
  );
}
