// import { mapGetters, mapState } from "vuex";
import { TERMINAL } from 'store/actions';
import { COMPANY as COMPANY_GETTERS } from 'store/getters';
import { normalizeUrl } from 'service/utils';
import { editComponent } from 'mixins/edit-component';
import { editPlaceComponent } from 'mixins/edit-place-component';
import Vue from 'vue';
import { TIME_REGEX } from 'service/constants';
import { Terminal } from 'kv_shared/lib/data-types';

export default (Vue as any).extend({
  mixins: [
    editComponent(Terminal, state => state.terminals.current),
    editPlaceComponent(COMPANY_GETTERS.TERMINAL_CARRIERS),
  ],

  created() {
    this.$store.dispatch(TERMINAL.LOAD_CURRENT_ITEM, this.uid);
  },

  data() {
    return {
      timeRegex: TIME_REGEX,
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    days() {
      return {
        monday: {
          label: this.$t('Montag'),
        },
        tuesday: {
          label: this.$t('Dienstag'),
        },
        wednesday: {
          label: this.$t('Mittwoch'),
        },
        thursday: {
          label: this.$t('Donnerstag'),
        },
        friday: {
          label: this.$t('Freitag'),
        },
        saturday: {
          label: this.$t('Samstag'),
        },
        sunday: {
          label: this.$t('Sonntag'),
        },
      };
    },
  },

  // watch some data. Use with care, prefer computed properties if possible
  // watch : {
  //   someDataKey: 'someMethod',
  //   otherDataKey () {...},
  // },

  // methods that are available in the component or in templates
  methods: {
    normalizeUrl() {
      this.itemData.contact.url = normalizeUrl(this.itemData.contact.url);
    },

    checkAndSetBHCheckboxes(checkValue, index) {
      if (checkValue === false) {
        this.itemData.businessHours[index].allDay = false;
        this.itemData.businessHours[index].begin = '';
        this.itemData.businessHours[index].end = '';
      }
    },

    submitCallback() {
      return this.$store
        .dispatch(TERMINAL.SAVE_ITEM, this.itemData)
        .catch(e => {
          alert(
            this.$t('Beim Ändern des Terminals ist ein Fehler aufgetreten.'),
          );
          console.error('item save error', e);
        });
    },
  },
});
