function createDefaultItemActions(prefix: string) {
  return {
    LOAD_LIST: prefix + '_load_list',
    LOAD_CURRENT_ITEM: prefix + '_load_current_by_id',
    RESET_CURRENT: prefix + '_reset_current',
    REMOVE_ITEM: prefix + '_remove_item',
    SAVE_ITEM: prefix + '_update_item',
    SET_ITEM: prefix + '_set_item',

    API_CREATE: prefix + '_api_create',
    API_UPDATE: prefix + '_api_update',
    API_GET_LIST: prefix + '_api_get_list',
    API_GET_BY_ID: prefix + '_api_get_by_id',
    API_DELETE_BY_ID: prefix + '_api_delete_by_id',
  };
}

function createDefaultFileAttachmentActions(prefix: string) {
  return {
    REMOVE_DOCUMENT: prefix + '_delete_document',
    ADD_DOCUMENT: prefix + '_add_document',

    API_UPLOAD_DOCUMENT: prefix + '_api_upload_document',
    API_DELETE_DOCUMENT: prefix + '_api_delete_document',
  };
}

export const GLOBAL = {
  ADD_CONFIG: 'global_add_api_config',
  CHANGE_LANGUAGE: 'global_change_language',
};

export const UI = {
  SHOW_LOADER: 'ui_show_loader',
  HIDE_LOADER: 'ui_hide_loader',

  OPEN_MENU: 'ui_open_menu',
  CLOSE_MENU: 'ui_close_menu',
  TOGGLE_MENU: 'ui_toggle_menu',

  SHOW_CONFIRM_MODAL: 'ui_show_confirm_modal',
  SHOW_INFORM_MODAL: 'ui_show_inform_modal',
  CLOSE_MODAL: 'ui_close_modal',
};

export const AUTHENTICATION = {
  LOGIN: 'authentication_login',
  LOGOUT: 'authentication_logout',
  RESET_PASSWORD: 'authentication_reset_password',
  NEW_PASSWORD: 'authentication_new_password',
  SAVE_LOCAL_SESSION: 'authentication_save_local',
  REAUTHENTICATE: 'authentication_reauthenticate',
};

export const API = {
  GET: 'api_get',
  POST: 'api_post',
  PUT: 'api_put',
  DELETE: 'api_delete',
  UPLOAD: 'api_upload',
};

export const MISC = {
  GET_IMPRINT: 'misc_get_imprint',
  UPDATE_IMRPINT: 'misc_update_imprint',
};

export const USER = {
  ...createDefaultItemActions('user'),
  FILTER_LIST: 'user_filter_list',
  UPDATE_CONTAINER_ZUG_COMPANIES: 'user_update_container_zug_companies',
};

export const LOCATION = {
  ...createDefaultItemActions('location'),
  FILTER_LIST: 'location_filter_list',
};

export const COUNTRY = {
  ...createDefaultItemActions('country'),
};

export const COMPANY = {
  ...createDefaultItemActions('company'),
  GET_CONFIG_BY_TYPE: 'company_get_config_by_type',
  FILTER_LIST: 'company_filter_list',

  API_GET_CONFIG_BY_TYPE: 'company_api_get_config_by_type',
  API_SAVE_CONFIG_BY_TYPE: 'company_api_save_config_by_type',
};

export const CONNECTION = {
  ...createDefaultItemActions('connection'),
  FILTER_LIST: 'connection_filter_list',
  SELECT_CONNECTION: 'connection_select_item',
};

export const TRAIN = {
  ...createDefaultItemActions('train'),
  FILTER_LIST: 'train_filter_list',
  SELECT_TRAIN: 'train_select_item',
  COPY_USABLE_UNITS: 'train_copy_usable_units',
};

export const LOADING_POINT = {
  ...createDefaultItemActions('loadingpoint'),
  ...createDefaultFileAttachmentActions('loadingpoint'),
  LOAD_COMPANY_TYPES: 'loadingpoint_load_company_types',
  UPDATE_COMPANY_TYPES: 'loadingpoint_update_company_types',
  FILTER_LIST: 'loadingpoint_filter_list',
  SAVE_COMPANY_TYPES: 'loadingpoint_save_company_type',

  API_GET_COMPANY_TYPES: 'loadingpoint_api_get_company_types',
  API_UPDATE_COMPANY_TYPES: 'loadingpoint_api_update_company_types',
};

export const WAGON_LOAD = {
  ...createDefaultItemActions('wagonload'),
  FILTER_LIST: 'wagonload_filter_list',
};

export const TERMINAL = {
  ...createDefaultItemActions('terminal'),
  ...createDefaultFileAttachmentActions('terminal'),
  FILTER_LIST: 'terminal_filter_list',
};

export const NEWS = {
  ...createDefaultItemActions('news'),
  FILTER_LIST: 'news_filter_list',
};

export const CONVEYANCE = {
  ...createDefaultItemActions('conveyance'),
  FILTER_FORWARDERS: 'conveyance_filter_forwarders',
  SEARCH_GEO_POSITIONS: 'conveyance_search_geo_positions',
  API_GET_POSITIONS: 'conveyance_api_get_positions',
  CALCULATE_LOCATIONS: 'conveyance_calculate_locations',
  RECALCULATE_ALL: 'conveyance_recalculate_all',
};
