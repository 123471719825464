import Vue from 'vue';
import VeeValidate from 'vee-validate';

const config = {
  errorBagName: '$errors', // change if property conflicts.
  fieldsBagName: '$fields',
  delay: 300,
  locale: 'en',
  dictionary: null,
  strict: true,
  enableAutoClasses: false,
  classNames: {
    touched: 'touched', // the control has been blurred
    untouched: 'untouched', // the control hasn't been blurred
    valid: 'valid', // model is valid
    invalid: 'error', // model is invalid
    pristine: 'pristine', // control has not been interacted with
    dirty: 'dirty', // control has been interacted with
  },
};

Vue.use(VeeValidate, config as any);
