// import { mapGetters, mapState } from "vuex";
import { TRAIN } from 'store/actions';
import { weekDaysMini } from 'service/moment-service';
import cloneDeep from 'lodash/cloneDeep';
import { DEFAULT_LANGUAGE } from 'kv_shared/lib/utils/localization';

class TrainCopy {
  // tslint:disable-next-line:semicolon
  name!: string;
  // tslint:disable-next-line:semicolon
  day!: number;
}

export default {
  // do not mutate these, but use them as initial values
  props: ['templateId'],

  // Data fetching
  created() {
    this.$store.dispatch(TRAIN.LOAD_CURRENT_ITEM, this.templateId);
  },

  // local component state
  data() {
    return {
      numberOfCopies: 0,
      trainCopies: [],
    };
  },

  // computed properties
  computed: {
    trainData() {
      return this.$store.state.trains.current;
    },

    currentLanguage() {
      return this.$store.state.locale;
    },

    weekdayMiniOptions() {
      return weekDaysMini.map(weekday => ({
        value: weekday.id,
        label:
          weekday.label[this.currentLanguage] ||
          weekday.label[DEFAULT_LANGUAGE],
      }));
    },
  },

  watch: {
    numberOfCopies: 'updateNumberOfCopies',
  },

  // methods that are available in the component or in templates
  methods: {
    updateNumberOfCopies() {
      const newCopies: TrainCopy[] = [];
      const name = this.trainData.name;
      const day = this.trainData.departure.day;

      for (let i = 0; i < this.numberOfCopies; i++) {
        const current = this.trainCopies[i];
        const copy = new TrainCopy();

        if (current && current.name) {
          copy.name = current.name;
        } else {
          copy.name = name + ' ' + (i + 1);
        }

        if (current && current.day) {
          copy.day = current.day;
        } else if (day) {
          copy.day = ((day - 1 + (i + 1)) % 7) + 1;
        }

        newCopies.push(copy);
      }

      this.trainCopies = newCopies;
    },

    getTimingDay(day) {
      return this.weekdayMiniOptions.find(weekday => weekday.value === day);
    },

    cancelEdit() {
      this.numberOfCopies = 0;
    },

    submit() {
      this.$validator.validateAll().then(valid => {
        if (!valid) {
          console.log('form errors');
          return;
        }

        Promise.all(
          this.trainCopies.map((train: TrainCopy) => {
            const newTrainData = cloneDeep(this.trainData);
            const daysDiff = (train.day - newTrainData.departure.day + 7) % 7;

            delete newTrainData.uid;
            newTrainData.name = train.name;
            newTrainData.departure.day = train.day;
            newTrainData.arrival.day =
              ((newTrainData.arrival.day + daysDiff - 1) % 7) + 1;
            newTrainData.endOfBooking.day =
              ((newTrainData.endOfBooking.day + daysDiff - 1) % 7) + 1;

            for (const terminalTiming of newTrainData.terminalTimings) {
              if (
                terminalTiming.delivery &&
                terminalTiming.delivery.day &&
                terminalTiming.delivery.day !== ''
              ) {
                terminalTiming.delivery.day =
                  ((terminalTiming.delivery.day + daysDiff - 1) % 7) + 1;
              }

              if (
                terminalTiming.collection &&
                terminalTiming.collection.day &&
                terminalTiming.collection.day !== ''
              ) {
                terminalTiming.collection.day =
                  ((terminalTiming.collection.day + daysDiff - 1) % 7) + 1;
              }
            }

            return this.$store.dispatch(TRAIN.SAVE_ITEM, newTrainData);
          }),
        )
          .then(() => this.cancelEdit())
          .catch(error => {
            console.error('error while creating train copies', error);
          });
      });
    },
  },
};
