import { mapGetters, mapState } from 'vuex';
import { LOCATION, COUNTRY, UI } from 'store/actions';
import {
  LOCATION as LOCATION_GETTERS,
  COUNTRY as COUNTRY_GETTERS,
} from 'store/getters';
import { Location } from 'kv_shared/lib/data-types';

export default {
  // add props here that are passed from parent templates or from router,
  // like item uid etc for data fetching.
  // do not mutate these, but use them as initial values for 'data'
  props: [],

  // Data fetching here if necessary
  created() {
    this.$store.dispatch(LOCATION.LOAD_LIST);
    this.$store.dispatch(COUNTRY.LOAD_LIST);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      orderBy: '',
      reverse: false,
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapState<any>({
      currentLanguage: state => state.locale,
    }),

    ...mapGetters({
      locations: LOCATION_GETTERS.FILTERED_LIST,
      names: LOCATION_GETTERS.LOCALE_NAMES,
      countries: COUNTRY_GETTERS.NAMES_BY_ID,
    }),

    filteredItems() {
      const filtered = [...this.locations] as Location[];

      const cityFilter = (l1, l2) => {
        const city1: string = this.names[l1.uid];
        const city2: string = this.names[l2.uid];
        return city1.localeCompare(city2);
      };

      if (this.orderBy === 'countryName') {
        filtered.sort((l1, l2) => {
          const country1 = this.countries[l1.country.uid as string];
          const country2 = this.countries[l2.country.uid as string];
          if (country1 && country2) {
            const result = country1.localeCompare(country2);
            if (result === 0) {
              return cityFilter(l1, l2);
            } else {
              return result;
            }
          }
          return 0;
        });
      } else {
        filtered.sort(cityFilter);
      }

      if (this.reverse) {
        filtered.reverse();
      }

      return filtered;
    },

    searchFilter: {
      get() {
        return this.$store.state.locations.searchString;
      },
      set(value) {
        this.$store.dispatch(LOCATION.FILTER_LIST, value);
      },
    },
  },

  // methods that are available in the component or in templates
  methods: {
    order(orderBy) {
      if (this.orderBy === orderBy) {
        this.reverse = !this.reverse;
      } else {
        this.orderBy = orderBy;
        this.reverse = false;
      }
    },

    deleteLocation(location) {
      this.$store
        .dispatch(UI.SHOW_CONFIRM_MODAL, {
          message: this.$t('Wollen Sie den Ort {name} wirklich löschen?', {
            name: location.name[this.currentLanguage] || location.name.locale,
          }),
          confirm: this.$t('Ja'),
          close: this.$t('Nein'),
        })
        .then(response => {
          if (response.confirmed) {
            this.$store.dispatch(LOCATION.REMOVE_ITEM, location);
            this.searchFilter = '';
          }
        });
    },
  },
};
