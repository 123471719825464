import { mapGetters } from 'vuex';
import { TERMINAL, COMPANY } from 'store/actions';
import {
  TERMINAL as TERMINAL_GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
} from 'store/getters';
import { sortListBy } from 'service/utils';

export default {
  // add props here that are passed from parent templates or from router,
  // like item uid etc for data fetching.
  // do not mutate these, but use them as initial values for 'data'
  props: [],

  // Data fetching here if necessary
  created() {
    this.$store.dispatch(TERMINAL.LOAD_LIST);
    this.$store.dispatch(COMPANY.LOAD_LIST);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      orderBy: 'name',
      reverse: false,
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapGetters({
      terminals: TERMINAL_GETTERS.FILTERED_LIST,
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
    }),

    filteredItems() {
      const filtered = [...this.terminals];
      if (this.orderBy === 'companyName') {
        sortListBy(filtered, 'companyName', 'name', 'active');
      } else {
        sortListBy(filtered, this.orderBy);
      }
      if (this.reverse) {
        filtered.reverse();
      }
      return filtered;
    },

    searchFilter: {
      get() {
        return this.$store.state.terminals.searchString;
      },
      set(value) {
        this.$store.dispatch(TERMINAL.FILTER_LIST, value);
      },
    },
  },

  // methods that are available in the component or in templates
  methods: {
    order(orderBy) {
      if (this.orderBy === orderBy) {
        this.reverse = !this.reverse;
      } else {
        this.orderBy = orderBy;
        this.reverse = false;
      }
    },

    delete(terminal) {
      // @todo
      console.log('delete item ', terminal.uid);
    },
  },
};
