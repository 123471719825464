var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b-operator-overview"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-offset-1 col-sm-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12"},[_c('h2',[_vm._v(_vm._s(_vm.$t('Überblick Verbindungen')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchFilterConnections),expression:"searchFilterConnections"}],staticClass:"filter",attrs:{"type":"text","placeholder":_vm.$t('Filter...')},domProps:{"value":(_vm.searchFilterConnections)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchFilterConnections=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"fixed-table-container connections"},[_c('div',{staticClass:"table-header"}),_vm._v(" "),_c('div',{staticClass:"fixed-table-container-inner"},[_c('table',{class:{ 'extended-view': _vm.isSuperUser }},[_c('thead',[_c('tr',[_c('th',[_c('div',{staticClass:"th-inner sortable",class:{
                          sorted: _vm.orderConnectionsBy == 'name',
                          reverse: _vm.reverseConnections,
                        },on:{"click":function($event){return _vm.orderConnection('name')}}},[_vm._v("\n                        "+_vm._s(_vm.$t('Name'))+"\n                      ")])]),_vm._v(" "),_c('th',[_c('div',{staticClass:"th-inner sortable",class:{
                          sorted: _vm.orderConnectionsBy == 'location',
                          reverse: _vm.reverseConnections,
                        },on:{"click":function($event){return _vm.orderConnection('location')}}},[_vm._v("\n                        "+_vm._s(_vm.$t('Orte'))+"\n                      ")])]),_vm._v(" "),_c('th',[(_vm.isSuperUser)?_c('div',{staticClass:"th-inner sortable",class:{
                          sorted: _vm.orderConnectionsBy == 'operator',
                          reverse: _vm.reverseConnections,
                        },on:{"click":function($event){return _vm.orderConnection('operator')}}},[_vm._v("\n                        "+_vm._s(_vm.$t('Operator'))+"\n                      ")]):_vm._e()])])]),_vm._v(" "),_c('tbody',[_vm._l((_vm.filteredConnectionItems),function(item){return _c('tr',{key:item.uid,class:{
                      active:
                        _vm.activeConnection && _vm.activeConnection.uid === item.uid,
                    },on:{"click":function($event){return _vm.toggleConnectionActiveState(item)}}},[_c('td',{attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('td',{attrs:{"title":item.locationNamesString}},[_vm._v("\n                      "+_vm._s(item.locationNamesString)+"\n                    ")]),_vm._v(" "),(_vm.isSuperUser)?_c('td',{attrs:{"title":item.operatorNameString}},[_vm._v("\n                      "+_vm._s(item.operatorNameString)+"\n                    ")]):_vm._e()])}),_vm._v(" "),(_vm.connectionList.length === 0)?_c('tr',{staticClass:"empty"},[_c('td',{attrs:{"colspan":"2"}},[_vm._v("\n                      "+_vm._s(_vm.$t('Keine Verbindung vorhanden.'))+"\n                    ")])]):_vm._e()],2)])])])])]),_vm._v(" "),_c('div',{staticClass:"row spacer tools"},[_c('div',{staticClass:"col-xs-12"},[_c('router-link',{staticClass:"icon add",attrs:{"to":{ name: 'connection.add' },"title":_vm.$t('Neue Verbindung anlegen')}},[_vm._v(_vm._s(_vm.$t('Neue Verbindung anlegen')))]),_vm._v(" "),_c('router-link',{staticClass:"button icon copy",class:{ disabled: !_vm.activeConnection },attrs:{"to":_vm.activeConnection && _vm.activeConnection.uid
                ? {
                    name: 'connection.copy',
                    params: {
                      templateId: _vm.activeConnection && _vm.activeConnection.uid,
                    },
                  }
                : '',"title":_vm.$t('Verbindung kopieren')}},[_vm._v(" ")]),_vm._v(" "),_c('router-link',{staticClass:"button icon edit",class:{ disabled: !_vm.activeConnection },attrs:{"to":_vm.activeConnection && _vm.activeConnection.uid
                ? {
                    name: 'connection.edit',
                    params: { uid: _vm.activeConnection && _vm.activeConnection.uid },
                  }
                : '',"title":_vm.$t('Verbindung bearbeiten')}},[_vm._v(" ")]),_vm._v(" "),_c('button',{staticClass:"button icon delete",attrs:{"type":"button","title":_vm.$t('Verbindung löschen'),"disabled":!_vm.activeConnection},on:{"click":function($event){return _vm.deleteConnection()}}},[_vm._v("\n             \n          ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-offset-1 col-sm-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12"},[_c('h2',[_vm._v(_vm._s(_vm.$t('Überblick Züge')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchFilterTrains),expression:"searchFilterTrains"}],staticClass:"filter",attrs:{"type":"text","placeholder":_vm.$t('Filter...')},domProps:{"value":(_vm.searchFilterTrains)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchFilterTrains=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"fixed-table-container"},[_c('div',{staticClass:"table-header"}),_vm._v(" "),_c('div',{staticClass:"fixed-table-container-inner"},[_c('table',{staticClass:"trains"},[_c('thead',[_c('tr',[_c('th',[_c('div',{staticClass:"th-inner sortable",class:{
                          sorted: _vm.orderTrainsBy == 'name',
                          reverse: _vm.reverseTrains,
                        },on:{"click":function($event){return _vm.orderTrains('name')}}},[_vm._v("\n                        "+_vm._s(_vm.$t('Name'))+"\n                      ")])]),_vm._v(" "),_c('th',[_c('div',{staticClass:"th-inner sortable",class:{
                          sorted: _vm.orderTrainsBy == 'stations',
                          reverse: _vm.reverseTrains,
                        },on:{"click":function($event){return _vm.orderTrains('stations')}}},[_vm._v("\n                        "+_vm._s(_vm.$t('Orte'))+"\n                      ")])]),_vm._v(" "),_c('th',[_c('div',{staticClass:"th-inner sortable",class:{
                          sorted: _vm.orderTrainsBy == 'departure',
                          reverse: _vm.reverseTrains,
                        },on:{"click":function($event){return _vm.orderTrains('departure')}}},[_vm._v("\n                        "+_vm._s(_vm.$t('Abfahrt'))+"\n                      ")])])])]),_vm._v(" "),_c('tbody',[_vm._l((_vm.filteredTrainItems),function(item){return _c('tr',{key:item.uid,class:{
                      active: _vm.activeTrain && _vm.activeTrain.uid === item.uid,
                    },on:{"click":function($event){return _vm.toggleTrainActiveState(item)}}},[_c('td',{attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('td',{attrs:{"title":item.locationNamesString}},[_vm._v("\n                      "+_vm._s(item.locationNamesString)+"\n                    ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.departureTime))])])}),_vm._v(" "),(_vm.trainList.length === 0 && !_vm.activeConnection)?_c('tr',{staticClass:"empty"},[_c('td',{attrs:{"colspan":"3"}},[_vm._v("\n                      "+_vm._s(_vm.$t('Bitte eine Verbindung auswählen.'))+"\n                    ")])]):_vm._e(),_vm._v(" "),(_vm.trainList.length === 0 && _vm.activeConnection)?_c('tr',{staticClass:"empty"},[_c('td',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.$t('Kein Zug vorhanden.')))])]):_vm._e()],2)])])])])]),_vm._v(" "),_c('div',{staticClass:"row spacer tools"},[_c('div',{staticClass:"col-xs-12"},[(_vm.activeConnection && _vm.activeConnection.uid)?_c('router-link',{staticClass:"icon add",attrs:{"to":{
              name: 'train.add',
              params: {
                connectionId: _vm.activeConnection && _vm.activeConnection.uid,
              },
            },"title":_vm.$t('Neuen Zug anlegen')}},[_vm._v(_vm._s(_vm.$t('Neuen Zug anlegen')))]):_vm._e(),_vm._v(" "),_c('router-link',{staticClass:"button icon copy",class:{ disabled: !_vm.activeTrain },attrs:{"to":{
              name: 'train.copy',
              params: { templateId: _vm.activeTrain && _vm.activeTrain.uid },
            },"title":_vm.$t('Zug kopieren'),"disabled":!_vm.activeTrain}},[_vm._v(" ")]),_vm._v(" "),_c('router-link',{staticClass:"button icon copy-multi",class:{ disabled: !_vm.activeTrain },attrs:{"to":{
              name: 'train.multicopy',
              params: { templateId: _vm.activeTrain && _vm.activeTrain.uid },
            },"title":_vm.$t('Zug mehrfach kopieren'),"disabled":!_vm.activeTrain}},[_vm._v(" ")]),_vm._v(" "),_c('button',{staticClass:"button icon order",attrs:{"type":"button","title":_vm.$t('Übertragung Ladeformen'),"disabled":!_vm.copyUsableUnitsAvailable},on:{"click":function($event){return _vm.copyUsableUnits()}}},[_vm._v("\n             \n          ")]),_vm._v(" "),_c('router-link',{staticClass:"button icon edit",class:{ disabled: !_vm.activeTrain },attrs:{"to":{
              name: 'train.edit',
              params: { uid: _vm.activeTrain && _vm.activeTrain.uid },
            },"title":_vm.$t('Zug bearbeiten')}},[_vm._v(" ")]),_vm._v(" "),_c('button',{staticClass:"button icon delete",attrs:{"type":"button","title":_vm.$t('Zug löschen'),"disabled":!_vm.activeTrain || _vm.trainList.length === 0},on:{"click":function($event){return _vm.deleteTrain()}}},[_vm._v("\n             \n          ")])],1)])])]),_vm._v(" "),_c('div',{staticClass:"row back"},[_c('div',{staticClass:"col-xs-1 col-sm-1"},[_c('router-link',{staticClass:"icon back",attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t('Zurück')))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }