import { TRAIN as ACTIONS, API } from 'store/actions';
import { TRAIN as MUTATIONS } from 'store/mutations';
import {
  TRAIN as GETTERS,
  TERMINAL as TERMINAL_GETTERS,
  LOCATION as LOCATION_GETTERS,
} from 'store/getters';
import { listToIdMap, defaultValueOnHttpError } from 'service/utils';
import { weekDaysMini } from 'service/moment-service';
import {
  defaultItemActions,
  defaultItemMutations,
  defaultItemState,
} from 'store/commons/item-list';
import {
  defaultSearchableState,
  defaultSearchableActions,
  defaultSearchableMutations,
} from 'store/commons/searchable';
import uniq from 'lodash/uniq';
import { DEFAULT_LANGUAGE } from 'kv_shared/lib/utils/localization';

const state = {
  ...defaultItemState(),
  ...defaultSearchableState(),
};

const getters = {
  [GETTERS.BY_ID]: state => listToIdMap(state.list),

  [GETTERS.OF_CURRENT_CONNECTION]: (state, getters, rootState) => {
    return state.list.filter(
      train =>
        train.connection.uid &&
        rootState.connections.current &&
        train.connection.uid === rootState.connections.current.uid,
    );
  },

  [GETTERS.FILTERED_LIST]: (state, getters, rootState) => {
    const filtered: any[] = [];
    const terminalList = getters[TERMINAL_GETTERS.BY_ID];
    const locationNamesList = getters[LOCATION_GETTERS.LOCALE_NAMES];

    // Trains are implicetly filtered by the currently selected connection
    for (const train of getters[GETTERS.OF_CURRENT_CONNECTION]) {
      const locationNames: any[] = [];
      let departureTime = '';

      // Location Names
      for (const trainTerminal of train.terminal) {
        const terminalUid = trainTerminal.uid;
        const terminal = terminalList[terminalUid];

        if (terminal) {
          const name = locationNamesList[terminal.locality.uid];
          locationNames.push(name);
        }
      }

      const locationNamesString = uniq(locationNames).join(' - ');

      // Departure Time
      if (train.departure.day) {
        const dayStr =
          weekDaysMini[(train.departure.day - 1) % 7].label[rootState.locale] ||
          weekDaysMini[(train.departure.day - 1) % 7].label[DEFAULT_LANGUAGE];
        const timeStr = train.departure.time;
        departureTime = String(dayStr + ' ' + timeStr);
      }

      if (train.name.toLowerCase().indexOf(state.searchString) > -1) {
        filtered.push(
          Object.freeze({
            ...train,
            locationNamesString,
            departureTime,
          }),
        );
      }
    }

    return filtered;
  },
};

const actions = {
  ...defaultItemActions(ACTIONS, MUTATIONS),
  ...defaultSearchableActions(ACTIONS, MUTATIONS),

  [ACTIONS.COPY_USABLE_UNITS]({ state, getters, dispatch, commit }) {
    const current = state.current;
    const trains = getters[GETTERS.OF_CURRENT_CONNECTION].filter(
      t => current && t.uid !== current.uid,
    );

    if (trains.length > 0) {
      return Promise.all(
        trains.map(train =>
          dispatch(ACTIONS.SAVE_ITEM, {
            ...train,
            usableUnits: current.usableUnits,
          }),
        ),
      ).then(() => commit(MUTATIONS.SET_CURRENT, current));
    }
  },

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }, connectionUid) {
    return dispatch(API.GET, 'schedule/train')
      .then(response => {
        return response.trainList || [];
      })
      .catch(error => {
        console.error('handleHttpError', error);
        return [];
      });
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, 'schedule/train/' + uid).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_DELETE_BY_ID]({ dispatch }, uid) {
    return dispatch(API.DELETE, 'schedule/train/' + uid);
  },

  [ACTIONS.API_CREATE]({ dispatch }, newItem) {
    return dispatch(API.POST, { path: 'schedule/train/create' }).then(
      response => {
        return dispatch(API.PUT, {
          path: 'schedule/train/' + response.uid + '/init',
          body: newItem,
        }).then(() => response);
      },
    );
  },

  [ACTIONS.API_UPDATE]({ dispatch }, train) {
    return dispatch(API.PUT, {
      path: 'schedule/train/' + train.uid,
      body: train,
    });
  },
};

const mutations = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSearchableMutations(MUTATIONS),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
