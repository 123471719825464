// import { mapGetters, mapState } from "vuex";
import { TERMINAL, UI } from 'store/actions';
import { Terminal } from 'kv_shared/lib/data-types';

export default {
  // add props here that are passed from parent templates or from router,
  // like item uid etc for data fetching.
  // do not mutate these, but use them as initial values for 'data'
  props: ['uid'],

  // Data fetching here if necessary
  created() {
    this.$store.dispatch(TERMINAL.LOAD_CURRENT_ITEM, this.uid);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {
      itemData: new Terminal(),
      editName: false,
    };
  },

  // computed properties. Add vuex store data here
  computed: {
    currentStoreData() {
      return this.$store.state.terminals.current;
    },
  },

  watch: {
    currentStoreData: 'resetData',
  },

  methods: {
    resetData() {
      if (this.currentStoreData) {
        this.itemData = {
          ...new Terminal(),
          ...this.currentStoreData,
        };
      } else {
        this.itemData = new Terminal();
      }
    },

    changeActiveState() {
      this.$store
        .dispatch(UI.SHOW_CONFIRM_MODAL, {
          message: this.itemData.active
            ? this.$t('Soll das Terminal {name} wirklich deaktiviert werden?', {
                name: this.itemData.name,
              })
            : this.$t('Soll das Terminal {name} wirklich aktiviert werden?', {
                name: this.itemData.name,
              }),
          confirm: this.$t('Ja'),
          close: this.$t('Nein'),
        })
        .then(respone => {
          if (respone.confirmed) {
            this.itemData.active = !this.itemData.active;
            this.submit();
          }
        });
    },

    cancelEditName() {
      this.itemData.name = this.currentStoreData.name;
      this.editName = false;
    },

    saveName() {
      this.submit().then(() => (this.editName = false));
    },

    submit() {
      return this.$validator
        .validateAll()
        .then(valid => {
          if (!valid) {
            console.log('form errors');
            return;
          }

          return this.$store
            .dispatch(TERMINAL.SAVE_ITEM, this.itemData)
            .catch(e => {
              alert(
                this.$t(
                  'Beim Ändern des Terminals ist ein Fehler aufgetreten.',
                ),
              );
              console.error('item save error', e);
            });
        })
        .catch(e => {
          console.log('form errors', e);
        });
    },
  },
};
