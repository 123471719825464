import { mapGetters } from 'vuex';
import { LOCATION, COMPANY } from 'store/actions';
import {
  LOCATION as LOCATION_GETTERS,
  COMPANY as COMPANY_GETTERS,
} from 'store/getters';

export function editPlaceComponent(companiesGetter) {
  return {
    created() {
      this.$store.dispatch(COMPANY.LOAD_LIST);
      this.$store.dispatch(LOCATION.LOAD_LIST);
      this.selectSingleCompany();
    },

    computed: {
      ...mapGetters({
        companies: companiesGetter,
        companiesById: COMPANY_GETTERS.BY_ID,
        locationsById: LOCATION_GETTERS.BY_ID,
        locationsSortedByName: LOCATION_GETTERS.SORTED_BY_NAME,
        locationNames: LOCATION_GETTERS.LOCALE_NAMES,
      }),

      currentCompany() {
        return this.companiesById[this.itemData.company.uid];
      },

      companyOptions() {
        return this.companies.map(company => ({
          label: company.name,
          value: company.uid,
        }));
      },

      companySelection: {
        get() {
          return this.companyOptions.find(
            opt => opt.value === this.itemData.company.uid,
          );
        },
        set(value) {
          this.itemData.company.uid = value && value.value;
        },
      },

      locationOptions() {
        return this.locationsSortedByName.map(loc => ({
          label: this.locationNames[loc.uid],
          value: loc.uid,
        }));
      },

      locationSelection: {
        get() {
          return this.locationOptions.find(
            opt => opt.value === this.itemData.locality.uid,
          );
        },
        set(value) {
          this.itemData.locality.uid = value && value.value;
        },
      },

      companySelectionNeeded() {
        return this.companies.length > 1;
      },

      missingPlaceMessage() {
        // tslint:disable-next-line:max-line-length
        return this.$t(
          'Sollte ein Ort nicht aufgeführt sein, wenden Sie sich bitte an <a href="mailto:redaktion.dbinfrago@deutschebahn.com">redaktion.dbinfrago@deutschebahn.com</a>',
        );
      },
    },

    watch: {
      companySelectionNeeded: 'selectSingleCompany',
      companies: 'selectSingleCompany',
    },

    methods: {
      selectSingleCompany() {
        if (
          !this.companySelectionNeeded &&
          this.companies[0] != null &&
          this.itemData &&
          this.itemData.company &&
          this.itemData.company.uid !== this.companies[0].uid
        ) {
          this.itemData.company.uid = this.companies[0].uid;
        }
      },
    },
  };
}
