import {
  CONVEYANCE,
  COMPANY,
  COUNTRY,
  LOCATION,
  TERMINAL,
} from 'store/actions';
// tslint:disable-next-line:max-line-length
import {
  CONVEYANCE as GETTERS,
  COUNTRY as COUNTRY_GETTERS,
  LOCATION as LOCATION_GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
} from 'store/getters';
import { normalizeUrl } from 'service/utils';
import { editComponent } from 'mixins/edit-component';
import Vue from 'vue';
import {
  ConveyanceArea,
  CountryAreaDefinition,
  CircularArea,
  PostalCodeQueryResult,
} from 'kv_shared/lib/data-types';
import { mapGetters, mapState } from 'vuex';
import AreaSelector from './AreaSelector.vue';
import { availablePLZApiCountryCodes } from 'kv_shared/lib/constants';

export default {
  components: { AreaSelector },

  mixins: [editComponent(ConveyanceArea, state => state.conveyance.current)],

  props: ['forwarderUid'],

  created() {
    this.$store.dispatch(COUNTRY.LOAD_LIST);
    this.$store.dispatch(LOCATION.LOAD_LIST);
    this.$store.dispatch(TERMINAL.LOAD_LIST);
    this.$store.dispatch(CONVEYANCE.LOAD_LIST);
    this.$store.dispatch(COMPANY.LOAD_CURRENT_ITEM, this.forwarderUid);
    this.$store.dispatch(CONVEYANCE.LOAD_CURRENT_ITEM, this.uid);
  },

  data() {
    return {
      countrySelection: null,
      activeLocationSelector: '',
      countryErrors: {},
      countriesWithPLZData: availablePLZApiCountryCodes,
    };
  },

  computed: {
    ...mapState<any>({
      positionResults: state => state.conveyance.positionResults,
    }),
    ...mapGetters({
      countryNameList: COUNTRY_GETTERS.NAMES_BY_ID,
      locationNames: LOCATION_GETTERS.LOCALE_NAMES,
      countriesByCode: COUNTRY_GETTERS.BY_CODE,
      countriesById: COUNTRY_GETTERS.BY_ID,
      conveyanceAreas: GETTERS.AREAS_OF_CURRENT_FORWARDER,
      locationsByCountry: GETTERS.LOCATIONS_BY_COUNTRY,
      currentUser: AUTHENTICATION_GETTERS.CURRENT_USER,
    }),

    countryOptions() {
      let options = Object.keys(this.countriesByCode).map(code => ({
        value: code,
        label: this.countryNameList[this.countriesByCode[code].uid],
      }));

      if (!this.currentUser.isSuperUser) {
        options = options.filter(o => {
          const uid = this.countriesByCode[o.value].uid;
          return this.currentUser.country.find(c => c.uid === uid);
        });
      }

      return options.sort((a, b) => a.label.localeCompare(b.label));
    },

    areaCountries() {
      return this.countryOptions.filter(country =>
        (this.itemData as ConveyanceArea).areas.find(
          a => a.country === country.value,
        ),
      );
    },

    areasByCountry() {
      return this.areaCountries.reduce(
        (map, opt) => {
          map[opt.value] = this.itemData.areas.find(
            a => a.country === opt.value,
          );
          return map;
        },
        {} as any,
      );
    },

    locationsByCounrtyCode() {
      if (!this.itemData.uid) {
        return {};
      }
      const locs = this.locationsByCountry[this.itemData.uid];
      const locsByCode = {};
      for (const countryId in locs) {
        const country = this.countriesById[countryId];
        locsByCode[country.code] = locs[countryId].join(', ');
      }
      return locsByCode;
    },

    countryAreaOptions() {
      return this.countryOptions.filter(
        country => !this.itemData.areas.find(a => a.country === country.value),
      );
    },

    geoPositionOptions() {
      return this.positionResults
        .map((plz: PostalCodeQueryResult) => ({
          label: plz.plz + ', ' + plz.city.def,
          value: plz.loc,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },

    usableUnitOptions() {
      const usableUnits = [
        {
          value: 'container20',
          label: this.$t("20' Ct"),
        },
        {
          value: 'container30',
          label: this.$t("30' Ct"),
        },
        {
          value: 'container40',
          label: this.$t("40' Ct"),
        },
        {
          value: 'container45',
          label: this.$t("45' Ct"),
        },
        {
          value: 'exchangeableContainer',
          label: this.$t('Wechselbehälter'),
        },
        {
          value: 'tankContainer',
          label: this.$t('Tankcontainer'),
        },
        {
          value: 'semiTrailer',
          label: this.$t('Sattelanhänger'),
        },
        {
          value: 'bulk',
          label: this.$t('Bulk'),
        },
      ];

      return usableUnits;
    },
  },

  watch: {
    itemData: {
      deep: true,
      handler() {
        for (const countryCode of Object.keys(this.countryErrors)) {
          const area = this.itemData.areas.find(a => a.country === countryCode);
          if (area) {
            if (area.all || area.areas.length > 0) {
              Vue.delete(this.countryErrors, countryCode);
            }
          }
        }
      },
    },
  },

  methods: {
    addCountry() {
      const area = new CountryAreaDefinition();
      area.country = this.countrySelection.value;
      this.itemData.areas.push(area);
      this.countrySelection = null;
    },

    removeCountry(countryCode) {
      this.itemData.areas = this.itemData.areas.filter(
        a => a.country !== countryCode,
      );
    },

    addArea(countryCode: string) {
      const item: ConveyanceArea = this.itemData;
      const area = item.areas.find(a => a.country === countryCode);
      if (area) {
        area.areas.push(new CircularArea());
        this.activateLocationSelector(countryCode + (area.areas.length - 1));
      }
    },

    searchPositions(countryCode, query) {
      this.$store.dispatch(CONVEYANCE.SEARCH_GEO_POSITIONS, {
        countryCode,
        query,
      });
    },

    updateLocation(area, opt) {
      if (opt && opt.value) {
        area.plz = opt.label || area.plz;
        area.loc = opt.value || area.loc;
      }
    },

    updateRadius(area, opt) {
      if (opt && opt.value) {
        area.radius = opt.value;
      }
    },

    removeLocation(countryCode, index) {
      const area = this.itemData.areas.find(a => a.country === countryCode);
      if (area) {
        Vue.delete(area.areas, index);
      }
    },

    activateLocationSelector(id) {
      this.activeLocationSelector = id;
    },

    normalizeUrl() {
      this.itemData.contact.url = normalizeUrl(this.itemData.contact.url);
    },

    async submitCallback() {
      if (!this.forwarderUid) {
        alert(
          this.$t('Beim Ändern des Portfolios ist ein Fehler aufgetreten.'),
        );
        console.error('no forwarderUid found');
        return;
      }

      this.itemData.company = { uid: this.forwarderUid };

      // reset areas if whole country selected
      for (const area of this.itemData.areas) {
        if (area) {
          // check if area definition is disabled for that country
          if (!this.countriesWithPLZData[area.country]) {
            area.all = true;
          }
          if (area.all) {
            area.areas = [];
          } else if (area.areas.length === 0) {
            // tslint:disable-next-line:max-line-length
            this.countryErrors[area.country] = this.$t(
              'Das Editieren der Einsatzgebiete ist noch nicht abgeschlossen. Wählen Sie entweder das ganze Land aus oder fügen Sie einen Standort hinzu',
            );
          }
        }
      }

      if (Object.keys(this.countryErrors).length > 0) {
        return;
      }

      this.itemData.localities = await this.$store.dispatch(
        CONVEYANCE.CALCULATE_LOCATIONS,
        this.itemData,
      );

      return this.$store
        .dispatch(CONVEYANCE.SAVE_ITEM, this.itemData)
        .catch(e => {
          if (this.itemData.uid) {
            alert(
              this.$t('Beim Ändern des Portfolios ist ein Fehler aufgetreten.'),
            );
          } else {
            alert(
              this.$t(
                'Beim Anlegen des Portfolios ist ein Fehler aufgetreten.',
              ),
            );
          }
          console.error('item save error', e);
        })
        .then(() => {
          // Switch to edit mode if area was newly created (prevents some edit bugs)
          if (this.$route.name === 'forwarder.area.add' && this.itemData.uid) {
            this.$router.push({
              name: 'forwarder.area.edit',
              params: {
                uid: this.itemData.uid,
                forwarderUid: this.forwarderUid,
              },
            });
          }
        });
    },
  },
};
