import { mapState } from 'vuex';
import { COMPANY } from 'store/actions';
import Vue from 'vue';
import { CompanyTypeConfig } from 'kv_shared/lib/data-types';
import { merge } from 'service/utils';

export default {
  // Data fetching
  created() {
    this.$store.dispatch(COMPANY.GET_CONFIG_BY_TYPE, this.type);
    this.welcomeData.uid = this.type;
  },

  props: ['type'],

  data() {
    return {
      welcomeData: new CompanyTypeConfig(),
    };
  },

  computed: {
    ...mapState<any>({
      companyConfigurations: state => state.companies.configurations,
    }),

    initialWelcomeData() {
      return this.companyConfigurations[this.type];
    },
  },

  // watch some data. Use with care, prefer computed properties if possible
  watch: {
    initialWelcomeData: 'resetData',
  },

  methods: {
    resetData() {
      if (this.initialWelcomeData) {
        this.welcomeData = merge(
          new CompanyTypeConfig(),
          this.initialWelcomeData,
        );
      }
    },

    submit() {
      this.$validator
        .validateAll()
        .then(valid => {
          if (!valid) {
            console.log('form errors');
            return;
          }

          this.$store
            .dispatch(COMPANY.API_SAVE_CONFIG_BY_TYPE, this.welcomeData)
            .catch(error => {
              // handle system specific errors
              console.error('unknown (not system specific) error', error);
            });
        })
        .catch(() => {
          console.log('form errors, saving failed');
        });
    },

    resetForm() {
      this.resetData();
      Vue.nextTick(() => {
        this.$errors.clear();
      });
    },
  },
};
