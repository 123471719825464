import { freezeToStopVueReactivity } from 'service/vue-helpers';

export function defaultItemState() {
  return {
    list: [] as any[],
    current: null,
  };
}

export function defaultItemActions(ACTIONS, MUTATIONS) {
  return {
    [ACTIONS.LOAD_LIST]({ commit, dispatch, state }, forceReload = false) {
      if (!forceReload && state.list && state.list.length) {
        return;
      }
      return dispatch(ACTIONS.API_GET_LIST).then(list =>
        commit(MUTATIONS.REPLACE_LIST, list),
      );
    },

    [ACTIONS.LOAD_CURRENT_ITEM]({ dispatch, commit, state }, uid) {
      if (uid) {
        const item = state.list.find(i => i.uid === uid);
        dispatch(ACTIONS.SET_ITEM, { item });

        return dispatch(ACTIONS.API_GET_BY_ID, uid).then(item =>
          dispatch(ACTIONS.SET_ITEM, { item }),
        );
      } else {
        return dispatch(ACTIONS.RESET_CURRENT);
      }
    },

    [ACTIONS.SET_ITEM](
      { dispatch, commit, state },
      { item, updateList = false },
    ) {
      if (!(item && item.uid)) {
        return dispatch(ACTIONS.RESET_CURRENT);
      }
      commit(MUTATIONS.SET_CURRENT, item);
      if (updateList && state.list && state.list.length) {
        commit(MUTATIONS.UPDATE_LIST, [item]);
      }
    },

    [ACTIONS.RESET_CURRENT]({ commit }) {
      commit(MUTATIONS.SET_CURRENT, null);
    },

    [ACTIONS.SAVE_ITEM]({ commit, dispatch, state }, item) {
      if (!item.uid) {
        return dispatch(ACTIONS.API_CREATE, item).then(result => {
          if (result.uid) {
            item.uid = result.uid;
          }
          dispatch(ACTIONS.SET_ITEM, { item, updateList: true });
        });
      } else {
        return dispatch(ACTIONS.API_UPDATE, item).then(() =>
          dispatch(ACTIONS.SET_ITEM, { item, updateList: true }),
        );
      }
    },

    [ACTIONS.REMOVE_ITEM]({ commit, dispatch, state }, item) {
      dispatch(ACTIONS.API_DELETE_BY_ID, item.uid)
        .then(result => {
          console.log('item deleted!', item, result);
        })
        .then(() => {
          commit(MUTATIONS.REMOVE_FROM_LIST, [item]);
        });
    },
  };
}

export function defaultItemMutations(MUTATIONS) {
  return {
    [MUTATIONS.REPLACE_LIST](state, newList) {
      state.list = newList.map(freezeToStopVueReactivity);
    },

    [MUTATIONS.SET_CURRENT](state, item) {
      state.current = freezeToStopVueReactivity(item);
    },

    [MUTATIONS.UPDATE_LIST](state, newItems) {
      for (const item of newItems) {
        const index = state.list.findIndex(
          listItem => listItem.uid === item.uid,
        );
        if (index >= 0) {
          state.list.splice(index, 1, freezeToStopVueReactivity(item));
        } else {
          state.list.push(freezeToStopVueReactivity(item));
        }
      }
    },

    [MUTATIONS.REMOVE_FROM_LIST](state, deletedItems) {
      for (const item of deletedItems) {
        const index = state.list.findIndex(
          listItem => listItem.uid === item.uid,
        );
        if (index >= 0) {
          state.list.splice(index, 1);
        }
      }
    },
  };
}
