import { CONNECTION as ACTIONS, API } from 'store/actions';
import { CONNECTION as MUTATIONS } from 'store/mutations';
// tslint:disable-next-line:max-line-length
import {
  CONNECTION as GETTERS,
  LOCATION as LOCATION_GETTERS,
  COMPANY as COMPANY_GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
} from 'store/getters';
import { listToIdMap, defaultValueOnHttpError } from 'service/utils';
import {
  defaultItemActions,
  defaultItemMutations,
  defaultItemState,
} from 'store/commons/item-list';
import {
  defaultSearchableState,
  defaultSearchableActions,
  defaultSearchableMutations,
} from 'store/commons/searchable';
import { freezeToStopVueReactivity } from 'service/vue-helpers';

const state = {
  ...defaultItemState(),
  ...defaultSearchableState(),
};

const getters = {
  [GETTERS.BY_ID]: state => listToIdMap(state.list),

  [GETTERS.FILTERED_LIST]: (state, getters, rootState) => {
    const filtered: any[] = [];
    const locationList = getters[LOCATION_GETTERS.BY_ID];
    const locationLocaleNames = getters[LOCATION_GETTERS.LOCALE_NAMES];
    const companyList = getters[COMPANY_GETTERS.BY_ID];

    for (const connection of getters[GETTERS.OF_CURRENT_USER]) {
      const locationNames: any[] = [];

      // Location Names
      for (const connectionLocation of connection.locality) {
        const locationUid = connectionLocation.uid;
        const location = locationList[locationUid];

        if (location) {
          locationNames.push(locationLocaleNames[location.uid]);
        }
      }

      const locationNamesString = locationNames.join(' - ');

      // Operator Name
      const company = companyList[connection.operator.uid];
      const operatorNameString = company ? company.name : '';

      if (
        connection.name.toLowerCase().indexOf(state.searchString) > -1 ||
        locationNamesString.toLowerCase().indexOf(state.searchString) > -1 ||
        operatorNameString.toLowerCase().indexOf(state.searchString) > -1
      ) {
        filtered.push(
          freezeToStopVueReactivity({
            ...connection,
            locationNamesString,
            operatorNameString,
          }),
        );
      }
    }

    return filtered;
  },

  [GETTERS.OF_CURRENT_USER]: (state, getters, rootState) => {
    const user = getters[AUTHENTICATION_GETTERS.CURRENT_USER];
    if (user.isSuperUser) {
      return state.list;
    }

    let filtered: any[] = [];
    let operatorUids = [];

    // Get operator uids of current user
    if (user.isOperator || user.isContainerzug) {
      if (user.company && user.company.length > 0) {
        operatorUids = user.company.map(company => company.uid);
      } else {
        console.error('user has no related company', user);
        return [];
      }
    }

    // Filter Connections by operatorUids
    if (operatorUids.length > 0) {
      filtered = state.list.filter(connection =>
        operatorUids.find(uid => connection.operator.uid === uid),
      );
    }

    return filtered;
  },
};

const actions = {
  ...defaultItemActions(ACTIONS, MUTATIONS),
  ...defaultSearchableActions(ACTIONS, MUTATIONS),

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, 'schedule/connection')
      .then(response => {
        return response.connectionList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, 'schedule/connection/' + uid).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_DELETE_BY_ID]({ dispatch }, uid) {
    return dispatch(API.DELETE, 'schedule/connection/' + uid);
  },

  [ACTIONS.API_CREATE]({ dispatch }, newItem) {
    return dispatch(API.POST, { path: 'schedule/connection/create' }).then(
      response => {
        return dispatch(API.PUT, {
          path: 'schedule/connection/' + response.uid + '/init',
          body: newItem,
        }).then(() => response);
      },
    );
  },

  [ACTIONS.API_UPDATE]({ dispatch }, connection) {
    return dispatch(API.PUT, {
      path: 'schedule/connection/' + connection.uid,
      body: connection,
    });
  },
};

const mutations = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSearchableMutations(MUTATIONS),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
