import { ParsedGeoLocation } from '../data-types';

const toRad = Math.PI / 180;

// tslint:disable-next-line:max-line-length
// https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula/21623206#21623206
export function getDistanceInKM(
  pos1: ParsedGeoLocation,
  pos2: ParsedGeoLocation,
) {
  const a =
    0.5 -
    Math.cos((pos2.lat - pos1.lat) * toRad) / 2 +
    (Math.cos(pos1.lat * toRad) *
      Math.cos(pos2.lat * toRad) *
      (1 - Math.cos((pos2.lng - pos1.lng) * toRad))) /
      2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}
