import { UI } from 'store/actions';
import debounce from 'lodash/debounce';

export function defaultSearchableState() {
  return {
    searchString: '',
  };
}

export function defaultSearchableActions(ACTIONS, MUTATIONS) {
  return {
    [ACTIONS.FILTER_LIST]: debounce(({ commit, dispatch }, filter) => {
      dispatch(UI.SHOW_LOADER);
      setTimeout(() => {
        commit(MUTATIONS.SET_SEARCH_STRING, filter);
        setTimeout(dispatch, 30, UI.HIDE_LOADER);
      }, 30);
    }, 300),
  };
}

export function defaultSearchableMutations(MUTATIONS) {
  return {
    [MUTATIONS.SET_SEARCH_STRING](state, searchString) {
      state.searchString = searchString ? searchString.toLowerCase() : '';
    },
  };
}
