import { mapState } from 'vuex';
import AppHeader from './shared/Header.vue';
import Breadcrumb from './shared/Breadcrumb.vue';
import Modal from './shared/Modal.vue';
import { COMPANY } from '../store/actions';

export default {
  components: {
    'app-header': AppHeader,
    Breadcrumb,
    Modal,
  },

  created() {
    // needed to get workarround booking provider user type
    this.$store.dispatch(COMPANY.LOAD_LIST);
  },

  computed: mapState<any>({
    showLoader: state => state.ui.showLoader,
  }),
};
