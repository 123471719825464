import { USER as ACTIONS, API, AUTHENTICATION } from 'store/actions';
import {
  USER as MUTATIONS,
  AUTHENTICATION as AUTHENTICATION_MUTATIONS,
} from 'store/mutations';
import {
  USER as GETTERS,
  AUTHENTICATION as AUTHENTICATION_GETTERS,
  COMPANY as COMPANY_GETTERS,
} from 'store/getters';
// tslint:disable-next-line:max-line-length
import {
  USER_ROLE_OBJECT,
  USER_ADMIN_EMAIL,
  USER_CHIEF_EDITOR_EMAIL,
  USER_SPECIAL_ROLE,
} from 'service/constants';
import { listToIdMap, defaultValueOnHttpError } from 'service/utils';
import i18n from 'kv_shared/lib/vue/i18n';
import {
  defaultItemState,
  defaultItemMutations,
  defaultItemActions,
} from 'store/commons/item-list';
import {
  defaultSearchableState,
  defaultSearchableActions,
  defaultSearchableMutations,
} from 'store/commons/searchable';
import { CompanyType, UserHackRole } from 'kv_shared/lib/data-types';
import { isUserBookingProvider } from 'kv_shared/lib/bookingProviderHack';

const state = {
  ...defaultItemState(),
  ...defaultSearchableState(),
};

const getters = {
  [GETTERS.BY_ID]: state => listToIdMap(state.list),

  [GETTERS.ROLE_NAMES]: (state, getters, rootState) => {
    rootState.locale; // Get once for reactivity updates on locale changes

    return Object.values(USER_ROLE_OBJECT).reduce((obj, { type, name }) => {
      obj[type] = i18n.t(name as string);
      return obj;
    }, {});
  },

  [GETTERS.FILTERED_LIST]: (state, getters, rootState) => {
    const filtered: any[] = [];
    const unknown = i18n.t('(unbekannt)');

    for (const user of state.list) {
      const firstName = user.firstName ? user.firstName.toLowerCase() : '';
      const lastName = user.lastName ? user.lastName.toLowerCase() : '';

      const userRoleName = isUserBookingProvider(
        user,
        getters[COMPANY_GETTERS.BY_ID],
      )
        ? getters[GETTERS.ROLE_NAMES][UserHackRole.BOOKING_PROVIDER]
        : user.userRole
            .map(role => getters[GETTERS.ROLE_NAMES][role] || unknown)
            .sort()
            .join(', ');

      if (
        firstName.indexOf(state.searchString) > -1 ||
        lastName.indexOf(state.searchString) > -1 ||
        userRoleName.toLowerCase().indexOf(state.searchString) > -1
      ) {
        filtered.push({
          ...user,
          userRoleName,
        });
      }
    }

    return filtered;
  },

  [GETTERS.CHIEF_EDITOR]: state =>
    state.list.find(user => user.email === USER_CHIEF_EDITOR_EMAIL),

  [GETTERS.ADMIN]: state =>
    state.list.find(user => user.email === USER_ADMIN_EMAIL),
};

const defaultActions = defaultItemActions(ACTIONS, MUTATIONS);

const actions = {
  ...defaultActions,
  ...defaultSearchableActions(ACTIONS, MUTATIONS),

  // Override to also update current authentication data if neccessary
  [ACTIONS.SAVE_ITEM](actionCtx, item) {
    return defaultActions[ACTIONS.SAVE_ITEM](actionCtx, item).then(() => {
      if (item.uid === actionCtx.rootState.authentication.user.uid) {
        actionCtx.commit(AUTHENTICATION_MUTATIONS.SET_USER, item);
        actionCtx.dispatch(AUTHENTICATION.SAVE_LOCAL_SESSION);
      }
    });
  },

  [ACTIONS.UPDATE_CONTAINER_ZUG_COMPANIES]({ dispatch, state }, userData) {
    if (
      !userData ||
      userData.specialRole === USER_SPECIAL_ROLE.CONTAINERZUG ||
      !userData.company ||
      !userData.company.length
    ) {
      return;
    }

    return dispatch(ACTIONS.LOAD_LIST, true).then(() => {
      const containerzugUsers = state.list.filter(
        user => user.specialRole === USER_SPECIAL_ROLE.CONTAINERZUG,
      );

      const companiesToRemove = userData.company.map(comp => comp.uid);

      return containerzugUsers.reduce((promise, user) => {
        return promise.then(() => {
          const company = user.company.filter(
            comp => !companiesToRemove.find(uid => uid === comp.uid),
          );

          // Create new data cause user is readonly by Object.freeze (Vue optimization)
          const data = Object.assign({}, user, { company });
          return dispatch(ACTIONS.API_UPDATE, data);
        });
      }, Promise.resolve());
    });
  },

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch, rootGetters }) {
    const user = rootGetters[AUTHENTICATION_GETTERS.CURRENT_USER];

    if (user.isSuperUser) {
      return dispatch(API.GET, 'user')
        .then(response => {
          return response.userList || [];
        })
        .catch(defaultValueOnHttpError([]));
    } else if (user.isSubChiefEditor) {
      const roles = Object.values(CompanyType).filter(role =>
        user.userRole.includes(role),
      );

      // get lists for all roles of current user
      return Promise.all(
        roles.map(r =>
          dispatch(API.GET, 'user/' + r)
            .then(response => {
              return response.userList || [];
            })
            .catch(defaultValueOnHttpError([])),
        ),
      ).then(lists => {
        // reduce duplicate users, if they have multiple roles
        const userMap = lists.reduce((users, list) => {
          list.forEach(u => (users[u.uid] = u));
          return list;
        }, {});

        return Object.values(userMap);
      });
    } else {
      return [];
    }
  },

  [ACTIONS.API_GET_BY_ID]({ commit, dispatch }, uid) {
    return dispatch(API.GET, 'user/' + uid);
  },

  [ACTIONS.API_UPDATE]({ dispatch }, user) {
    return dispatch(API.PUT, {
      path: 'user/' + user.uid,
      body: user,
    });
  },

  [ACTIONS.API_CREATE]({ dispatch }, newItem) {
    return dispatch(API.POST, { path: 'user/create' }).then(response => {
      return dispatch(API.PUT, {
        path: 'user/' + response.uid + '/init',
        body: newItem,
      }).then(() => response);
    });
  },

  [ACTIONS.API_DELETE_BY_ID]({ dispatch }, uid) {
    return dispatch(API.DELETE, 'user/' + uid);
  },
};

const mutations = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSearchableMutations(MUTATIONS),

  [MUTATIONS.SET_SEARCH_STRING](state, searchString) {
    state.searchString = searchString ? searchString.toLowerCase() : '';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
