import { mapGetters } from 'vuex';
import { LOCATION, COUNTRY } from 'store/actions';
import { COUNTRY as COUNTRY_GETTERS } from 'store/getters';
import Vue from 'vue';
import { editComponent } from 'mixins/edit-component';
import map from 'lodash/map';
import { sortListBy } from 'service/utils';
import { Location } from 'kv_shared/lib/data-types';

export default (Vue as any).extend({
  mixins: [editComponent(Location, state => state.locations.current)],

  // Data fetching
  created() {
    this.$store.dispatch(LOCATION.LOAD_CURRENT_ITEM, this.uid);
    this.$store.dispatch(COUNTRY.LOAD_LIST);
  },

  // local component state, that is not saved in global vuex store
  data() {
    return {};
  },

  // computed properties. Add vuex store data here
  computed: {
    ...mapGetters({
      countryList: COUNTRY_GETTERS.BY_ID,
      countryNameList: COUNTRY_GETTERS.NAMES_BY_ID,
    }),

    countryOptions() {
      const options = map(this.countryNameList, (countryName, uid) => ({
        label: countryName,
        value: uid,
      }));
      return sortListBy(options, ['label']);
    },

    countrySelection: {
      get() {
        return this.countryOptions.find(
          opt => opt.value === this.itemData.country.uid,
        );
      },
      set(value) {
        this.itemData.country.uid = value && value.value;
      },
    },

    countrySelectionNeeded() {
      return this.itemData.country.uid === '';
    },
  },

  // methods that are available in the component or in templates
  methods: {
    checkAndSetOtherNameFields() {
      if (!this.itemData.uid && this.itemData.name.locale !== '') {
        if (!this.itemData.name.en) {
          this.itemData.name.en = this.itemData.name.locale;
        }
        if (!this.itemData.name.de) {
          this.itemData.name.de = this.itemData.name.locale;
        }
      }
    },

    submitCallback() {
      return this.$store
        .dispatch(LOCATION.SAVE_ITEM, this.itemData)
        .catch(e => {
          if (this.itemData.uid) {
            alert(this.$t('Beim Ändern des Orts ist ein Fehler aufgetreten.'));
          } else {
            alert(this.$t('Beim Anlegen des Orts ist ein Fehler aufgetreten.'));
          }
          console.error('update error', e);
        });
    },
  },
});
