export const dataDirectory = 'data/plz/';

export const csvProperties = [
  'country',
  'plz',
  'city',
  'destrict',
  'region',
  'lat',
  'lng',
  'lang',
];

export const countryConfigs = {
  AT: {
    schema: [0, 3, 4, 5, 1, 6, 7, -1],
    encoding: 'latin1',
    defaultLanguage: '',
  },
  BE: {
    schema: [0, 2, 3, -1, 4, 10, 11, 1],
    encoding: 'utf8',
    defaultLanguage: 'NL',
  }, // (Language:1 NL,FR)
  CH: {
    schema: [1, 5, 6, 7, 3, 8, 9, 2],
    encoding: 'latin1',
    defaultLanguage: 'DE',
  }, // (Language:2 DE,FR,IT)
  CZ: {
    schema: [0, 1, 3, 2, -1, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  DE: {
    schema: [0, 4, 6, 7, 1, 8, 9, -1],
    encoding: 'latin1',
    defaultLanguage: '',
  },
  DK: {
    schema: [0, 1, 2, -1, 4, 6, 7, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  ES: {
    schema: [0, 1, 2, -1, 4, 6, 7, -1],
    encoding: 'latin1',
    defaultLanguage: '',
  },
  FI: {
    schema: [0, 2, 3, -1, 4, 9, 10, 1],
    encoding: 'utf8',
    defaultLanguage: 'FI',
  }, // (Language:1, FI,SV)
  FO: {
    schema: [0, 1, 2, -1, -1, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  FR: {
    schema: [0, 1, 3, -1, 4, 7, 8, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  // GB: {
  //   schema: [0, 1, 2, 3, 4, 6, 7, -1],
  //   encoding: 'utf8',
  //   defaultLanguage: '',
  // },
  GR: {
    schema: [1, 3, 4, -1, 6, 7, 8, 2],
    encoding: 'utf8',
    defaultLanguage: 'EN',
  }, // (Language:2, EL,EN)
  HR: {
    schema: [0, 1, 7, 2, 3, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  HU: {
    schema: [0, 1, 2, -1, 3, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  IS: {
    schema: [0, 1, 2, -1, 3, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  IT: {
    schema: [1, 3, 6, 4, 5, 8, 9, 2],
    encoding: 'latin1',
    defaultLanguage: 'IT',
  }, // (Language:2, IT,EN)
  LI: {
    schema: [0, 1, 5, 2, -1, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  LU: {
    schema: [0, 2, 6, 3, 4, 9, 10, 1],
    encoding: 'utf8',
    defaultLanguage: 'DE',
  }, // (Language:1, DE,FR)
  MC: {
    schema: [0, 1, 3, 2, 5, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  NL: {
    schema: [0, 1, 4, 2, 3, 5, 6, -1],
    encoding: 'latin1',
    defaultLanguage: '',
  },
  NO: {
    schema: [0, 1, 5, 2, 3, 6, 7, -1],
    encoding: 'latin1',
    defaultLanguage: '',
  },
  PL: {
    schema: [0, 1, 2, -1, 4, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  PT: {
    schema: [0, 1, 2, 7, 5, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  RU: {
    schema: [1, 5, 6, 4, 3, 7, 8, 2],
    encoding: 'utf8',
    defaultLanguage: 'EN',
  }, // (Language:2, EN,RU)
  SE: {
    schema: [0, 1, 4, 2, 3, 5, 6, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  SI: {
    schema: [0, 1, 3, 2, -1, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  SK: {
    schema: [0, 1, 5, 3, 4, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  SM: {
    schema: [0, 1, 2, -1, -1, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  TR: {
    schema: [0, 1, 2, -1, 3, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
  VA: {
    schema: [0, 1, 2, -1, -1, 8, 9, -1],
    encoding: 'utf8',
    defaultLanguage: '',
  },
};

export const maxImportCount = 70000;
