import Vue from 'vue';
import Vuex from 'vuex';
import authentication from './modules/authentication';
import api from './modules/api';
import terminals from './modules/terminals';
import companies from './modules/companies';
import locations from './modules/locations';
import countries from './modules/countries';
import users from './modules/users';
import loadingPoints from './modules/loadingpoints';
import wagonLoad from './modules/wagonload';
import conveyance from './modules/conveyance';
import connections from './modules/connections';
import trains from './modules/trains';
import news from './modules/news';
import misc from './modules/misc';
import ui from './modules/ui';
import rootStore from './root';

Vue.use(Vuex as any);

const store = new Vuex.Store({
  ...rootStore,
  modules: {
    api,
    ui,
    authentication,
    terminals,
    companies,
    locations,
    countries,
    users,
    loadingPoints,
    wagonLoad,
    conveyance,
    connections,
    trains,
    news,
    misc,
  },
});

(window as any).vuex = store;

export default store;
