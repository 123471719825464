import {
  CONVEYANCE as ACTIONS,
  API,
  UI,
  COUNTRY,
  LOCATION,
  TERMINAL,
} from 'store/actions';
import { CONVEYANCE as MUTATIONS } from 'store/mutations';
// tslint:disable-next-line:max-line-length
import {
  CONVEYANCE as GETTERS,
  COMPANY as COMPANY_GETTERS,
  COUNTRY as COUNTRY_GETTERS,
  TERMINAL as TERMINAL_GETTERS,
  LOCATION as LOCATION_GETTERS,
} from 'store/getters';
import { defaultValueOnHttpError } from 'service/utils';
import {
  defaultItemActions,
  defaultItemState,
  defaultItemMutations,
} from 'store/commons/item-list';
import {
  defaultSearchableState,
  defaultSearchableMutations,
} from 'store/commons/searchable';
import debounce from 'lodash/debounce';
import { ConveyanceArea, Location, Country } from 'kv_shared/lib/data-types';
import { getDistanceInKM } from 'kv_shared/lib/utils/geo-coords';
import isEqual from 'lodash/isEqual';

const state = {
  ...defaultItemState(),
  ...defaultSearchableState(),
  positionResults: [],
};

const getters = {
  [GETTERS.FILTERED_FORWARDER_LIST]: (state, getters) => {
    const filtered: any[] = [];

    for (const forwarder of getters[COMPANY_GETTERS.FORWARDERS]) {
      if (
        forwarder.name &&
        forwarder.name.toLowerCase().indexOf(state.searchString) > -1
      ) {
        filtered.push(forwarder);
      }
    }

    return filtered;
  },

  [GETTERS.AREAS_OF_CURRENT_FORWARDER](state, getters, rootState) {
    const forwarder = rootState.companies.current;
    if (forwarder) {
      return state.list.filter(area => area.company.uid === forwarder.uid);
    }
    return [];
  },

  [GETTERS.LOCATIONS_BY_COUNTRY](state, getters, rootState) {
    const locations = getters[LOCATION_GETTERS.BY_ID];
    const localeNames = getters[LOCATION_GETTERS.LOCALE_NAMES];
    const areaLocationsByCountry: {
      [areaUid: string]: { [countryUid: string]: string[] };
    } = {};
    for (const area of state.list as ConveyanceArea[]) {
      const byCountry: { [countryUid: string]: string[] } = {};
      for (const l of area.localities) {
        const loc: Location = locations[l.uid as string];
        if (loc && loc.country.uid) {
          const cid = loc.country.uid;
          if (byCountry[cid]) {
            byCountry[cid].push(localeNames[loc.uid as string]);
          } else {
            byCountry[cid] = [localeNames[loc.uid as string]];
          }
        }
      }
      for (const cid in byCountry) {
        byCountry[cid].sort((a, b) => a.localeCompare(b));
      }
      areaLocationsByCountry[area.uid as string] = byCountry;
    }
    return areaLocationsByCountry;
  },
};

const actions = {
  ...defaultItemActions(ACTIONS, MUTATIONS),

  [ACTIONS.FILTER_FORWARDERS]: debounce(({ commit, dispatch }, filter) => {
    dispatch(UI.SHOW_LOADER);
    setTimeout(() => {
      commit(MUTATIONS.SET_SEARCH_STRING, filter);
      setTimeout(dispatch, 30, UI.HIDE_LOADER);
    }, 30);
  }, 300),

  [ACTIONS.SEARCH_GEO_POSITIONS]: (
    { dispatch, commit, getters, state },
    { countryCode, query },
  ) => {
    if (countryCode && query) {
      return dispatch(ACTIONS.API_GET_POSITIONS, { countryCode, query }).then(
        list => {
          commit(MUTATIONS.SET_POSITION_RESULTS, list);
        },
      );
    } else {
      commit(MUTATIONS.SET_POSITION_RESULTS, null);
    }
  },

  async [ACTIONS.CALCULATE_LOCATIONS](
    { getters },
    conveyanceData: ConveyanceArea,
  ) {
    const terminals = getters[TERMINAL_GETTERS.BY_COUNTRY];
    const countries = getters[COUNTRY_GETTERS.BY_CODE];

    const locations: any = {};

    for (const area of conveyanceData.areas) {
      const country: Country = countries[area.country];
      if (area && country) {
        if (!area.all) {
          const ts = terminals[country.uid as string];
          if (ts) {
            for (const t of ts) {
              if (!locations[t.locality.uid]) {
                for (const zone of area.areas) {
                  if (
                    zone.loc &&
                    getDistanceInKM(zone.loc, t.loc) < zone.radius + 5
                  ) {
                    locations[t.locality.uid] = true;
                  }
                }
              }
            }
          }
        }
      }
    }

    return Object.keys(locations).map(uid => ({ uid }));
  },

  async [ACTIONS.RECALCULATE_ALL]({ state, dispatch }) {
    await Promise.all([
      dispatch(COUNTRY.LOAD_LIST),
      dispatch(LOCATION.LOAD_LIST),
      dispatch(TERMINAL.LOAD_LIST),
    ]);
    for (const area of state.list as ConveyanceArea[]) {
      const newList = await dispatch(ACTIONS.CALCULATE_LOCATIONS, area);
      if (
        !isEqual(
          newList.sort((a, b) => a.uid.localeCompare(b.uid)),
          area.localities.sort((a: any, b: any) => a.uid.localeCompare(b.uid)),
        )
      ) {
        console.log('updating', area.uid, area.name);
        await dispatch(ACTIONS.SAVE_ITEM, {
          ...area,
          localities: newList,
        });
      }
    }
  },

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, 'area/conveyance')
      .then(response => {
        return response.areaList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, 'area/conveyance/' + uid).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_UPDATE]({ dispatch }, area) {
    return dispatch(API.PUT, {
      path: 'area/conveyance/' + area.uid,
      body: area,
    });
  },

  [ACTIONS.API_CREATE]({ dispatch }, newItem) {
    return dispatch(API.POST, { path: 'area/conveyance/create' }).then(
      response => {
        return dispatch(API.PUT, {
          path: 'area/conveyance/' + response.uid + '/init',
          body: newItem,
        }).then(() => response);
      },
    );
  },

  [ACTIONS.API_DELETE_BY_ID]({ dispatch }, uid) {
    return dispatch(API.DELETE, 'area/conveyance/' + uid);
  },

  [ACTIONS.API_GET_POSITIONS]({ dispatch }, { countryCode, query }) {
    return dispatch(API.GET, {
      path: `location/plz/${countryCode}`,
      query: { plz: query, limit: 30 },
    })
      .then(response => {
        return response.plzList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },
};

const mutations = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSearchableMutations(MUTATIONS),

  [MUTATIONS.SET_POSITION_RESULTS](state, results) {
    state.positionResults = results || [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
